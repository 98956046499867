import React, { useState } from "react";
import {
  Button,
  Grid,
  TextField,
  Menu,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { useForceUpdate } from "../../../../lib";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";

export const SortGrid = ({ id, setList, list, copy, filterTitle }) => {
  let forceUpdate = useForceUpdate();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    forceUpdate();
    setAnchorEl(null);
  };

  return (
    <Grid container>
      <Grid item>
        <TextField
          type="search"
          margin="normal"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={(event) => {
            setList(
              copy.filter((query) =>
                query.name
                  .toLowerCase()
                  .includes(event.target.value.toLowerCase()),
              ),
            );
          }}
        />
      </Grid>
      <Grid item style={{ display: "flex" }}>
        <Button>
          <Tooltip title={filterTitle} arrow>
            <FilterListIcon
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            />
          </Tooltip>
          <Menu
            id={id}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <MenuItem
              onClick={() => {
                setList(list.sort((a, b) => (a.name > b.name ? 1 : -1)));
                handleClose();
              }}
            >
              A-Z
            </MenuItem>
            <MenuItem
              onClick={() => {
                setList(list.sort((a, b) => (a.name < b.name ? 1 : -1)));
                handleClose();
              }}
            >
              Z-A
            </MenuItem>
          </Menu>
        </Button>
      </Grid>
    </Grid>
  );
};
