import React from "react";
import {
  Checkbox,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";

interface Props {
  items: any;
  checked: any;
  setChecked: any;
  fullRight: any;
}

export const GroupListItems = (props: Props) => {
  const handleToggle = (value: any) => () => {
    const currentIndex = props.checked.indexOf(value);
    const newChecked = [...props.checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    props.setChecked(newChecked);
  };

  return props.items?.map((value: any) => {
    const labelId = `transfer-list-all-item-${value}-label`;

    return (
      <ListItem
        style={{
          padding: 0,
        }}
        key={value}
        role="listitem"
        button
        onClick={handleToggle(value)}
        // on
        dense={true}
      >
        <ListItemIcon>
          <Checkbox
            style={{
              transform: "scale(.7)",
              padding: 0,
            }}
            checked={props.checked.indexOf(value) !== -1}
            tabIndex={-1}
            disableRipple
            inputProps={{ "aria-labelledby": labelId }}
          />
        </ListItemIcon>
        <ListItemText id={value.id} primary={value.name} />
      </ListItem>
    );
  });
};
