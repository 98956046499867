import { AuthState } from "./auth/state";
import { PrefState } from "./prefs/state";
import { WatchState } from "./watches/state";
import { ScopeState } from "./scopes/state";
import { UIState } from "./ui/state";
import { WatchFormState, watchform } from "./watchform/state";

// Export Actions, Selectors

export * from "./auth/actions";
export * from "./auth/selectors";

export * from "./prefs/actions";
export * from "./prefs/selectors";

export * from "./watches/actions";
export * from "./watches/selectors";

export * from "./ui/actions";
export * from "./ui/selectors";

export * from "./scopes/actions";

export * from "./watchform/actions";
export * from "./watchform/selectors";

export interface AppState {
  auth: typeof AuthState;
  prefs: typeof PrefState;
  scopes: typeof ScopeState.defaults;
  ui: typeof UIState.defaults;
  watches: typeof WatchState.defaults;
  watchform: WatchFormState;
}

const states = [
  // Export States
  AuthState,
  PrefState,
  WatchState,
  UIState,
  ScopeState,
  watchform,
];
export default states;
