import React, { useEffect, useState } from "react";
import { Typography, Box } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid/";
import { WatchActions } from "../../../../state";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { format_Word, getDetailButton, CustomToolbar } from "../../../../lib";
import { AddWatchAttribute } from ".";
import { QuickSearchToolbar, escapeRegExp } from "../../../../lib/utils";
export function WatchAttributesGroups(props) {
  const dispatch = useDispatch();
  const {
    pageRoute: {
      params: { attribute },
    },
  } = props;
  const siteId = useSelector((state) => state.scopes.site_id);
  const [attrRows, setAttrRows] = useState([]);
  const attrData = useSelector(
    (state) => state.watches.attributeList,
    shallowEqual,
  );
  useEffect(() => {
    (async function () {
      await dispatch(WatchActions.getAttributeGroups(attribute, siteId));
    })();
  }, [attribute, siteId]);

  useEffect(() => {
    if (attrData[0]?.hasOwnProperty(`${attribute}_id`)) {
      const rows = attrData.map((row) => {
        let id = row[`${attribute}_id`];

        var tableRow = {
          id: id,
          button: row,
          name: row[attribute],
          count: row.count,
        };

        return tableRow;
      });
      setAttrRows(rows);
    }
  }, [attrData, attribute]);
  const attrColumns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    { field: "name", headerName: "Name", flex: 1 },
    {
      field: "count",
      headerName: "Count",
      type: "number",
      flex: 0.4,
      headerAlign: "left",
    },
    {
      field: "button",
      headerName: "Details",
      flex: 1,
      filterable: false,
      renderCell: (params) => {
        return getDetailButton(
          "watch-details-groups",
          params.value,
          attribute,
          siteId,
        );
      },
    },
  ];
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState(attrRows);
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = attrRows.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field]?.toString());
      });
    });
    setRows(filteredRows);
  };
  useEffect(() => {
    if (attrRows?.length) {
      setRows(attrRows);
    }
  }, [attrRows]);
  return (
    <Box
      style={{
        width: "100%",
      }}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="h3">{format_Word(attribute)}</Typography>
      </div>
      <AddWatchAttribute
        attribute={format_Word(attribute)}
        rawAttribute={attribute}
        path={"watch-attribute-groups"}
      />
      <div>
        <DataGrid
          rows={attrRows}
          columns={attrColumns}
          disableColumnMenu={true}
          isRowSelectable={false}
          pageSize={100}
          checkboxSelection={false}
          autoHeight={true}
          rowHeight={38}
          components={{ Toolbar: QuickSearchToolbar }}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event) => {
                requestSearch(event.target.value);
              },
              clearSearch: () => requestSearch(""),
            },
          }}
          sortModel={[
            {
              field: "name",
              sort: "asc",
            },
          ]}
        />
      </div>
    </Box>
  );
}
