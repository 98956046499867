import { HomePage } from "./HomePage";
import { NotAuthorizedPage } from "./NotAuthorizedPage";
import { NotFoundPage } from "./NotFoundPage";
import { WatchesPage } from "./WatchesPage";
import { AddWatchPage } from "./AddWatchPage";
import { UserPage } from "./UserPage";
import { WatchHistory } from "./Components/WatchHistory";
import { WatchAttributes } from "./Components/watchDetailTable/WatchAttributes";
import { SiteAttributes } from "./Components/watchDetailTable/SiteAttributes";
import { RecordsPage } from "./Components/watchDetailTable/RecordsPage";
import { ShippingSiteAttributes } from "./Components/watchDetailTable/ShippingSiteAttributes";
import { WatchAttributesAffiliated } from "./Components/watchDetailTable/WatchAttributesAffiliated";
import { MovementAttributes } from "./Components/watchDetailTable/MovementAttributes";
import { WatchAttributesGroups } from "./Components/watchDetailTable/WatchAttributesGroups";
import { WatchDetails } from "./Components/watchDetailTable/WatchDetails";
import { MovementDetails } from "./Components/watchDetailTable/MovementDetails";
import { ShippingDetails } from "./Components/watchDetailTable/ShippingDetails";
import { WatchDetailsSeo } from "./Components/watchDetailTable/WatchDetailsSeo";
import { WatchDetailsTabs } from "./Components/watchDetailTable/WatchDetailsTabs";
import { WatchDetailsGroups } from "./Components/watchDetailTable/WatchDetailsGroups";

export * from "./Components/watchDetailTable/ConfirmDialog";
export * from "./Components/watchDetailTable/ConfirmButton";

export const MainPages = {
  home: {
    anon: false,
    path: "/",
    title: "Home",
    type: "PAGE_HOME",
    view: HomePage,
  },
  watchesList: {
    anon: false,
    path: "/watches-list",
    title: "Watches Lit",
    type: "PAGE_WATCHES_LIST",
    view: WatchesPage,
  },
  addWatch: {
    anon: false,
    path: "/add-watch",
    title: "Add Watches",
    type: "PAGE_ADD_WATCH",
    view: AddWatchPage,
  },

  editWatch: {
    anon: false,
    path: "/edit-watch/:id",
    title: "Edit Watches",
    type: "PAGE_EDIT_WATCH",
    view: AddWatchPage,
  },
  history: {
    anon: false,
    path: "/history/:id",
    title: "History",
    type: "HISTORY",
    view: WatchHistory,
  },
  WatchAttributes: {
    anon: false,
    path: "/watch-attributes/:attribute",
    title: "Watch Attributes",
    type: "WATCH_ATTRIBUTES",
    view: WatchAttributes,
  },
  Records: {
    anon: false,
    path: "/records",
    title: "Records",
    type: "RECORDS",
    view: RecordsPage,
  },
  SiteAttributes: {
    anon: false,
    path: "/manage-site-attributes/:attribute",
    title: "Site Attributes",
    type: "SITE_ATTRIBUTES",
    view: SiteAttributes,
  },
  ShippingSiteAttributes: {
    anon: false,
    path: "/manage-shipping-tiers/:attribute",
    title: "Ship Attributes",
    type: "SHIP_ATTRIBUTES",
    view: ShippingSiteAttributes,
  },
  WatchAttributeGroups: {
    anon: false,
    path: "/watch-attribute-groups/:attribute",
    title: "Watch Attribute Groups",
    type: "WATCH_ATTRIBUTE_GROUPS",
    view: WatchAttributesGroups,
  },
  WatchAttributesAffiliated: {
    anon: false,
    path: "/watch-attribute-affiliated/:attribute",
    title: "Watch Attribute Groups",
    type: "WATCH_ATTRIBUTE_AFFILIATED",
    view: WatchAttributesAffiliated,
  },
  MovementAttributes: {
    anon: false,
    path: "/movement-attribute/:attribute",
    title: "Movement Attribute",
    type: "MOVEMENT_ATTRIBUTE_AFFILIATED",
    view: MovementAttributes,
  },
  ShippingDetails: {
    anon: false,
    path: "/shipping-details/:attribute/:detail/:id/",
    title: "Shipping Details",
    type: "SHIPPING_DETAILS",
    view: ShippingDetails,
  },
  MovementDetails: {
    anon: false,
    path: "/movement-details/:attribute/:detail/:id/",
    title: "Movement Details",
    type: "MOVEMENT_DETAILS_BRANDS",
    view: MovementDetails,
  },
  MovementDetailsBrands: {
    anon: false,
    path: "/movement-details/:attribute/:detail/:brand/:id/",
    title: "Movement Details",
    type: "MOVEMENT_DETAILS",
    view: MovementDetails,
  },
  WatchDetails: {
    anon: false,
    path: "/watch-details/:attribute/:detail/:id/",
    title: "Watch Details",
    type: "WATCH_DETAILS",
    view: WatchDetails,
  },
  WatchDetailsSeo: {
    anon: false,
    path: "/watch-details-seo/:attribute/:detail/:id/:brand_id",
    title: "Watch Details",
    type: "WATCH_DETAILS_SEO",
    view: WatchDetailsSeo,
  },
  WatchDetailsTabs: {
    anon: false,
    path: "/watch-details-tabs/:attribute/:detail/:id/",
    title: "Watch Details",
    type: "WATCH_DETAILS_TABS",
    view: WatchDetailsTabs,
  },
  WatchDetailsGroups: {
    anon: false,
    path: "/watch-details-groups/:attribute/:detail/:id/",
    title: "Watch Details",
    type: "WATCH_DETAILS_GROUPS",
    view: WatchDetailsGroups,
  },
  WatchDetailsBrands: {
    anon: false,
    path: "/watch-details/:attribute/:detail/:brand/:id/",
    title: "Watch Details",
    type: "WATCH_DETAILS_BRANDS",
    view: WatchDetails,
  },
  WatchDetailsSeoBrands: {
    anon: false,
    path: "/watch-details-seo/:attribute/:detail/:id/:brand/:id/",
    title: "Watch Details",
    type: "WATCH_DETAILS_SEO_BRANDS",
    view: WatchDetailsSeo,
  },
  WatchDetailsTabsBrands: {
    anon: false,
    path: "/watch-details-tabs/:attribute/:detail/:brand/:id/",
    title: "Watch Details",
    type: "WATCH_DETAILS_TABS_BRANDS",
    view: WatchDetailsTabs,
  },
  WatchDetailsGroupsBrands: {
    anon: false,
    path: "/watch-details-groups/:attribute/:detail/:brand/:id/",
    title: "Watch Details",
    type: "WATCH_DETAILS_GROUPS_BRANDS",
    view: WatchDetailsGroups,
  },
  notAuthorized: {
    anon: true,
    layout: null,
    path: "/unauthorized",
    title: "Not Authorized",
    type: "PAGE_NOT_AUTHORIZED",
    view: NotAuthorizedPage,
  },
  notFound: {
    anon: true,
    layout: null,
    path: "*",
    title: "Page Not Found",
    type: "PAGE_NOT_FOUND",
    view: NotFoundPage,
  },
  addUser: {
    anon: false,
    path: "/manage-users",
    title: "Manage User",
    type: "PAGE_MANAGE_USER",
    view: UserPage,
  },
};
export default MainPages;

export const MainArea = {
  pages: MainPages,
};
