import React from "react";
import { Typography } from "@material-ui/core";

function _Page2Page() {
  return (
    <>
      <Typography variant="h3" style={{ marginBottom: 32 }}>
        Lets go! Lets add a watch!
      </Typography>
    </>
  );
}

export const Page2Page = React.memo(_Page2Page);
