import { WatchActions } from "./actions";

/**
 * Watch lists state (**NOT** persisted).
 * @example
 * {
 *    items: [
 *      { id: 1, title: "", done: false, },
 *      { id: 2, title: "", done: false, },
 *      { id: 3, title: "", done: false, },
 *    ],
 * }
 */

export const WatchState = {
  name: "watches",
  persist: false,
  defaults: {
    watchLists: {
      brand: [],
      band_color: [],
      band_material: [],
      material: [],
      case_shape: [],
      bezel: [],
      crystal: [],
      size: [],
      warranty: [],
      series: [],
      status: [],
      ship_tier: [],
      delivery: [],
      vendor: [],
      dial_color: [],
      case_finish: [],
      subdial_color: [],
      dial_finish: [],
      hands_material: [],
      hands_finish: [],
      second_hand: [],
      //hard code this. automatically generated list
      // date_location: generateHalfHourOptions(),
      bezel_material: [],
      bezel_finish: [],
      bezel_characteristics: [],
      case_back: [],
      //hard code this. I don't think it is being built out
      luminescence: [
        { luminescence_id: 1, luminescence: "Extremely Visible" },
        { luminescence_id: 2, luminescence: "Standard Visibility" },
        { luminescence_id: 3, luminescence: "Basically Visible" },
        { luminescence_id: 2, luminescence: "Weak Visibility" },
      ],

      //as of now, not using this
      // date_type: [
      //   { date_type_id: 1, date_type: "Window" },
      //   { date_type_id: 2, date_type: "Hand" },
      //   { date_type_id: 3, date_type: "Subdial" },
      // ],
    },
    attributeList: [],
    // detailsList: []
  },
  handlers: {
    [WatchActions.type.GET_WATCH_LISTS](state, { payload }) {
      return {
        ...state,
        watchLists: {
          ...payload,
        },
      };
    },
    [WatchActions.type.GET_ATTRIBUTES_LISTS](state, { payload }) {
      return {
        ...state,
        attributeList: payload,
      };
    },
    // [WatchActions.type.GET_DETAILS_LISTS](state, { payload }) {
    //   return {
    //     ...state,
    //     detailsList: payload,
    //   };
    // },
    [WatchActions.type.GET_WATCH_LIST](state, { payload }) {
      return {
        ...state,
        watchLists: { ...state.watchLists, ...payload },
      };
    },
    [WatchActions.type.ADD_TO_WATCH_LIST](state, { payload }) {
      const attr = Object.keys(payload).filter((x) => {
        return x.endsWith("_id");
      });
      let attribute = attr[0].slice(0, -3);
      if (attribute === "case_material") {
        attribute = "material";
      }
      return {
        ...state,
        watchLists: {
          ...state.watchLists,
          [attribute]: [...state?.watchLists[attribute], payload],
        },
      };
    },
  },
};
// function generateHalfHourOptions() {
//   let hour = 1;
//   let uniqueKey = 1;
//   const options = [];

//   while (hour < 13) {
//     options.push({ key: uniqueKey, date_location: `${hour}:00` });
//     uniqueKey++;
//     options.push({ key: uniqueKey, date_location: `${hour}:30` });
//     uniqueKey++;
//     hour++;
//   }
//   return options;
// }
