import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Box } from "@material-ui/core";
import { WatchActions } from "../../../../state";
import { GroupTransferBox } from ".";
import {
  format_Attribute,
  item_format_Attribute,
  format_Word,
  addS,
  not,
  intersection,
  useForceUpdate,
} from "../../../../lib";
import { ConfirmButton } from "./ConfirmButton";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { Link } from "react-router-dom";

export const GroupTransferList = (props) => {
  const siteId = useSelector((state) => state.scopes.site_id);
  const dispatch = useDispatch();
  const [left, setLeft] = useState();
  const [copyLeft, setCopyLeft] = useState();
  const [right, setRight] = useState();
  const [disableUpArrow, setDisableUpArrow] = useState();
  const [disableDownArrow, setDisableDownArrow] = useState();
  const [checked, setChecked] = useState([]);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const useStyles = makeStyles((theme) => ({
    root: {
      margin: "auto",
    },
    list: {
      width: "100%",
      height: "100%",
      minHeight: 350,
      minWidth: 350,
      // overflow: "auto",
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
  }));
  let forceUpdate = useForceUpdate();

  if (props.myBrandId && props.groupDetailsData) {
    props.groupDetailsData.brand_id = props.myBrandId;
  }
  const onSubmit = () => {
    dispatch(
      WatchActions.saveDetails({
        key: `/group-details-value/${props.groupId}?field=${props.attribute}&site=${siteId}`,
        groupDetailsData: props.groupDetailsData,
        right: right,
        attribute: props.attribute,
      }),
    );
  };

  function moveUp(selected, right) {
    let index = right.findIndex((title) => title === selected);
    if (index > 0) {
      let el = right[index];
      right[index] = right[index - 1];
      right[index - 1] = el;
    }
  }
  function moveDown(selected, right) {
    let index = right.findIndex((title) => title === selected);
    if (index !== -1 && index < right.length - 1) {
      let el = right[index];
      right[index] = right[index + 1];
      right[index + 1] = el;
    }
  }
  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setCopyLeft(not(copyLeft, leftChecked));
    setChecked(not(checked, leftChecked));
    props.setIsDisabled(false);
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setCopyLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    props.setIsDisabled(false);
  };
  const handleCheckedUp = () => {
    const selected = rightChecked[0];
    moveUp(selected, right);
    checkToDisable(selected);
  };
  const handleCheckedDown = () => {
    const selected = rightChecked[0];
    moveDown(selected, right);
    checkToDisable(selected);
  };
  const checkToDisable = (selected) => {
    let index = right.findIndex((title) => title === selected);
    if (rightChecked.length === 1 && index === 0) {
      setDisableUpArrow(true);
      setDisableDownArrow(false);
    } else if (rightChecked.length === 1 && index === right.length - 1) {
      setDisableDownArrow(true);
      setDisableUpArrow(false);
    } else if (rightChecked.length === 1) {
      setDisableUpArrow(false);
      setDisableDownArrow(false);
    } else {
      setDisableUpArrow(true);
      setDisableDownArrow(true);
    }
    props.setIsDisabled(false);
    forceUpdate();
  };

  useEffect(() => {
    (async function () {
      if (
        (props.attribute === "series_group" && props.editBrand) ||
        props.attribute !== "series_group"
      ) {
        const leftBoxData = await dispatch(
          WatchActions.getAttribute(
            format_Attribute(props.attribute),
            props.editBrand,
            siteId,
          ),
        );
        const leftArray = leftBoxData.map((row) => {
          return {
            name: row[format_Attribute(props.attribute)],
            id: row[format_Attribute(props.attribute) + "_id"],
          };
        });

        const rightArray = props.series.map((row) => {
          return {
            name:
              row[item_format_Attribute(props.attribute)][
                format_Attribute(props.attribute)
              ],
            id: row[format_Attribute(props.attribute) + "_id"],
          };
        });

        let difference = leftArray.filter((x) => {
          return !rightArray.map((rightItem) => rightItem.id).includes(x.id);
        });

        setLeft(difference);
        setCopyLeft(difference);
        setRight(rightArray);
      }
    })();
  }, [
    dispatch,
    props.attribute,
    props.editBrand,
    props.id,
    props.series,
    props.siteId,
  ]);

  const classes = useStyles();

  return (
    <div style={{ width: "100%" }}>
      {left && right && (
        <Grid
          container
          spacing={2}
          justify="left"
          alignItems="stretch"
          className={classes.root}
        >
          <Grid item style={{ height: "100%", width: "calc(50% - 40px)" }}>
            <GroupTransferBox
              title={`Remaining ${addS(
                format_Word(format_Attribute(props.attribute)),
              )}`}
              attribute={props.attribute}
              items={left}
              id={"leftBox"}
              filterTitle={"Filter by"}
              setList={setLeft}
              list={left}
              setCopy={setCopyLeft}
              copy={copyLeft}
              checked={checked}
              setChecked={setChecked}
              sort={true}
            />
          </Grid>

          <Grid item style={{ height: "100%", width: "80px" }}>
            <Grid container direction="column" alignItems="center">
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0 || siteId !== 0}
                aria-label="move selected right"
              >
                <KeyboardArrowRightIcon />
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0 || siteId !== 0}
                aria-label="move selected left"
              >
                <KeyboardArrowLeftIcon />
              </Button>

              <Box mt={1}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={props.isDisabled || siteId !== 0}
                  onClick={onSubmit}
                  component={Link}
                  to={
                    props.attribute.startsWith("series")
                      ? `/watch-attribute-affiliated/${props.attribute}`
                      : `/watch-attribute-groups/${props.attribute}`
                  }
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Grid item style={{ height: "100%", width: "calc(50% - 40px)" }}>
            <GroupTransferBox
              title={`Added ${addS(
                format_Word(format_Attribute(props.attribute)),
              )}`}
              attribute={props.attribute}
              items={right}
              id={"rightBox"}
              setList={setRight}
              list={right}
              checked={checked}
              setChecked={setChecked}
              moveList={true}
              rightChecked={rightChecked}
              handleCheckedUp={handleCheckedUp}
              handleCheckedDown={handleCheckedDown}
              disableUpArrow={disableUpArrow}
              disableDownArrow={disableDownArrow}
              setDisableUpArrow={setDisableUpArrow}
              setDisableDownArrow={setDisableDownArrow}
              right={right}
              fullRight={props.series}
              selected={rightChecked[0]}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};
