import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogActions, DialogTitle, Button } from "@material-ui/core";
import { useDispatch } from "../../../../state";
import { Link } from "react-router-dom";
import { buttonSubmitted } from "../../../../lib/helpers";

interface ConfirmDialogProps {
  attribute?: string;
  myValue?: string;
  oldValue?: string;
  myBrand?: string;
  myBrandId?: number;
  id?: number;
  groupId?: number;
  rawAttribute?: string;
  path?: string;
  Add_Edit_Delete?: string;
  color?: any;
  open: boolean;
  setOpen?: any;
  onClose?: any;
  right?: any;
  editName?: any;
  editBrand?: any;
  siteId?: number;
  shipData?: any;
  setNewFormik?: any;
}

export function ConfirmDialog(props: ConfirmDialogProps) {
  const dispatch = useDispatch();
  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      disableEscapeKeyDown
    >
      {!props.right ? (
        <DialogTitle
          id="simple-dialog-title"
          style={{ whiteSpace: "pre-line" }}
        >
          Are you sure you want to {props.Add_Edit_Delete} {props.attribute}
          {props.Add_Edit_Delete === "edit"
            ? " from \n" + props.oldValue + "\n to \n"
            : " - "}
          {props.myValue}?
        </DialogTitle>
      ) : (
        <DialogTitle
          id="simple-dialog-title"
          style={{ whiteSpace: "pre-line" }}
        >
          Are you sure you want to Save Changes on Group name, brand
          affiliation, adding to remaining series, changing order in remanding
          series
        </DialogTitle>
      )}

      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            handleClose();
          }}
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            buttonSubmitted({
              Add_Edit_Delete: props.Add_Edit_Delete,
              rawAttribute: props.rawAttribute,
              myValue: props.myValue,
              myBrandId: props.myBrandId,
              id: props.id,
              attribute: props.attribute,
              dispatch,
              siteId: 0, // hardcoded for now
              shipData: props.shipData,
              setNewFormik: props.setNewFormik,
            });
            handleClose();
          }}
          component={Link}
          to={`/${props.path}/${props.rawAttribute}`}
          color="primary"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  // selectedValue: PropTypes.string.isRequired,
};
