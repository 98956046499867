import { UIActions } from "..";
import { Dispatch } from "redux";
import { WatchFormState } from "./state";
import { authGet } from "../../lib";

const type = {
  CLEAR_FORM: "CLEAR_FORM",
  SAVE_FORM: "SAVE_FORM",
};

export const WatchFormActions = {
  type,
  submitForm(formValues: any) {
    return async (dispatch: Dispatch) => {
      dispatch({ type: UIActions.type.UI_LOADING_SET, value: true });
      dispatch({ type: UIActions.type.UI_LOADING_SET, value: false });
    };
  },
  saveForm(formValues: WatchFormState) {
    return async (dispatch: Dispatch, getState) => {
      const { watchForm } = getState();
      dispatch({
        type: WatchFormActions.type.SAVE_FORM,
        payload: formValues,
      });
    };
  },
  getWatchForm(watchId: number) {
    return async (dispatch: Dispatch, getState: any) => {
      console.log("getWatchForm");
      dispatch({ type: WatchFormActions.type.CLEAR_FORM });
      const {
        scopes: { siteId = 0 },
        watchForm,
      } = getState();
      console.log("watchForm after clear", watchForm);

      if (!watchForm || watchForm.item_id === watchId) return;
      //TODO: decide if above is good idea
      dispatch({ type: UIActions.type.UI_LOADING_SET, value: true });
      const url = `item/${watchId}?site=${siteId}`;
      const { data } = await authGet(url);
      console.log({ data });
      dispatch({
        type: WatchFormActions.type.SAVE_FORM,
        payload: data,
      });
      dispatch({ type: UIActions.type.UI_LOADING_SET, value: false });
    };
  },
  clearForm() {
    console.log("clearForm");
    return { type: type.CLEAR_FORM };
  },
};
