const type = {
  SET_SCOPE: "SET_SCOPE",
  UPDATE_SCOPE: "UPDATE_SCOPE",
};

export const ScopeActions = {
  type,

  getDefaultScope() {
    return (dispatch, getState) => {
      const {
        auth: { user: { roles } = {} },
      } = getState();
      const allRoles = [
        { id: 0, role: "prestige", text: "Prestige Time" },
        { id: 1, role: "tws", text: "The Watch Source" },
        { id: 2, role: "tpc", text: "Timepiece Collection" },
      ];
      //get the default scope based on users permissions
      const allowed = roles.includes("admin")
        ? allRoles
        : allRoles.filter((item) => roles.includes(item.role));
      dispatch({
        type: type.SET_SCOPE,
        payload: {
          scopes_allowed: allowed,
          selected_scope: allowed[0].role,
          site_id: allowed[0].id,
        },
      });
    };
  },
  updateScope(i) {
    return (dispatch, getState) => {
      dispatch({
        type: type.UPDATE_SCOPE,
        payload: i,
      });
    };
  },
};
