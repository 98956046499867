import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import {
  DataGrid,
  GridToolbarContainer,
  GridFilterToolbarButton,
} from "@material-ui/data-grid/";
import { QuickSearchToolbar, escapeRegExp } from "../../../lib/utils";
import { WatchActions } from "../../../state";

export const WatchHistory = (props) => {
  const {
    pageRoute: {
      params: { id },
    },
  } = props;
  const [data, setData] = useState();
  const siteId = useSelector((state) => state.scopes.site_id);

  useEffect(() => {
    (async function () {
      const data = await WatchActions.getHistory(id, siteId);
      setData(data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    { field: "id", headerName: "ID", flex: 0.2 },
    { field: "date", headerName: "Date Changed", flex: 0.4 },
    { field: "edits", headerName: "Edits", flex: 1.4 },
    { field: "user", headerName: "Changed By", flex: 0.4 },
  ];

  let historyRows = [];
  if (data?.history.length) {
    historyRows = data?.history.map((row) => {
      return {
        id: format(new Date(row.date_changed), "T"),
        user: row.changed_by,
        date: format(new Date(row.date_changed), "PPpp"),
        edits: row.data,
      };
    });
  }
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridFilterToolbarButton />
      </GridToolbarContainer>
    );
  }
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState(historyRows);
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = historyRows.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field]?.toString());
      });
    });
    setRows(filteredRows);
  };
  const [firstTime, setFirstTime] = useState(true);
  useEffect(() => {
    if (firstTime && historyRows?.length) {
      setFirstTime(false);
      setRows(historyRows);
    }
  }, [historyRows]);
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <DataGrid
        rows={rows}
        // rows={historyRows}
        columns={columns}
        disableColumnMenu={true}
        isRowSelectable={false}
        checkboxSelection={false}
        autoHeight={true}
        rowHeight={38}
        // components={{
        //   Toolbar: CustomToolbar,
        // }}
        sortModel={[
          {
            field: "id",
            sort: "asc",
          },
        ]}
        components={{ Toolbar: QuickSearchToolbar }}
        componentsProps={{
          toolbar: {
            value: searchText,
            onChange: (event) => {
              requestSearch(event.target.value);
            },
            clearSearch: () => requestSearch(""),
          },
        }}
      />
      <br />
    </div>
  );
};
