import { Page2Page } from "./Page2Page";

const roles = ["admin"];

export const MiscPages = {
  page2: {
    anon: false,
    roles,
    path: "/page-2",
    title: "Page Two",
    type: "PAGE_TWO",
    view: Page2Page,
  },
};
export default MiscPages;

export const MiscArea = {
  pages: MiscPages,
};
