import React from "react";
import { useSelector } from "react-redux";

import DeleteIcon from "@material-ui/icons/Delete";
import { ConfirmDialog } from ".";
import { styled } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import MuiButton from "@material-ui/core/Button";

interface Props {
  attribute: string;
  myValue?: string;
  oldValue?: string;
  myBrand?: string;
  myBrandId?: number;
  id?: number;
  groupId?: number;
  rawAttribute?: string;
  Add_Edit_Delete: string;
  color: any;
  editName?: any;
  editBrand?: any;
  right?: any;
  disabled: boolean;
  caseBackCategory?: string;
  path?: string;
  siteId?: number;
  shipData?: any;
  checked2?: any;
}
const Button = styled(MuiButton)(spacing);

export function ConfirmButton(props: Props) {
  const siteId = useSelector((state: any) => state.scopes.site_id);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <span>
      <Button
        variant={props.checked2 ? "outlined" : "text"}
        color={props.color}
        onClick={handleClickOpen}
        disabled={props.disabled}
        size="large"
      >
        {props.Add_Edit_Delete === "delete" ? (
          <DeleteIcon />
        ) : (
          props.Add_Edit_Delete + " " + props.attribute
        )}
      </Button>
      <ConfirmDialog
        right={props.right}
        editBrand={props.editBrand}
        attribute={props.attribute}
        myValue={props.myValue}
        oldValue={props.oldValue}
        myBrand={props.myBrand}
        myBrandId={props.myBrandId}
        open={open}
        setOpen={setOpen}
        onClose={handleClose}
        rawAttribute={props.rawAttribute}
        Add_Edit_Delete={props.Add_Edit_Delete}
        
        id={props.id}
        groupId={props.groupId}
        editName={props.editName}
        path={props.path}
        siteId={siteId}
        shipData={props.shipData}
      />
    </span>
  );
}
