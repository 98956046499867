import React, { useEffect, useState } from "react";
import { Typography, Box } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid/";
import { WatchActions } from "../../../../state";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  format_Word,
  getDetailButton,
  showDisabled,
  showBrandsStatus,
} from "../../../../lib";
import { AddWatchAttribute } from ".";
import { QuickSearchToolbar, escapeRegExp } from "../../../../lib/utils";
export function WatchAttributesAffiliated(props) {
  const dispatch = useDispatch();
  const {
    pageRoute: {
      params: { attribute },
    },
  } = props;
  const siteId = useSelector((state) => state.scopes.site_id);
  const [hide, setHide] = useState(false);
  const [attrRows, setAttrRows] = useState([]);
  const [brandAffiliated, setBrandAffiliated] = useState(false);
  const [groupAffiliated, setGroupAffiliated] = useState(false);
  const [materialAffiliated, setMaterialAffiliated] = useState(false);
  const [dialAffiliated, setDialAffiliated] = useState(false);
  const [handsAffiliated, setHandsAffiliated] = useState(false);
  const [hidden, setHidden] = useState();
  const [deactivateBrandPage, setDeactivateBrandPage] = useState();

  const attrData = useSelector(
    (state) => state.watches.attributeList,
    shallowEqual,
  );
  useEffect(() => {
    (async function () {
      if (attribute === "series_group") {
        await dispatch(WatchActions.getAttributeGroups(attribute, siteId));
      } else await dispatch(WatchActions.getAttribute(attribute, null, siteId));
    })();
  }, [attribute, siteId]);
  useEffect(() => {
    setHidden(attrData[0]?.hasOwnProperty("hide"));
    setDeactivateBrandPage(attrData[0]?.hasOwnProperty("deactivate_brand"));
    setBrandAffiliated(
      attrData[0]?.hasOwnProperty("brand_id") &&
        !attrData[0]?.hasOwnProperty("deactivate_brand"),
    );
    setGroupAffiliated(
      attrData[0]?.hasOwnProperty("item_" + attribute + "_groups"),
    );
    setMaterialAffiliated(attrData[0]?.hasOwnProperty("button_material_count"));
    setDialAffiliated(attrData[0]?.hasOwnProperty("dial_color_id"));
    setHandsAffiliated(attrData[0]?.hasOwnProperty("hands_material_id"));
    if (attrData[0]?.hasOwnProperty(`${attribute}_id`)) {
      const rows = attrData.map((row) => {
        let id = row[`${attribute}_id`];

        var tableRow = {
          id: id,
          button: row,
          name: row[attribute],
          count: row.count,
          caseMaterialCount: null,
          crownMaterialCount: null,
          buttonMaterialCount: null,
          handsMaterialCount: null,
          secondsHandMaterialCount: null,
          dialColorCount: null,
          dialFlangeColorCount: null,
          brand: null,
          group: null,
          hidden: null,
          status: null,
        };
        if (hidden) {
          tableRow.hidden = row.hide;
        }
        if (deactivateBrandPage) {
          tableRow.status = row.deactivate_brand;
        }

        if (brandAffiliated) {
          if (row.item_brand) {
            tableRow.brand = row.item_brand.brand;
          }
        }

        if (groupAffiliated) {
          if (row[`item_${attribute}_groups`][0]) {
            tableRow.group =
              row[`item_${attribute}_groups`][0][`${attribute}_group`];
          }
        }

        if (materialAffiliated) {
          tableRow.count = null;
          tableRow.caseMaterialCount = row.case_material_count;
          tableRow.crownMaterialCount = row.crown_material_count;
          tableRow.buttonMaterialCount = row.button_material_count;
        }
        if (handsAffiliated) {
          tableRow.count = null;
          tableRow.handsMaterialCount = row.hands_material_count;
          tableRow.secondsHandMaterialCount = row.second_hand_material_count;
        }
        if (dialAffiliated) {
          tableRow.count = null;
          tableRow.dialColorCount = row.dial_color_count;
          tableRow.dialFlangeColorCount = row.dial_flange_color_count;
        }

        return tableRow;
      });
      setAttrRows(rows);
    }
  }, [
    attrData,
    attribute,
    brandAffiliated,
    deactivateBrandPage,
    dialAffiliated,
    groupAffiliated,
    handsAffiliated,
    hidden,
    materialAffiliated,
  ]);
  const attrColumns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    { field: "name", headerName: "Name", flex: 1 },
    {
      field: "count",
      headerName: "Count",
      type: "number",
      flex: 0.4,
      headerAlign: "left",
      align: "left",
      hide: materialAffiliated || handsAffiliated || dialAffiliated,
      filterable: !materialAffiliated || !handsAffiliated || !dialAffiliated,
    },
    {
      field: "caseMaterialCount",
      headerName: "Case Material Count",
      type: "number",
      flex: 0.4,
      headerAlign: "left",
      align: "left",
      hide: !materialAffiliated,
      filterable: materialAffiliated,
    },
    {
      field: "crownMaterialCount",
      headerName: "Crown Material Count",
      type: "number",
      flex: 0.4,
      headerAlign: "left",
      align: "left",
      hide: !materialAffiliated,
      filterable: materialAffiliated,
    },
    {
      field: "buttonMaterialCount",
      headerName: "Button Material Count",
      type: "number",
      flex: 0.4,
      headerAlign: "left",
      align: "left",
      hide: !materialAffiliated,
      filterable: materialAffiliated,
    },
    {
      field: "handsMaterialCount",
      headerName: "Hands Material Count",
      type: "number",
      flex: 0.4,
      headerAlign: "left",
      align: "left",
      hide: !handsAffiliated,
      filterable: handsAffiliated,
    },
    {
      field: "secondsHandMaterialCount",
      headerName: "Seconds Hand Material Count",
      type: "number",
      flex: 0.4,
      headerAlign: "left",
      align: "left",
      hide: !handsAffiliated,
      filterable: handsAffiliated,
    },
    {
      field: "dialColorCount",
      headerName: "Dial Color Count",
      type: "number",
      flex: 0.4,
      headerAlign: "left",
      align: "left",
      hide: !dialAffiliated,
      filterable: dialAffiliated,
    },
    {
      field: "dialFlangeColorCount",
      headerName: "Dial Flange Color Count",
      type: "number",
      flex: 0.4,
      headerAlign: "left",
      align: "left",
      hide: !dialAffiliated,
      filterable: dialAffiliated,
    },
    {
      field: "brand",
      headerName: "Brand Affiliation",
      flex: 1,
      hide: !brandAffiliated,
      filterable: brandAffiliated,
    },
    {
      field: "group",
      headerName: "Group",
      flex: 1,
      hide: !groupAffiliated,
      filterable: groupAffiliated,
    },
    {
      field: "hidden",
      headerName: "Hidden",
      flex: 0,
      hide: !hidden,
      filterable: false,
      renderCell: (params) => {
        return showDisabled(params.value);
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.3,
      hide: !deactivateBrandPage,
      filterable: false,
      renderCell: (params) => {
        return showBrandsStatus(!params.value);
      },
    },
    {
      field: "button",
      headerName: "Details",
      flex: 1,
      hide: hide,
      filterable: false,
      renderCell: (params) => {
        let path = "watch-details";
        if (attribute === "series_group") {
          path = "watch-details-groups";
        } else if (materialAffiliated || dialAffiliated || handsAffiliated) {
          path = "watch-details-tabs";
        } else {
          path = "watch-details";
        }
        return getDetailButton(
          path,
          params.value,
          attribute,
          brandAffiliated,
          siteId,
        );
      },
    },
  ];

  let isCaseShape = false;
  if (attribute === "case_shape") {
    isCaseShape = true;
  }
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState(attrRows);
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = attrRows.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field]?.toString());
      });
    });
    setRows(filteredRows);
  };
  useEffect(() => {
    if (attrRows?.length) {
      setRows(attrRows);
    }
  }, [attrRows]);
  return (
    <Box
      style={{
        width: "100%",
      }}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="h3">{format_Word(attribute)}</Typography>
      </div>
      <AddWatchAttribute
        attribute={format_Word(attribute)}
        rawAttribute={attribute}
        brandAffiliated={brandAffiliated}
        isCaseShape={isCaseShape}
        path={"watch-attribute-affiliated"}
      />
      <div>
        <DataGrid
          rows={rows}
          columns={attrColumns}
          disableColumnMenu={true}
          isRowSelectable={false}
          pageSize={100}
          checkboxSelection={false}
          autoHeight={true}
          rowHeight={38}
          sortModel={[
            {
              field: "name",
              sort: "asc",
            },
          ]}
          components={{ Toolbar: QuickSearchToolbar }}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event) => {
                requestSearch(event.target.value);
              },
              clearSearch: () => requestSearch(""),
            },
          }}
        />
      </div>
    </Box>
  );
}
