import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Typography,
  InputAdornment,
  Box,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import {
  DataGrid,
  GridToolbarContainer,
  GridFilterToolbarButton,
  getGridNumericColumnOperators,
} from "@material-ui/data-grid/";
import { WatchActions } from "../../../../state";
import {
  moneyFormatter,
  GridButtons,
  getSiteSpecificPrice,
  getSiteSpecificRetailPrice,
  getSiteSpecificStatus,
  getSiteSpecificDateAdded,
  format_Word,
  statusColor,
} from "../../../../lib";
import { EditWatchDetails } from ".";
import { withStyles } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";
import { QuickSearchToolbar, escapeRegExp } from "../../../../lib/utils";
export function WatchDetails(props) {
  const [rows, setRows] = useState([]);
  const [checked, setChecked] = useState();
  const [groupDetailsData, setGroupDetailsData] = useState();
  const [myBrandId, setMyBrandId] = useState();
  const [isDisabled, setIsDisabled] = useState(true);
  const GreenSwitch = withStyles({
    switchBase: {
      color: "#C8C8C8",
      "&$checked": {
        color: "#315179",
      },
      "&$checked + $track": {
        backgroundColor: "#C8C8C8",
      },
    },
    checked: {},
    track: {},
  })(Switch);
  const siteId = useSelector((state) => state.scopes.site_id);

  const {
    pageRoute: {
      params: { attribute, id, detail, brand },
    },
  } = props;
  useEffect(() => {
    (async function () {
      if (checked) {
        const data = await WatchActions.getDetails(attribute, id, siteId, true);
        setRows(data);
      } else {
        const data = await WatchActions.getDetails(attribute, id, siteId);
        setRows(data);
      }
    })();
  }, [checked, attribute, id, siteId]);

  const price = {
    type: "parseFloat",
    flex: 0.4,
    valueFormatter: ({ value }) => moneyFormatter(Number(value), siteId),
    headerAlign: "left",
    align: "left",
    extendType: "parseFloat",
    filterOperators: getGridNumericColumnOperators()
      .filter((operator) => operator.value === ">" || operator.value === "<")
      .map((operator) => {
        return {
          ...operator,
          InputComponentProps: {
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            },
          },
        };
      }),
  };

  const detailsColumns = [
    { field: "model", headerName: "Model", flex: 1 },
    { field: "title", headerName: "Title", flex: 2, sort: "asc" },
    { field: "price", headerName: "Price", ...price },
    { field: "retail", headerName: "Retail Price", ...price },
    {
      field: "status",
      headerName: "Status",
      flex: 0.4,
      renderCell: (params) => {
        return statusColor(params.value);
      },
      filterable: false,
    },
    { field: "dateAdded", headerName: "Date Added", type: "date", flex: 0.5 },
    {
      field: "buttons",
      headerName: "View",
      flex: 0.7,
      renderCell: (params) => {
        return GridButtons(params.value);
      },
      filterable: false,
    },
  ];

  const detailsRows = rows?.map((row) => {
    return {
      id: row.item_id,
      model: row.model_no,
      title: row.title,
      price: getSiteSpecificPrice(row.price, siteId),
      retail: getSiteSpecificRetailPrice(row, siteId),
      status: getSiteSpecificStatus(row, siteId),
      dateAdded: getSiteSpecificDateAdded(row, siteId),
      buttons: row,
    };
  });
  const handleChange = () => {
    if (checked) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  };
  const [searchText1, setSearchText1] = useState("");
  const [rows1, setRows1] = useState(detailsRows);
  const requestSearch = (searchValue) => {
    console.log(searchValue);
    setSearchText1(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = detailsRows.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field]?.toString());
      });
    });
    setRows1(filteredRows);
  };
  useEffect(() => {
    if (detailsRows?.length) {
      setRows1(detailsRows);
    }
  }, [rows]);
  return (
    <Box
      style={{
        width: "100%",
      }}
    >
      <div>
        <div>
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Typography variant="h3">
                {format_Word(attribute) + " - " + detail + " (ID:" + id + ")"}
              </Typography>
            </div>
            <EditWatchDetails
              brandTitle={brand}
              attribute={attribute}
              id={id}
              detail={detail}
              rawAttribute={attribute}
              path={
                attribute === "warranty" ||
                attribute === "vendor" ||
                attribute === "delivery"
                  ? "manage-siteId-attributes"
                  : attribute === "series"
                  ? "watch-attribute-affiliated"
                  : "watch-attributes"
              }
              setGroupDetailsData={setGroupDetailsData}
              setMyBrandId={setMyBrandId}
              myBrandId={myBrandId}
              setIsDisabled={setIsDisabled}
              isDisabled={isDisabled}
            ></EditWatchDetails>
            <FormControlLabel
              control={
                <GreenSwitch
                  checked={checked}
                  onChange={handleChange}
                  name="checked"
                  color="primary"
                />
              }
              label={"All Watches"}
            />
            <DataGrid
              rows={rows1}
              columns={detailsColumns}
              disableColumnMenu={true}
              isRowSelectable={false}
              autoHeight={true}
              pageSize={100}
              checkboxSelection={false}
              rowHeight={38}
              components={{ Toolbar: QuickSearchToolbar }}
              componentsProps={{
                toolbar: {
                  value: searchText1,
                  onChange: (event) => {
                    requestSearch(event.target.value);
                  },
                  clearSearch: () => requestSearch(""),
                },
              }}
              sortModel={[
                {
                  field: "title",
                  sort: "asc",
                },
              ]}
            />
          </div>
        </div>
      </div>
    </Box>
  );
}
