import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  TextField,
  Typography,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid/";
import { useDispatch } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { authGet } from "../../lib";
import { WatchActions } from "../../state";
import { QuickSearchToolbar, escapeRegExp } from "../../lib/utils";

export function UserPage() {
  const siteId = useSelector((state) => state.scopes.site_id);
  const dispatch = useDispatch();
  const [value, setValue] = useState();
  const [data, setData] = useState();
  const [params, setParams] = useState();
  const [open, toggleOpen] = useState(false);
  const [openDelete, toggleOpenDelete] = useState(false);
  const [form, setForm] = useState({
    first_name: null,
    last_name: null,
    role_id: null,
    email: null,
  });
  const handleClose = () => {
    toggleOpen(false);
    toggleOpenDelete(false);
    setForm({ first_name: null, last_name: null, role_id: null, email: null });
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.2 },
    { field: "first_name", headerName: "First", flex: 0.4 },
    { field: "last_name", headerName: "Last", flex: 0.4 },
    { field: "email", headerName: "Email", flex: 0.4 },
    { field: "role", headerName: "Role", flex: 0.4 },
    {
      field: "delete",
      headerName: "Delete",
      flex: 0.4,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Button
            onClick={() => {
              setParams(params?.row);
              toggleOpenDelete(true);
            }}
            color="secondary"
          >
            Delete
          </Button>
        );
      },
    },
  ];

  const [options, setOptions] = useState();
  useEffect(() => {
    if (siteId === 0) {
      (async () => {
        const response = await authGet("/users/permissions");
        setOptions(response.data);
      })();
      (async () => {
        const response = await authGet("/users/details");
        setData(response.data);
      })();
    }
  }, [siteId]);
  let userDetails = [];
  if (data?.length) {
    userDetails = data?.map((row) => {
      return {
        id: row.id,
        first_name: row.first_name,
        last_name: row.last_name,
        email: row.email,
        role: row.roles.map((role) => role.role).join(", "),
      };
    });
  }

  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState(userDetails);
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = userDetails.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field]?.toString());
      });
    });
    setRows(filteredRows);
  };
  const [firstTime, setFirstTime] = useState(true);
  useEffect(() => {
    if (firstTime && userDetails?.length) {
      setFirstTime(false);
      setRows(userDetails);
    }
  }, [userDetails]);

  return (
    <>
      {siteId === 0 && (
        <>
          <Typography variant="h3">Manage Users</Typography>
          <Box m={1} display="flex" justifyContent="space-between">
            <Box m={1} display="flex" justifyContent="space-between">
              <TextField
                id="first_name"
                label="First Name"
                variant="outlined"
                onChange={(e) => {
                  setForm((prev) => ({
                    ...prev,
                    first_name: e.target.value || "",
                  }));
                }}
              />
            </Box>
            <Box m={1} display="flex" justifyContent="space-between">
              <TextField
                id="last_name"
                label="Last Name"
                variant="outlined"
                onChange={(e) => {
                  setForm((prev) => ({
                    ...prev,
                    last_name: e.target.value,
                  }));
                }}
              />
            </Box>
            <Box m={1} display="flex" justifyContent="space-between">
              <TextField
                id="email"
                label="Email"
                variant="outlined"
                onChange={(e) => {
                  setForm((prev) => ({
                    ...prev,
                    email: e.target.value,
                  }));
                }}
              />
            </Box>
            {options && (
              <Box m={1} display="flex" justifyContent="space-between">
                <Autocomplete
                  multiple
                  id="tags-standard"
                  options={options}
                  getOptionLabel={(option) => option.role || ""}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setForm((prev) => ({
                        ...prev,
                        role_id: newValue.map((role) => role.id),
                      }));
                      setValue(newValue);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params?.inputProps,
                        autoComplete: "new-password",
                      }}
                      variant="outlined"
                      label="Role"
                      placeholder="Add"
                    />
                  )}
                />
              </Box>
            )}

            <Box m={1} display="flex" justifyContent="space-between">
              <Button
                onClick={() => {
                  toggleOpen(true);
                }}
                m={3}
                variant="contained"
                color="primary"
                disabled={
                  !form.first_name ||
                  !form.last_name ||
                  !form.email ||
                  !form.role_id
                }
              >
                Add User
              </Button>
            </Box>

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="simple-dialog-title"
              disableEscapeKeyDown
            >
              <DialogTitle
                id="simple-dialog-title"
                style={{ whiteSpace: "pre-line" }}
              >
                Are you sure you want to add user:
                <br />
                {form.first_name}
                <br />
                {form.last_name}
                <br />
                {form.email}
                <br />
                {value?.map((role) => role.role).join(", ") || ""}
              </DialogTitle>

              <DialogActions>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleClose();
                  }}
                  color="secondary"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    dispatch(WatchActions.addUser(form));
                    handleClose();
                  }}
                  color="primary"
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openDelete}
              onClose={handleClose}
              aria-labelledby="simple-dialog-title"
              disableEscapeKeyDown
            >
              <DialogTitle
                id="simple-dialog-title"
                style={{ whiteSpace: "pre-line" }}
              >
                Are you sure you want to delete user:
                <br />
                {params?.first_name}
                <br />
                {params?.last_name}
                <br />
                {params?.email}
              </DialogTitle>

              <DialogActions>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleClose();
                  }}
                  color="secondary"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    dispatch(WatchActions.deleteUser(params.id));
                    handleClose();
                  }}
                  color="primary"
                  z
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
          <div style={{ height: "100%", width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              disableColumnMenu={true}
              isRowSelectable={false}
              checkboxSelection={false}
              autoHeight={true}
              rowHeight={38}
              sortModel={[
                {
                  field: "id",
                  sort: "asc",
                },
              ]}
              components={{ Toolbar: QuickSearchToolbar }}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) => {
                    requestSearch(event.target.value);
                  },
                  clearSearch: () => requestSearch(""),
                },
              }}
            />
            <br />
          </div>
        </>
      )}
    </>
  );
}
