import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import AddIcon from "@material-ui/icons/Add";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useFormikContext } from "formik";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { WatchHeader } from "../Components";

import {
  BottomNavigationAction,
  BottomNavigation,
  Step,
  Stepper,
  StepLabel,
  Badge,
  Paper,
} from "@material-ui/core";

import { authPut } from "../../../lib";
import {
  WatchModel,
  Pricing,
  Images,
  Case,
  Dial,
  BandAndBuckle,
  Movement,
} from "../Components";
import {
  WatchFormActions,
  useDispatch,
  WatchFormSelectors,
} from "../../../state";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  padding: {
    padding: 0,
  },
  margin: {
    margin: 0,
  },
  both: {
    padding: 0,
    margin: 0,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stickToBottom: {
    position: "fixed",
    bottom: 0,
    margin: 10,
  },
}));
function getSteps() {
  return [
    "Brand & Model",
    "Pricing & status",
    "Images",
    "Case description",
    "Dial description",
    "Band & Buckle",
    "Movement",
    "Review",
  ];
}

export const AddWatchStepper = (props) => {
  const siteId = 0; //useSelector((state) => state.scopes.site_id);
  const watchform = useSelector(WatchFormSelectors.watchform);
  const { values } = useFormikContext();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(WatchFormActions.saveForm(values));
  }, [values]);

  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(props.isEdit ? 1 : 0);
  const [requiredBrandBadge, setRequiredBrandBadge] = useState(0);
  const [requiredPriceBadge, setRequiredPriceBadge] = useState(0);
  const [requiredImageBadge, setRequiredImageBadge] = useState(0);
  const [childCalibreData, setChildCalibreData] = useState({});
  const [badgeContentErrors, setBadgeContentErrors] = useState();
  const [originalModelNum, setOriginalModelNum] = useState(watchform.model_no);
  const [openAccordion, setOpenAccordion] = useState({
    watch: false,
    price: false,
    image: false,
    case: false,
    dial: false,
    band: false,
    movement: false,
  });
  useEffect(() => {
    setBadgeContentErrors(
      requiredBrandBadge + requiredPriceBadge + requiredImageBadge,
    );
  }, [requiredBrandBadge, requiredImageBadge, requiredPriceBadge]);
  const steps = getSteps();
  function ColorlibStepIcon(props) {
    const { active, completed } = props;

    const icons = {
      1: (
        <Badge badgeContent={requiredBrandBadge} color="secondary">
          <Button
            onClick={() => {
              setActiveStep(0);
            }}
          >
            Step 1
          </Button>
        </Badge>
      ),
      2: (
        <Badge badgeContent={requiredPriceBadge} color="secondary">
          <Button
            onClick={() => {
              setActiveStep(1);
            }}
          >
            Step 2
          </Button>
        </Badge>
      ),
      3: (
        <Badge badgeContent={requiredImageBadge} color="secondary">
          <Button
            onClick={() => {
              setActiveStep(2);
            }}
          >
            Step 3{" "}
          </Button>
        </Badge>
      ),
      4: (
        <Button
          onClick={() => {
            setActiveStep(3);
          }}
        >
          Step 4
        </Button>
      ),
      5: (
        <Button
          onClick={() => {
            setActiveStep(4);
          }}
        >
          Step 5
        </Button>
      ),
      6: (
        <Button
          onClick={() => {
            setActiveStep(5);
          }}
        >
          Step 6
        </Button>
      ),
      7: (
        <Button
          //movement
          onClick={() => {
            setActiveStep(6);
          }}
        >
          Step 7
        </Button>
      ),
      8: (
        <Button
          //review
          onClick={() => {
            setActiveStep(7);
          }}
        >
          Review
        </Button>
      ),
    };

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {icons[String(props.icon)]}
      </div>
    );
  }

  ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node,
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const [open, setOpen] = useState(false);
  const [openDuplicate, setOpenDuplicate] = useState(false);

  const handleClickOpen = () => {
    setActiveStep(7);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickDuplicate = () => {
    setActiveStep(7);
    setOpenDuplicate(true);
  };
  const handleCloseDuplicate = () => {
    setOpenDuplicate(false);
  };

  //there are 2 or even 3 apis one is adding the new calibre to the database
  //one is adding images to the database on the submit not here
  //then after the props.submit form is adding the add watch to the database
  const submit = (e) => {
    if (e.currentTarget.id === "duplicate") {
      props.setDuplicate(true);
    } else props.setDuplicate(false);
    console.log("child calib", childCalibreData);
    if (childCalibreData.parent_calibre_id && true) {
      //replace true with a add new watch make sure your not reusing the edit watch from b4 that has a parent
      (async function () {
        const { data, error } = await authPut(
          `/calibre-details/${childCalibreData.calibre_id}`,
          {
            base_calibre: childCalibreData.base_calibre,
            brand_id: childCalibreData.brand_id,
            calibre: childCalibreData.calibre,
            certification_id: childCalibreData.certification_id,
            description: childCalibreData.description,
            in_house: childCalibreData.in_house,
            movement: childCalibreData.movement,
            parent_calibre_id: childCalibreData.parent_calibre_id,
            reserve_days: parseInt(childCalibreData.reserve_days),
            reserve_hours: parseInt(childCalibreData.reserve_hours),
            reserve_notes: childCalibreData.reserve_notes,
            vph: parseInt(childCalibreData.vph),
            components: parseInt(childCalibreData.components),
            jewels: parseInt(childCalibreData.jewels),
            complications: childCalibreData.complications.filter(
              (value) => value !== false,
            ),
            complication_details: childCalibreData.complication_details.filter(
              (value) => value.calibre_id !== false,
            ),
          },
        );
        if (error) {
          alert("ERROR: " + error.message);
        } else {
          console.log(data);
        }
      })();
    } else {
      console.log("NO PARENT");
    }

    props.submitForm();
  };

  return (
    <div className={classes.root}>
      <WatchHeader
        watchId={props.watchId}
        isEdit={props.isEdit}
        data={watchform}
        retired={props.retired}
        statusID={props.statusID}
      />
      <Stepper activeStep={activeStep} className={classes.padding}>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
          </Step>
        ))}
      </Stepper>
      <WatchModel
        classes={props.classes}
        setFieldValue={props.setFieldValue}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        openAccordion={openAccordion}
        setOpenAccordion={setOpenAccordion}
        requiredBrandBadge={requiredBrandBadge}
        setRequiredBrandBadge={setRequiredBrandBadge}
        watchId={props.watchId}
        isEdit={props.isEdit}
        setOriginalModelNum={setOriginalModelNum}
        originalModelNum={watchform.model_no}
      />
      <Pricing
        tpc_retail={props.tpc_retail}
        setTpc_retail={props.setTpc_retail}
        classes={props.classes}
        activeStep={activeStep}
        openAccordion={openAccordion}
        setOpenAccordion={setOpenAccordion}
        setRequiredPriceBadge={setRequiredPriceBadge}
        requiredPriceBadge={requiredPriceBadge}
        setStatus={props.setStatus}
        setRetired={props.setRetired}
        setActiveStep={setActiveStep}
        isEdit={props.isEdit}
      />
      <Images
        classes={props.classes}
        activeStep={activeStep}
        openAccordion={openAccordion}
        setOpenAccordion={setOpenAccordion}
        setRequiredImageBadge={setRequiredImageBadge}
        setActiveStep={setActiveStep}
        setMyPhotos={props.setMyPhotos}
        myPhotos={props.myPhotos}
      />
      <Case
        classes={props.classes}
        activeStep={activeStep}
        openAccordion={openAccordion}
        setOpenAccordion={setOpenAccordion}
        setActiveStep={setActiveStep}
      />
      <Dial
        classes={props.classes}
        activeStep={activeStep}
        openAccordion={openAccordion}
        setOpenAccordion={setOpenAccordion}
        setActiveStep={setActiveStep}
      />
      <BandAndBuckle
        classes={props.classes}
        activeStep={activeStep}
        openAccordion={openAccordion}
        setOpenAccordion={setOpenAccordion}
        setActiveStep={setActiveStep}
      />
      <Movement
        classes={props.classes}
        activeStep={activeStep}
        openAccordion={openAccordion}
        setOpenAccordion={setOpenAccordion}
        setChildCalibreData={setChildCalibreData}
        childCalibreData={childCalibreData}
        setActiveStep={setActiveStep}
      />
      <Paper
        style={{
          width: "90%",
          position: "fixed",
          bottom: 0,
          left: "5%",
          right: "5%",
          zIndex: 1500,
        }}
        elevation={13}
      >
        <BottomNavigation showLabels>
          {props.isEdit && siteId === 0 && (
            <BottomNavigationAction
              icon={
                <Badge badgeContent={badgeContentErrors} color="secondary">
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    startIcon={<FileCopyIcon />}
                    id={"duplicate"}
                    onClick={handleClickDuplicate}
                    disabled={badgeContentErrors}
                  >
                    Duplicate
                  </Button>
                </Badge>
              }
            />
          )}

          <BottomNavigationAction
            icon={
              <Badge badgeContent={badgeContentErrors} color="secondary">
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  startIcon={<AddIcon />}
                  onClick={handleClickOpen}
                  disabled={badgeContentErrors}
                >
                  {props.isEdit ? "Update" : "Add"}
                </Button>
              </Badge>
            }
            disabled={badgeContentErrors}
          />
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {badgeContentErrors
                ? "Uh-oh!"
                : props.isEdit
                ? "Confirm to Edit"
                : "Confirm to Add"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {badgeContentErrors
                  ? "Seems that not all required fields were filled in. Please review and fill in the required fields."
                  : props.isEdit
                  ? "Are you sure you want to save changes for this watch?"
                  : "Are you sure you want to Add this watch?"}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" variant="outlined">
                Cancel
              </Button>
              <Button
                variant="outlined"
                onClick={submit}
                color="primary"
                autoFocus
                disabled={badgeContentErrors}
              >
                {props.isEdit ? "Save" : "Add"}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openDuplicate}
            onClose={handleCloseDuplicate}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {badgeContentErrors || originalModelNum
                ? "Uh-oh!"
                : "Confirm to duplicate "}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {badgeContentErrors
                  ? "Seems that not all required fields were filled in. Please review and fill in the required fields."
                  : originalModelNum
                  ? "Model number can not be the Same"
                  : ""}
              </DialogContentText>
              <DialogContentText id="alert-dialog-description">
                {"Use current alt pics?"}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseDuplicate}
                color="primary"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                onClick={submit}
                id={"duplicate"}
                color="primary"
                autoFocus
                disabled={badgeContentErrors || originalModelNum}
              >
                Duplicate
              </Button>
            </DialogActions>
          </Dialog>
          {activeStep < 7 && (
            <BottomNavigationAction
              icon={
                <Badge color="secondary">
                  <Button
                    variant="outlined"
                    color="default"
                    className={classes.button}
                    endIcon={<ArrowForwardIcon />}
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                </Badge>
              }
            />
          )}
        </BottomNavigation>
      </Paper>
    </div>
  );
};
