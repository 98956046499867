import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Typography, InputAdornment, Box } from "@material-ui/core";
import { getGridNumericColumnOperators } from "@material-ui/data-grid/";
import { WatchActions } from "../../../../state";
import {
  moneyFormatter,
  GridButtons,
  getSiteSpecificPrice,
  getSiteSpecificRetailPrice,
  getSiteSpecificStatus,
  getSiteSpecificDateAdded,
  format_Word,
  statusColor,
} from "../../../../lib";
import { EditWatchDetails, GroupTabs } from ".";

export function WatchDetailsTabs(props) {
  const [rows, setRows] = useState([]);
  const [rows2, setRows2] = useState([]);
  const [rows3, setRows3] = useState([]);
  const [tabTwoName, setTabTwoName] = useState();
  const [tabThreeName, setTabThreeName] = useState();
  const [checked, setChecked] = useState();
  const siteId = useSelector((state) => state.scopes.site_id);

  const {
    pageRoute: {
      params: { attribute, id, detail, brand, brand_id },
    },
  } = props;

  useEffect(() => {
    (async function () {
      if (checked) {
        const data = await WatchActions.getDetails(attribute, id, siteId, true);
        setRows(data);

        if (attribute === "case_material") {
          const data2 = await WatchActions.getDetails(
            "button_material",
            id,
            siteId,
            true,
          );
          setRows2(data2);
          setTabTwoName("Button Material");
          const data3 = await WatchActions.getDetails(
            "crown_material",
            id,
            siteId,
            true,
          );
          setRows3(data3);
          setTabThreeName("Crown Material");
        } else if (attribute === "dial_color") {
          const data2 = await WatchActions.getDetails(
            "dial_flange_color",
            id,
            siteId,
            true,
          );
          setRows2(data2);
          setTabTwoName("Dial Flange Color");
        } else if (attribute === "hands_material") {
          const data2 = await WatchActions.getDetails(
            "second_hand_material",
            id,
            siteId,
            true,
          );
          setRows2(data2);
          setTabTwoName("Seconds Hand Material");
        }
      } else {
        const data = await WatchActions.getDetails(attribute, id, siteId);
        setRows(data);
        if (attribute === "case_material") {
          const data2 = await WatchActions.getDetails(
            "button_material",
            id,
            siteId,
          );
          setRows2(data2);
          setTabTwoName("Button Material");
          const data3 = await WatchActions.getDetails(
            "crown_material",
            id,
            siteId,
          );
          setRows3(data3);
          setTabThreeName("Crown Material");
        } else if (attribute === "dial_color") {
          const data2 = await WatchActions.getDetails(
            "dial_flange_color",
            id,
            siteId,
          );
          setRows2(data2);
          setTabTwoName("Dial Flange Color");
        } else if (attribute === "hands_material") {
          const data2 = await WatchActions.getDetails(
            "second_hand_material",
            id,
            siteId,
          );
          setRows2(data2);
          setTabTwoName("Seconds Hand Material");
        }
      }
    })();
  }, [checked, attribute, id, siteId]);

  const price = {
    type: "parseFloat",
    flex: 0.4,
    valueFormatter: ({ value }) => moneyFormatter(Number(value), siteId),
    headerAlign: "left",
    align: "left",
    extendType: "parseFloat",
    filterOperators: getGridNumericColumnOperators()
      .filter((operator) => operator.value === ">" || operator.value === "<")
      .map((operator) => {
        return {
          ...operator,
          InputComponentProps: {
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            },
          },
        };
      }),
  };

  const detailsColumns = [
    { field: "model", headerName: "Model", flex: 1 },
    { field: "title", headerName: "Title", flex: 2, sort: "asc" },
    { field: "price", headerName: "Price", ...price },
    { field: "retail", headerName: "Retail Price", ...price },
    {
      field: "status",
      headerName: "Status",
      flex: 0.4,
      renderCell: (params) => {
        return statusColor(params.value);
      },
      filterable: false,
    },
    { field: "dateAdded", headerName: "Date Added", type: "date", flex: 0.5 },
    {
      field: "buttons",
      headerName: "View",
      flex: 0.7,
      renderCell: (params) => {
        return GridButtons(params.value);
      },
      filterable: false,
    },
  ];

  let detailsRows = [];
  if (!attribute.endsWith("_group")) {
    detailsRows = rows?.map((row) => {
      return {
        id: row.item_id,
        model: row.model_no,
        title: row.title,
        price: getSiteSpecificPrice(row.price, siteId),
        retail: getSiteSpecificRetailPrice(row, siteId),
        status: getSiteSpecificStatus(row, siteId),
        dateAdded: getSiteSpecificDateAdded(row, siteId),
        buttons: row,
      };
    });
  }
  return (
    <Box
      style={{
        width: "100%",
      }}
    >
      <div>
        <div>
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Typography variant="h3">
                {format_Word(attribute) + " - " + detail + " (ID:" + id + ")"}
              </Typography>
            </div>
            <EditWatchDetails
              brandTitle={brand}
              attribute={attribute}
              id={id}
              detail={detail}
              rawAttribute={attribute}
              path={"watch-attribute-affiliated"}
            ></EditWatchDetails>
            <GroupTabs
              attribute={format_Word(attribute)}
              columns={detailsColumns}
              rows={detailsRows}
              rows2={rows2}
              rows3={rows3}
              site={siteId}
              tabTwoName={tabTwoName}
              tabThreeName={tabThreeName}
              checked={checked}
              setChecked={setChecked}
            ></GroupTabs>
          </div>
        </div>
      </div>
    </Box>
  );
}
