import React, { useState } from "react";
import { TextField, Box } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useSelector, shallowEqual } from "react-redux";
import { ConfirmButton } from ".";
import { WatchSelectors } from "../../../../state";

interface Props {
  attribute: string;
  path?: string;
  rawAttribute: string;
  brandAffiliated: true;
  isCaseShape: boolean;
}

export const AddWatchAttribute = (props: Props) => {
  const [myValue, setValue] = useState("");
  const [myBrand, setBrand] = useState("");
  const [myBrandId, setMyBrandId] = useState(Number);
  const [caseBackCategory, setCaseBackCategory] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [brandDisabled, setBrandDisabled] = useState(true);
  const [bgColor, setBgColor] = useState("");
  const siteId = useSelector((state: any) => state.scopes.site_id);

  const { brand: brandList } = useSelector(WatchSelectors.watches);

  const brandListTitle = brandList.map((brands: any) => {
    return { title: brands.brand, id: brands.brand_id };
  });
  const caseCategories = [
    { title: "Diameter" },
    { title: "Width" },
    { title: "Width & Height" },
  ];
  return (
    <div style={{ width: "100%" }}>
      {siteId === 0 && (
        <Box
          boxShadow={5}
          display="flex"
          justifyContent="center"
          my={4}
          p={2}
          pt={2}
          bgcolor={bgColor}
        >
          <Box p={1}>
            <TextField
              value={myValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                let myValue: string = e.target.value;
                setValue(myValue);
                setDisabled(false);
                setBgColor("background.paper");
              }}
              label={props.attribute}
            />
          </Box>
          <Box p={1}>
            {props.brandAffiliated && (
              <Autocomplete
                id="combo-box-demo"
                options={brandListTitle}
                getOptionLabel={(option: any) => option.title}
                onChange={(event, value) => {
                  if (value) {
                    setBrand(value.title);
                    setMyBrandId(value.id);
                    setBrandDisabled(false);
                  }
                }}
                style={{ width: 235 }}
                renderInput={(params) => (
                  <TextField {...params} label="Select a Brand Affiliation" />
                )}
              />
            )}
            {props.isCaseShape && (
              <Autocomplete
                id="case-shape-category"
                options={caseCategories}
                getOptionLabel={(option: any) => option.title}
                onChange={(event, value: any) => {
                  setCaseBackCategory(value?.title);
                }}
                style={{ width: 235 }}
                renderInput={(params) => (
                  <TextField {...params} label="Select a Category" />
                )}
              />
            )}
          </Box>
          {props.rawAttribute === "series" ||
          props.rawAttribute === "band_name" ||
          props.rawAttribute === "clasp_characteristic" ||
          props.rawAttribute === "clasp_name" ? (
            <Box p={1}>
              <ConfirmButton
                attribute={props.attribute}
                myValue={myValue}
                myBrand={myBrand}
                myBrandId={myBrandId}
                caseBackCategory={caseBackCategory}
                rawAttribute={props.rawAttribute}
                Add_Edit_Delete={"add"}
                color={"primary"}
                disabled={brandDisabled}
                path={props.path}
              ></ConfirmButton>
            </Box>
          ) : (
            <Box p={1}>
              <ConfirmButton
                attribute={props.attribute}
                myValue={myValue}
                myBrand={myBrand}
                myBrandId={myBrandId}
                caseBackCategory={caseBackCategory}
                rawAttribute={props.rawAttribute}
                Add_Edit_Delete={"add"}
                color={"primary"}
                disabled={disabled}
                path={props.path}
              ></ConfirmButton>
            </Box>
          )}
        </Box>
      )}
    </div>
  );
};
