import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  TextField as MUITextField,
  Box,
  Button,
  DialogTitle,
  Dialog,
  DialogActions,
  Badge,
  IconButton,
  DialogContent,
  FormControl,
} from "@material-ui/core";
import { authGet } from "../../../lib";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useDispatch } from "../../../state";
import { useSelector, shallowEqual } from "react-redux";
import { CustomAutoComplete } from "./CustomAutoComplete";
import { AddOnTheFlyAutoComplete } from "./AddOnTheFlyAutoComplete";
import { Field, useFormikContext, FastField } from "formik";
import { DatePicker } from "formik-material-ui-pickers";
import { TextField, CheckboxWithLabel, Switch } from "formik-material-ui";
import { green, red, blue } from "@material-ui/core/colors";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { withStyles } from "@material-ui/core/styles";
import ShareIcon from "@material-ui/icons/Share";

function calculatePercentOff(retail, percent) {
  return Math.floor(retail - retail * (percent / 100));
}

export const Pricing = (props) => {
  const siteId = useSelector((state) => state.scopes.site_id);
  const dispatch = useDispatch();
  const { classes } = props;
  const { values, setFieldValue } = useFormikContext();
  const [openShare, setOpenShare] = useState(false);
  const handleCloseShare = () => {
    setOpenShare(false);
  };
  const [openConfirm, setOpenConfirm] = useState(false);
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };
  const {
    delivery: delivery_list,
    ship_tier: ship_tier_list,
    vendor: vendor_list,
    warranty: warranty_list,
    status: status_list,
  } = useSelector((state) => state.watches.watchLists, shallowEqual);

  const [autoSelect, setAutoSelect] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [currentRetail, setCurrentRetail] = useState(
    numberWithCommas(values.price_retail),
  );
  const [runOnce, setRunOnce] = useState(true);

  const filterOptions = createFilterOptions({
    matchFrom: "start",
  });
  const [errorAlert, setErrorAlert] = useState(false);
  const [open, setOpen] = useState(false);

  const [percentOffField, setPercentOffField] = useState(Number);
  const handleNext = () => {
    props.setActiveStep(2);
  };
  const [percentOffSpecialField, setPercentOffSpecialField] = useState(Number);
  const GreenSwitch = withStyles({
    switchBase: {
      color: "#C8C8C8",
      "&$checked": {
        color: "#315179",
      },
      "&$checked + $track": {
        backgroundColor: "#C8C8C8",
      },
    },
    checked: {},
    track: {},
  })(Switch);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (percentOffField) {
      const po = calculatePercentOff(
        stringToNumber(values.price_retail),
        percentOffField,
      );
      setFieldValue("price_our", numberWithCommas(po));
    }
    if ((!percentOffField && values.price_our) || percentOffField === 0) {
      setPercentOffField(
        parseFloat(
          (
            (Math.floor(
              stringToNumber(values.price_retail) -
                stringToNumber(values.price_our),
            ) /
              stringToNumber(values.price_retail)) *
            100
          ).toFixed(0),
        ) || 0,
      );
    }
    if (percentOffSpecialField) {
      const po = calculatePercentOff(
        stringToNumber(values.price_retail),
        percentOffSpecialField,
      );
      setFieldValue("price_special", numberWithCommas(po));
    }
    if (
      (!percentOffSpecialField && values.price_special) ||
      percentOffSpecialField === 0
    ) {
      setPercentOffSpecialField(
        parseFloat(
          (
            (Math.floor(
              stringToNumber(values.price_retail) -
                stringToNumber(values.price_special),
            ) /
              stringToNumber(values.price_retail)) *
            100
          ).toFixed(0),
        ) || 0,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.price_retail]);
  useEffect(() => {
    if (!values.price_our) {
      setFieldValue("price_our", 0);
      setPercentOffField(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.price_our]);
  useEffect(() => {
    if (percentOffField === 100) {
      setPercentOffField(0);
    }
    if (percentOffSpecialField === 100) {
      setPercentOffSpecialField(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percentOffField, percentOffSpecialField]);

  useEffect(() => {
    if (values.show_recent) {
      let today = new Date();
      setFieldValue("date_added", today.toISOString());
    } else setFieldValue("date_added", null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.show_recent]);
  useEffect(() => {
    if (values.vendor_id) {
      let today = new Date();
      setFieldValue("vendor_exp", today.toISOString());
    } else setFieldValue("vendor_exp", null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.vendor_id]);
  useEffect(() => {
    if (values.show_reduced) {
      let today = new Date();
      setFieldValue("date_reduced", today.toISOString());
    } else setFieldValue("date_reduced", null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.show_reduced]);
  useEffect(() => {
    if (values.price_on_special) {
      let today = new Date();
      setFieldValue("vendor_exp", today.toISOString());
    } else {
      setFieldValue("vendor_exp", null);
      setFieldValue("price_special", "");
      setFieldValue("show_reduced", false);
      setFieldValue("date_reduced", null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.price_on_special]);

  useEffect(() => {
    props.setRetired(values.retired || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.retired]);
  useEffect(() => {
    if (!values.price_special) {
      setFieldValue("price_special", 0);
      setPercentOffSpecialField(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.price_special]);

  const handlePriceChange = (event) => {
    const po = stringToNumber(event.target.value);
    setPercentOffField(
      parseFloat(
        (
          (Math.floor(stringToNumber(values.price_retail) - po) /
            stringToNumber(values.price_retail)) *
          100
        ).toFixed(0),
      ) || 0,
    );
    setFieldValue("price_our", numberWithCommas(po));
  };
  const handlePriceSpecialChange = (event) => {
    const po = stringToNumber(event.target.value) || 0;
    setPercentOffSpecialField(
      parseFloat(
        (
          (Math.floor(stringToNumber(values.price_retail) - po) /
            stringToNumber(values.price_retail)) *
          100
        ).toFixed(0),
      ) || 0,
    );
    setFieldValue("price_special", numberWithCommas(po));
  };
  const handlePriceBlur = (event) => {
    const po = stringToNumber(event.target.value);
    if (po === 0) {
      setFieldValue("price_our", 0);
      setPercentOffField(0);
    }
    if (percentOffField >= 50 && percentOffField > 100) {
      setOpen(event.target.id);
    }
  };
  const handlePriceOffBlur = (event) => {
    const priceOff = parseInt(event.target.value);
    if (priceOff === 0) {
      setFieldValue("price_our", numberWithCommas(values.price_retail));
      setPercentOffField(0);
    }
    if (percentOffField >= 50 && percentOffField > 100) {
      setOpen(event.target.id);
    }
  };
  const handlePriceSpecialBlur = (event) => {
    const po = parseInt(event.target.value);
    if (po === 0) {
      setFieldValue("price_special", numberWithCommas(values.price_retail));
      setPercentOffSpecialField(0);
    }
    if (parseInt(percentOffSpecialField) >= 50) {
      setOpen(event.target.id);
    }
  };
  useEffect(() => {
    if (props.activeStep > 1 || props.requiredPriceBadge) {
      setErrorAlert(true);
      let required = 0;
      if (values.status_id === undefined || values.status_id === null) {
        required = required + 1;
      }
      if (
        values.price_on_special &&
        stringToNumber(values.price_special) >= stringToNumber(values.price_our)
      ) {
        required = required + 1;
      }
      props.setRequiredPriceBadge(required);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.activeStep,
    values.price_on_special,
    values.price_our,
    values.price_special,
    values.status_id,
  ]);
  useEffect(() => {
    props.setStatus(values.status_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.status_id]);
  const checkIfChangingFromPt = (e) => {
    (async () => {
      const ptShare = await authGet([`/site_share/${props.watchId}?site=0`]);
      if (currentRetail === ptShare.data.sharedFields.price_retail) {
        setOpenConfirm(true);
      }
    })();
    setRunOnce(false);
  };
  function numberWithCommas(string) {
    if (string === undefined || string === null) {
      return "";
    }
    let number =
      parseFloat(string.toString().replace(/,/g, "")) || parseInt(string);
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  function stringToNumber(string) {
    if (string === undefined || string === null) {
      return "";
    }
    let number =
      parseFloat(string.toString().replace(/,/g, "")) || parseInt(string);
    return number;
  }
  return (
    <Accordion
      expanded={
        props.activeStep === 1 ||
        props.activeStep === 7 ||
        props.openAccordion.price
      }
      style={{
        width: "100%",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        onClick={() => {
          props.setOpenAccordion(() => ({
            ...props.openAccordion,
            price: !props.openAccordion.price,
          }));
        }}
      >
        <Typography>
          <Badge badgeContent={props.requiredPriceBadge} color="secondary">
            Pricing & Status
          </Badge>
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        className={classes.grid}
        style={{
          display: "grid",
        }}
      >
        <Box
          display="flex"
          flexWrap="wrap"
          flexDirection="column"
          alignItems="left"
        >
          <Box display="flex" mr={1} justifyContent="space-between">
            <Box
              display="flex"
              justifyContent="space-between"
              style={{ width: "43%" }}
              alignItems="center"
            >
              {siteId === 0 && (
                <Box m={-1} style={{ width: "10%" }}>
                  <IconButton
                    aria-label="delete"
                    onClick={() => setOpenShare(true)}
                  >
                    <Badge
                      badgeContent={props.tpc_retail ? "TPC" : 0}
                      color="primary"
                    >
                      <ShareIcon />
                    </Badge>
                  </IconButton>
                  <Dialog
                    open={openShare}
                    onClose={handleCloseShare}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableBackdropClick
                    disableEscapeKeyDown
                  >
                    <DialogTitle id="alert-dialog-title">
                      Confirm to share retail with TPC
                    </DialogTitle>
                    <DialogContent id="alert-dialog-title">
                      <FormControl component="fieldset"></FormControl>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        variant="contained"
                        onClick={() => {
                          props.setTpc_retail(false);
                          handleCloseShare();
                        }}
                        color="secondary"
                      >
                        Don't share
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => {
                          props.setTpc_retail(true);
                          handleCloseShare();
                        }}
                        color="primary"
                      >
                        Share Retail
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Box>
              )}
              <Field
                className={
                  values.price_retail ? classes.gridTwoBlue : classes.gridTwo
                }
                component={TextField}
                name="price_retail"
                // type="number"
                onWheel={(e) => e.target.blur()}
                label="Retail"
                multiline
                style={{ width: "17%" }}
                onFocus={(event) => {
                  event.target.select();
                }}
                onChange={(e) => {
                  setFieldValue(
                    "price_retail",
                    numberWithCommas(e.target.value),
                  );
                  if (runOnce && siteId === 2) {
                    checkIfChangingFromPt(e.target.value);
                  }
                }}
              />
              <MUITextField
                className={
                  percentOffField >= 50
                    ? classes.gridAbove50
                    : percentOffField
                    ? classes.gridTwoBlue
                    : classes.gridTwo
                }
                onChange={(e) => {
                  setPercentOffField(e.target.value || 0);
                  if (values.price_retail && e.target.value) {
                    const po = calculatePercentOff(
                      stringToNumber(values.price_retail),
                      e.target.value,
                    );
                    setFieldValue("price_our", numberWithCommas(po));
                  }
                }}
                onBlur={handlePriceOffBlur}
                onWheel={(e) => e.target.blur()}
                label="% Off"
                id="price_our"
                name="our"
                value={percentOffField}
                style={{ width: "12%" }}
                multiline
                onFocus={(event) => {
                  event.target.select();
                }}
              />
              <Field
                className={
                  values.price_our ? classes.gridTwoBlue : classes.gridTwo
                }
                onFocus={(event) => {
                  event.target.select();
                }}
                onChange={handlePriceChange}
                onBlur={handlePriceBlur}
                component={TextField}
                name="price_our"
                id="price_our"
                type="number"
                onWheel={(e) => e.target.blur()}
                label="Price"
                style={{ width: "13%" }}
                multiline
              />

              <Box
                display="flex"
                justifyContent="space-between"
                style={{ width: "47%" }}
                mt={1}
              >
                <Box marginTop={-1} style={{ width: "16%" }}>
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    indeterminate={false}
                    name="price_on_special"
                    Label={{ label: "Special", labelPlacement: "start" }}
                    className={classes.gridTwo}
                    onFocus={(event) => {
                      event.target.select();
                    }}
                  />
                </Box>
                {values.price_on_special && (
                  <>
                    <MUITextField
                      className={
                        percentOffSpecialField >= 50
                          ? classes.gridAbove50
                          : percentOffField
                          ? classes.gridTwoBlue
                          : classes.gridTwo
                      }
                      onFocus={(event) => {
                        event.target.select();
                      }}
                      onChange={(e) => {
                        setPercentOffSpecialField(e.target.value || 0);
                        if (values.price_retail && e.target.value) {
                          const po = calculatePercentOff(
                            stringToNumber(values.price_retail),
                            e.target.value,
                          );
                          setFieldValue("price_special", numberWithCommas(po));
                        }
                      }}
                      onBlur={handlePriceSpecialBlur}
                      label="% Off Special"
                      id="price_special"
                      onWheel={(e) => e.target.blur()}
                      value={percentOffSpecialField}
                      style={{ width: "12%" }}
                      multiline
                    />

                    <Field
                      className={
                        stringToNumber(values.price_special) >=
                        stringToNumber(values.price_our)
                          ? classes.gridTwoRed
                          : values.price_special
                          ? classes.gridTwoBlue
                          : classes.gridTwo
                      }
                      onFocus={(event) => {
                        event.target.select();
                      }}
                      onChange={handlePriceSpecialChange}
                      onBlur={handlePriceSpecialBlur}
                      component={TextField}
                      name="price_special"
                      id="price_special"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      label="Special Price"
                      style={{ width: "34%" }}
                      multiline
                    />
                  </>
                )}
              </Box>
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              style={{ width: "18%" }}
              mt={1}
            >
              <Box display="flex" style={{ width: "9%" }} mt={-1}>
                <label>{"Show Recent"}</label>
                <Field
                  component={GreenSwitch}
                  type="checkbox"
                  indeterminate={false}
                  name="show_recent"
                />
              </Box>
              {values.show_recent && (
                <Field
                  onFocus={(event) => {
                    event.target.select();
                  }}
                  className={classes.gridTwoBlue}
                  component={DatePicker}
                  name="date_added"
                  label="Date Recent"
                  showTodayButton={true}
                  format="PP"
                  style={{ width: "50%" }}
                  multiline
                />
              )}
            </Box>
            <Box
              mt={1}
              display="flex"
              justifyContent="space-between"
              style={{ width: "20%" }}
            >
              <AddOnTheFlyAutoComplete
                classes={classes}
                setFieldValue={setFieldValue}
                formik={"vendor_id"}
                label={"Vendor"}
                width={"50%"}
                name={"vendor"}
                list={vendor_list}
              />
              {values.vendor_id && (
                <Field
                  className={classes.gridTwoBlue}
                  component={DatePicker}
                  name="vendor_exp"
                  label="Vendor Exp."
                  format="PP"
                  style={{ width: "49%" }}
                  multiline
                />
              )}
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              style={{ width: "18%" }}
              mt={1}
            >
              <Box display="flex" style={{ width: "9%" }} mt={-1}>
                <label>{"Show In Reduced"}</label>
                <Field
                  component={GreenSwitch}
                  type="checkbox"
                  indeterminate={false}
                  name="show_reduced"
                />
              </Box>
              {values.show_reduced && (
                <Field
                  onFocus={(event) => {
                    event.target.select();
                  }}
                  className={classes.gridTwoBlue}
                  component={DatePicker}
                  name="date_reduced"
                  label="Date Reduced"
                  showTodayButton={true}
                  format="PP"
                  style={{ width: "50%" }}
                  multiline
                />
              )}
            </Box>
          </Box>
          <Box mx={1} display="flex" justifyContent="space-between" mt={1}>
            <Autocomplete
              className={
                errorAlert
                  ? values.status_id === undefined || values.status_id === null
                    ? classes.gridTwoRed
                    : classes.gridTwoBlue
                  : values.status_id !== undefined || values.status_id !== null
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              autoSelect={autoSelect && inputValue.length > 0}
              autoHighlight={autoSelect && inputValue.length > 0}
              clearOnBlur={true}
              multiline
              options={status_list}
              getOptionLabel={(option) => option["status"] || ""}
              getOptionSelected={(option, value) =>
                option[`status_id`] === value[`status_id}`]
              }
              onChange={(e, value) => {
                if (value === null) {
                  setFieldValue("status_id", null);
                } else {
                  setFieldValue("status_id", value[`status_id`]);
                }
              }}
              onInputChange={(e, value) => {
                setAutoSelect(true);
                setInputValue(value);
                if (status_list?.find((x) => x["status"] === value)) {
                  setFieldValue(
                    "status_id",
                    status_list?.find((x) => x["status"] === value)[
                      `status_id`
                    ],
                  );
                } else setFieldValue("status_id", null);
              }}
              filterOptions={filterOptions}
              style={{ width: "13%" }}
              renderInput={(params) => {
                //has to be hardcoded bec status disabled value is 0 :\
                if (values.status_id === 0) {
                  params.inputProps.value =
                    status_list?.find((x) => x[`status_id`] === 0)?.[
                      "status"
                    ] || "";
                  return <MUITextField {...params} label={"Status"} />;
                } else if (values.status_id) {
                  params.inputProps.value =
                    status_list?.find(
                      (x) => x[`status_id`] === values.status_id,
                    )?.["status"] || "";
                  return <MUITextField {...params} label={"Status"} />;
                } else {
                  return (
                    <MUITextField
                      multiline
                      {...params}
                      label={"Status"}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  );
                }
              }}
            />
            <Box display="flex" mb={1} mt={-1}>
              <Field
                onFocus={(event) => {
                  event.target.select();
                }}
                disabled={siteId !== 0}
                className={classes.gridTwo}
                component={CheckboxWithLabel}
                type="checkbox"
                indeterminate={false}
                name="retired"
                Label={{
                  label: "Mark For Retirement",
                  labelPlacement: "start",
                }}
              />
            </Box>

            {siteId === 0 && (
              <>
                <AddOnTheFlyAutoComplete
                  classes={classes}
                  setFieldValue={setFieldValue}
                  formik={"warranty_id"}
                  label={"Warranty"}
                  width={"25%"}
                  name={"warranty"}
                  list={warranty_list}
                />
                <Box display="flex" mt={-2}>
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    indeterminate={false}
                    name="papers_included"
                    Label={{ label: "API", labelPlacement: "start" }}
                    className={classes.gridTwo}
                  />
                </Box>
              </>
            )}

            <CustomAutoComplete
              classes={classes}
              setFieldValue={setFieldValue}
              formik={"delivery_id"}
              label={"Delivery"}
              width={"23%"}
              name={"delivery"}
              list={delivery_list}
            />

            <CustomAutoComplete
              classes={classes}
              setFieldValue={setFieldValue}
              formik={"ship_tier_id"}
              label={"Shipping Tier"}
              width={"12%"}
              name={"ship_tier"}
              list={ship_tier_list}
            />
            <Button
              color="primary"
              onClick={handleNext}
              className={classes.button}
              endIcon={<ArrowDownwardIcon />}
            >
              Next
            </Button>
          </Box>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick
            disableEscapeKeyDown
          >
            <DialogTitle id="alert-dialog-title">
              The price entered is less than 50% of the retail price , if this
              is correct please select allow override otherwise please adjust
              the pricing.
            </DialogTitle>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => {
                  setFieldValue(open, 0);
                  handleClose();
                }}
                color="secondary"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  handleClose();
                }}
                color="primary"
              >
                Allow Override
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openConfirm}
            onClose={handleCloseConfirm}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick
            disableEscapeKeyDown
          >
            <DialogTitle id="alert-dialog-title">
              Warning: By changing this field It will be different then Prestige
              Times Retail Price
            </DialogTitle>
            <DialogContent id="alert-dialog-title">
              <FormControl component="fieldset"></FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => {
                  setFieldValue("price_retail", currentRetail);
                  setRunOnce(true);
                  handleCloseConfirm();
                }}
                color="secondary"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  handleCloseConfirm();
                }}
                color="primary"
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
