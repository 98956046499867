import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  TextField as MUITextField,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { useForceUpdate } from "../../../lib";
import RLDD from "react-list-drag-and-drop/lib/RLDD";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Dropzone from "react-dropzone";
import DeleteIcon from "@material-ui/icons/Delete";
import dragAndDrop from "./Images/dragAndDrop.png";
import deletePhoto from "./Images/deletePhoto.png";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { CheckboxWithLabel } from "formik-material-ui";
import { useFormikContext, FastField } from "formik";

export const Images = (props) => {
  let forceUpdate = useForceUpdate();
  const { values, setFieldValue } = useFormikContext();
  const imageChange = (image) => {
    props.setMyPhotos(image);
  };
  const handleNext = () => {
    props.setActiveStep(3);
  };
  const handleChange = (event) => {};
  const { classes } = props;
  let counter = 0;
  // console.log(props.myPhotos);
  // useEffect(() => {}, [props?.myPhotos]);
  return (
    <Accordion
      expanded={
        props.activeStep === 2 ||
        props.activeStep === 7 ||
        props.openAccordion.image
      }
      style={{
        width: "100%",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        onClick={() => {
          props.setOpenAccordion(() => ({
            ...props.openAccordion,
            image: !props.openAccordion.image,
          }));
        }}
      >
        <Typography>Images</Typography>
      </AccordionSummary>

      <AccordionDetails
        className={classes.grid}
        style={{
          display: "grid",
        }}
      >
        <Box
          display="flex"
          flexWrap="wrap"
          flexDirection="column"
          alignItems="left"
        >
          <Box display="flex">
            {props?.myPhotos.length < 7 ? (
              <Dropzone
                onDrop={(acceptedFiles) => {
                  props?.myPhotos.push({
                    image: acceptedFiles[0],
                    id: props?.myPhotos.length,
                    name: URL.createObjectURL(acceptedFiles[0]),
                    caption: undefined,
                  });
                  //   listingPhotos[0] = e.target.files[0];
                  forceUpdate();
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />

                      <div style={{ cursor: "pointer" }}>
                        <>
                          <img
                            src={dragAndDrop}
                            alt="dragAndDrop"
                            width="150"
                            height="305"
                          />
                        </>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            ) : (
              <div>
                <div>
                  <>
                    <img
                      src={deletePhoto}
                      alt="deletePhoto"
                      width="150"
                      height="305"
                    />
                  </>
                </div>
              </div>
            )}

            <RLDD
              items={props?.myPhotos}
              layout="horizontal"
              onChange={(image) => imageChange(image)}
              itemRenderer={(item) => {
                const { name, id, caption } = item;
                return (
                  <>
                    <DeleteIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        props?.myPhotos.splice(
                          props?.myPhotos.findIndex((index) => index.id === id),
                          1,
                        );
                        forceUpdate();
                      }}
                    />

                    <div>
                      <label htmlFor={"upload-button-0"}>
                        <div
                          style={{
                            cursor: "pointer",
                            margin: "2px",
                            border: "1px solid rgba(0, 0, 0, 0.3)",
                          }}
                        >
                          <>
                            <img
                              src={
                                name
                                  ? name
                                  : "https://objects.liquidweb.services/prest-dev/pic-unavailable.jpg"
                              }
                              alt="pic unavailable"
                              width="150"
                              height="100%"
                            />
                          </>
                        </div>
                      </label>
                    </div>
                  </>
                );
              }}
            />
          </Box>
          <Box display="flex" justifyContent="flex-start">
            <Box style={{ width: "150px", margin: "2px" }}>
              <FastField
                component={CheckboxWithLabel}
                type="checkbox"
                indeterminate={false}
                name="own_pic"
                Label={{ label: "Own Pic", labelPlacement: "start" }}
              />
            </Box>
            {props?.myPhotos.length >= 1 && (
              <MUITextField
                style={{ width: "150px", margin: "2px" }}
                id="standard-read-only-input"
                label="Main Photo"
                value="Main Photo"
                InputProps={{
                  readOnly: true,
                }}
              />
            )}

            {props?.myPhotos.length >= 2 && (
              <MUITextField
                style={{ width: "150px", margin: "2px" }}
                id="standard-basic"
                label="alt-photo-1"
                value={
                  props?.myPhotos[1]?.caption ? props?.myPhotos[1]?.caption : ""
                }
                onChange={(e) => {
                  props.myPhotos[1].caption = e.target.value;
                  forceUpdate();
                }}
              />
            )}

            {props?.myPhotos.length >= 3 && (
              <MUITextField
                style={{ width: "150px", margin: "2px" }}
                id="standard-basic"
                label="alt-photo-2"
                value={
                  props?.myPhotos[2]?.caption ? props?.myPhotos[2]?.caption : ""
                }
                onChange={(e) => {
                  props.myPhotos[2].caption = e.target.value;
                  forceUpdate();
                }}
              />
            )}

            {props?.myPhotos.length >= 4 && (
              <MUITextField
                style={{ width: "150px", margin: "2px" }}
                id="standard-basic"
                label="alt-photo-3"
                value={
                  props?.myPhotos[3]?.caption ? props?.myPhotos[3]?.caption : ""
                }
                onChange={(e) => {
                  props.myPhotos[3].caption = e.target.value;
                  forceUpdate();
                }}
              />
            )}

            {props?.myPhotos.length >= 5 && (
              <MUITextField
                style={{ width: "150px", margin: "2px" }}
                id="standard-basic"
                label="alt-photo-4"
                value={
                  props?.myPhotos[4]?.caption ? props?.myPhotos[4]?.caption : ""
                }
                onChange={(e) => {
                  props.myPhotos[4].caption = e.target.value;
                  forceUpdate();
                }}
              />
            )}

            {props?.myPhotos.length >= 6 && (
              <MUITextField
                style={{ width: "150px", margin: "2px" }}
                id="standard-basic"
                label="alt-photo-5"
                value={
                  props?.myPhotos[5]?.caption ? props?.myPhotos[5]?.caption : ""
                }
                onChange={(e) => {
                  props.myPhotos[5].caption = e.target.value;
                  forceUpdate();
                }}
              />
            )}
            {props?.myPhotos.length >= 7 && (
              <MUITextField
                style={{ width: "150px", margin: "2px" }}
                id="standard-basic"
                label="alt-photo-6"
                value={
                  props?.myPhotos[6]?.caption ? props?.myPhotos[6]?.caption : ""
                }
                onChange={(e) => {
                  props.myPhotos[6].caption = e.target.value;
                  forceUpdate();
                }}
              />
            )}
          </Box>

          <Button
            color="primary"
            onClick={handleNext}
            className={classes.button}
            endIcon={<ArrowDownwardIcon />}
          >
            Next
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
