import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Typography,
  InputAdornment,
  Box,
  FormControlLabel,
  Switch,
  TextField,
  Paper,
  Collapse,
} from "@material-ui/core";
import {
  DataGrid,
  GridToolbarContainer,
  GridFilterToolbarButton,
  getGridNumericColumnOperators,
} from "@material-ui/data-grid/";
import { WatchActions } from "../../../../state";
import {
  moneyFormatter,
  moneyFormatter2,
  GridButtons,
  getShipAddBox,
  getSiteSpecificPrice,
  getSiteSpecificRetailPrice,
  getSiteSpecificStatus,
  getSiteSpecificDateAdded,
  format_Word,
  statusColor,
} from "../../../../lib";
import { ConfirmButton } from ".";
import { withStyles } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";

export function ShippingDetails(props) {
  const [rows, setRows] = useState([]);
  const [shippingRows, setShippingRows] = useState([]);
  const [checked, setChecked] = useState();
  const [checked2, setChecked2] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const GreenSwitch = withStyles({
    switchBase: {
      color: "#C8C8C8",
      "&$checked": {
        color: "#315179",
      },
      "&$checked + $track": {
        backgroundColor: "#C8C8C8",
      },
    },
    checked: {},
    track: {},
  })(Switch);
  const siteId = useSelector((state) => state.scopes.site_id);

  const {
    pageRoute: {
      params: { attribute, id, detail },
    },
  } = props;
  const [shippingData, setShippingData] = useState({
    ship_tier_id: id,
    ship_tier: detail,
    data: [],
    can_delete: false,
  });
  useEffect(() => {
    (async function () {
      const shippingData = await WatchActions.getShippingDetails(id, siteId);
      setShippingRows(shippingData);
      if (checked) {
        const data = await WatchActions.getDetails(attribute, id, siteId, true);
        setRows(data);
      } else {
        const data = await WatchActions.getDetails(attribute, id, siteId);
        setRows(data);
      }
    })();
  }, [checked, attribute, id, siteId]);
  const [myValue, setMyValue] = useState(detail);
  const closeBox = () => setChecked2(false);
  const price = {
    type: "parseFloat",
    flex: 0.4,
    valueFormatter: ({ value }) => moneyFormatter(Number(value), siteId),
    headerAlign: "left",
    align: "left",
    extendType: "parseFloat",
    filterOperators: getGridNumericColumnOperators()
      .filter((operator) => operator.value === ">" || operator.value === "<")
      .map((operator) => {
        return {
          ...operator,
          InputComponentProps: {
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            },
          },
        };
      }),
  };
  const price2 = {
    type: "parseFloat",
    flex: 0.4,
    valueFormatter: ({ value }) => moneyFormatter2(Number(value), siteId),
    headerAlign: "left",
    align: "left",
    extendType: "parseFloat",
    filterOperators: getGridNumericColumnOperators()
      .filter((operator) => operator.value === ">" || operator.value === "<")
      .map((operator) => {
        return {
          ...operator,
          InputComponentProps: {
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            },
          },
        };
      }),
  };

  const detailsColumns = [
    { field: "model", headerName: "Model", flex: 1 },
    { field: "title", headerName: "Title", flex: 2, sort: "asc" },
    { field: "price", headerName: "Price", ...price },
    { field: "retail", headerName: "Retail Price", ...price },
    {
      field: "status",
      headerName: "Status",
      flex: 0.4,
      renderCell: (params) => {
        return statusColor(params.value);
      },
      filterable: false,
    },
    { field: "dateAdded", headerName: "Date Added", type: "date", flex: 0.5 },
    {
      field: "buttons",
      headerName: "View",
      flex: 0.7,
      renderCell: (params) => {
        return GridButtons(params.value);
      },
      filterable: false,
    },
  ];

  let detailsRows = [];
  detailsRows = rows?.map((row) => {
    return {
      id: row.item_id,
      model: row.model_no,
      title: row.title,
      price: getSiteSpecificPrice(row.price, siteId),
      retail: getSiteSpecificRetailPrice(row, siteId),
      status: getSiteSpecificStatus(row, siteId),
      dateAdded: getSiteSpecificDateAdded(row, siteId),
      buttons: row,
    };
  });

  const shipColumns = [
    { field: "description", headerName: "Description", flex: 1 },
    { field: "rate", headerName: "Rate", ...price, flex: 0.5 },
    { field: "price_low", headerName: "Price Low", ...price2, flex: 0.5 },
    { field: "price_high", headerName: "Price High", ...price2, flex: 0.5 },
    {
      field: "additional_amount",
      headerName: "Additional Amount",
      flex: 1,
      renderCell: (params) => {
        return getShipAddBox(params.value, shippingData, setShippingData);
      },
      filterable: false,
    },
  ];

  let shipRows = [];
  if (shippingRows.order_ship_rates) {
    shipRows = shippingRows.order_ship_rates.map((row) => {
      return {
        id: row.ship_rate_id,
        description: row.description,
        rate: row.rate,
        price_low: row.price_low,
        price_high: row.price_high,
        additional_amount: row,
      };
    });
  }

  const handleChange = () => {
    if (checked) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  };
  const handleChange2 = () => {
    if (checked2) {
      setChecked2(false);
    } else {
      setChecked2(true);
    }
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridFilterToolbarButton />
        <FormControlLabel
          control={
            <GreenSwitch
              checked={checked}
              onChange={handleChange}
              name="checked"
              color="primary"
            />
          }
          label={"All Watches"}
        />
      </GridToolbarContainer>
    );
  }
  return (
    <Box
      style={{
        width: "100%",
      }}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="h3">
          {format_Word(attribute) + " - " + detail + " (ID:" + id + ")"}
        </Typography>
      </div>
      {!checked2 && (
        <Box
          boxShadow={5}
          display="flex"
          justifyContent="center"
          mt={4}
          mb={2}
          p={2}
          pt={2}
        >
          <TextField
            multiline={true}
            value={myValue}
            onChange={(e) => {
              setMyValue(e.target.value);
              shippingData.ship_tier = e.target.value;
              setIsDisabled(false);
            }}
            label={"Ship Tier"}
          />
          <ConfirmButton
            attribute={"Ship Tier"}
            rawAttribute={"ship_tier"}
            myValue={myValue}
            oldValue={detail}
            id={id}
            Add_Edit_Delete={"edit"}
            color={"primary"}
            path={"manage-shipping-tiers"}
            shipData={shippingData}
            disabled={isDisabled}
          />

          {shippingRows.can_delete && (
            <ConfirmButton
              attribute={"Ship Tier"}
              myValue={myValue}
              id={id}
              rawAttribute={"ship_tier"}
              Add_Edit_Delete={"delete"}
              color={"secondary"}
              disabled={false}
              path={"manage-shipping-tiers"}
              shipData={shippingData}
            />
          )}
        </Box>
      )}

      <div>
        <FormControlLabel
          control={<GreenSwitch checked={checked2} onChange={handleChange2} />}
          label="Show Shipping Rates"
        />

        <Collapse in={checked2}>
          <Paper elevation={4}>
            <Box display="flex" justifyContent="center" p={2} pt={2}>
              <TextField
                multiline={true}
                value={myValue}
                onChange={(e) => {
                  setMyValue(e.target.value);
                  shippingData.ship_tier = e.target.value;
                }}
                label={"Ship Tier"}
              />
            </Box>
            <Box
              boxShadow={5}
              display="flex"
              justifyContent="center"
              mb={1}
              p={2}
              pt={2}
              // bgcolor={bgColor}
            >
              <DataGrid
                rows={shipRows}
                columns={shipColumns}
                disableColumnMenu={true}
                isRowSelectable={false}
                autoHeight={true}
                pageSize={100}
                checkboxSelection={false}
                rowHeight={50}
                hideFooter={true}
              />
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              my={1}
              p={2}
              pt={1}
              onClick={closeBox}
            >
              <Box display="flex" justifyContent="center">
                <ConfirmButton
                  checked2={checked2}
                  attribute={"Ship Tier"}
                  rawAttribute={"ship_tier"}
                  myValue={myValue}
                  oldValue={detail}
                  id={id}
                  Add_Edit_Delete={"edit"}
                  color={"primary"}
                  path={"manage-shipping-tiers"}
                  shipData={shippingData}
                />
              </Box>
            </Box>
          </Paper>
        </Collapse>
      </div>

      <Box
        boxShadow={5}
        display="flex"
        justifyContent="center"
        mt={2}
        mb={2}
        p={2}
        pt={2}
      >
        <DataGrid
          rows={detailsRows}
          columns={detailsColumns}
          disableColumnMenu={true}
          isRowSelectable={false}
          autoHeight={true}
          pageSize={100}
          checkboxSelection={false}
          rowHeight={38}
          components={{
            Toolbar: CustomToolbar,
          }}
          sortModel={[
            {
              field: "title",
              sort: "asc",
            },
          ]}
        />
      </Box>
    </Box>
  );
}
