import React from 'react';
import { Link } from 'react-router-dom';
import { Button, IconButton } from '@material-ui/core';

/**
 * A material-ui `Button` wrapped with react-router-dom `Link`
 * @param {import("@material-ui/core/Button").ButtonProps & import("react-router-dom").LinkProps} props
 * @returns Button
 */
export function ButtonLink(props) {
  const { children, ...rest } = props;
  return (
    <Button component={Link} {...rest}>
      {children}
    </Button>
  );
}

/**
 *  A material-ui `IconButton` wrapped with react-router-dom `Link`
 * @param {import("@material-ui/core/IconButton").IconButtonProps & import("react-router-dom").LinkProps} props
 * @returns IconButton
 */
export function IconButtonLink(props) {
  const { children, ...rest } = props;
  return (
    <IconButton component={Link} {...rest}>
      {children}
    </IconButton>
  );
}