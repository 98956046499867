import React, { useState, useEffect } from "react";
import { useSelector as useSelect, shallowEqual } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Button,
  Toolbar,
  Avatar,
  MenuItem,
  TextField,
  Menu,
  Typography,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { AuthSelectors, useSelector } from "../state";
import { ManageWatch } from "./headerCategories";
import { Navigation, timeoutAsync } from "../lib";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { ScopeActions } from "../state/scopes/actions";
import { Link } from "react-router-dom";
import { WatchFormActions, useDispatch } from "../state";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 5,
    display: "flex",
    justifyContent: "space-between",
  },
  right: { display: "flex", justifyContent: "flex-end" },
}));

export function Header() {
  const [refresh, setRefresh] = useState(false);

  const { scopes_allowed, selected_scope } = useSelect(
    (state) => state.scopes,
    shallowEqual,
  );
  console.log("selected_scope", selected_scope);
  const updateScope = (i) => {
    dispatch(ScopeActions.updateScope(i));
    setTimeout(() => setRefresh(true), 1);
  };
  const reload = () => window.location.reload(true);
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [logout, setLogout] = useState(false);
  const avatarInfo = useSelector(AuthSelectors.avatarInfo);
  const siteId = useSelector((state) => state.scopes.site_id);
  const classes = useStyles();
  function onLogoutClick(item) {
    if (!window.confirm("Are you sure you want to log out?")) {
      return;
    }
    Navigation.go("/auth/logout");
    return item.onClick;
  }
  function avatarClick() {
    setLogout(!logout);
  }
  //Just some temporary code to allow them to get to the ad watch page, should be built out properly
  //right now it throws an error, but it works
  function onMenuItemClick(link) {
    handleClose();
    Navigation.go(link);
    return;
  }
  useEffect(() => {
    if (!selected_scope) {
      dispatch(ScopeActions.getDefaultScope());
    }
  }, [selected_scope]);

  useEffect(() => {
    if (refresh) {
      reload();
    }
  }, [refresh]);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          {siteId === 0 && (
            <Button
              color="inherit"
              onClick={() => {
                dispatch(WatchFormActions.clearForm());
                onMenuItemClick(`/add-watch`);
              }}
            >
              Add Watch
            </Button>
          )}
          <ManageWatch />
          <Button
            onClick={handleClick}
            color="inherit"
            endIcon={<ArrowDropDownIcon />}
          >
            Manage Site
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            onClick={handleClose}
          >
            <MenuItem>
              <Link to="/manage-site-attributes/warranty">Warranty</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/manage-site-attributes/vendor">
                Vendors & Specials
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to="/manage-shipping-tiers/ship_tier">Shipping Tier</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/manage-site-attributes/delivery">
                Shipping Time Frame
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to="/records">Records</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/manage-users">Users</Link>
            </MenuItem>

            <MenuItem
              onMouseOver={(e) => (e.target.style.color = "#3f51b5")}
              onMouseOut={(e) => (e.target.style.color = "#000000")}
              onClick={() =>
                onMenuItemClick("/manage-site-attributes/delivery")
              }
            >
              <Typography>Shipping time frame</Typography>
            </MenuItem>
          </Menu>
          <Button
            color="inherit"
            onClick={() => onMenuItemClick("/watches-list")}
          >
            Watch Grid
          </Button>
          <Box className={classes.title}></Box>
          <TextField select value={selected_scope}>
            className={classes.right}
            {scopes_allowed.map((i) => (
              <MenuItem
                onClick={(e) => {
                  updateScope(i);
                }}
                key={i.role}
                value={i.role}
              >
                <Typography style={{ color: "#ffffff" }}> {i.text}</Typography>
              </MenuItem>
            ))}
          </TextField>
          <Avatar
            onClick={avatarClick}
            style={{
              cursor: "pointer",
              color: avatarInfo.textColor,
              backgroundColor: avatarInfo.bgColor,
            }}
          >
            {avatarInfo.text}
          </Avatar>
          {logout && (
            <Button color="inherit" onClick={onLogoutClick}>
              Logout
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
