import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import {
  Box,
  LinearProgress,
  Button,
  TextField as TxtField,
} from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { ConfirmButton } from ".";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { WatchActions, useDispatch } from "../../../../state";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

interface Props {
  brand_id: number;
  aka_rule: any;
  brand: any;
  stub: any;
  sites_router_metum: any;
  custom_aka: boolean;
  can_delete: boolean;
  myTitle: any;
  id: any;
  myBrand: any;
  brandListTitle: any;
  setMyBrand: any;
  setMyBrandId: any;
  setEditBrand: any;
  myBrandId: any;
  setGroupDetailsData: any;
  brandTitle: any;
  attribute: any;
  rawAttribute: any;
  setIsDisabled: any;
  isDisabled: boolean;
}

export function SeriesSeo(props: Props) {
  const dispatch = useDispatch();
  const [bgColor, setBgColor] = useState();
  const siteId = useSelector((state: any) => state.scopes.site_id);

  return (
    <div style={{ width: "100%" }}>
      <Formik
        initialValues={{
          ...props,
        }}
        onSubmit={(values) => {
          dispatch(
            WatchActions.editSeries(
              `/details-value/${props.id}?field=${props.attribute}&site=${siteId}`,
              values,
              props.myBrandId,
            ),
          );
        }}
      >
        {({ submitForm, isSubmitting, values }) => (
          <Form>
            {true && (
              <Box
                boxShadow={5}
                display="flex"
                flexWrap="wrap"
                flexDirection="column"
                alignItems="left"
                my={4}
                p={2}
                pt={2}
                bgcolor={bgColor}
                onLoad={props.setGroupDetailsData(values)}
                onKeyUp={(e: any) => {
                  props.setGroupDetailsData(values);
                  props.setIsDisabled(false);
                }}
              >
                <Box m={1} display="flex" justifyContent="space-between">
                  {props.attribute === "series" ? (
                    <Field
                      component={TextField}
                      name="series"
                      label="Series name"
                      style={{ width: "46%" }}
                      disabled={siteId !== 0}
                    />
                  ) : props.attribute === "series_group" ? (
                    <Field
                      component={TextField}
                      name="series_group"
                      label="Series Group"
                      style={{ width: "46%" }}
                      disabled={siteId !== 0}
                    />
                  ) : (
                    <Field
                      component={TextField}
                      name={props.attribute}
                      label="Group Name"
                      style={{ width: "50%" }}
                      disabled={siteId !== 0}
                    />
                  )}
                  {props?.hasOwnProperty(`brand_id`) && (
                    <Autocomplete
                      disabled={siteId !== 0}
                      inputValue={props.myBrand}
                      defaultValue={props.myTitle}
                      id="combo-box-demo"
                      options={props.brandListTitle}
                      getOptionLabel={(option) => option.title || ""}
                      onChange={(event, value) => {
                        if (value) {
                          props.setMyBrand(value?.title);
                          props.setMyBrandId(value?.id);
                          props.setIsDisabled(false);
                          if (props.setEditBrand) {
                            props.setEditBrand(value?.id);
                          }
                        }
                      }}
                      style={{ width: "53%" }}
                      renderInput={(params) => (
                        <TxtField
                          {...params}
                          label="Brand Affiliation"
                          defaultValue={props.brandTitle}
                        />
                      )}
                    />
                  )}
                </Box>
                <Box>
                  <Box m={1} display="flex" justifyContent="space-between">
                    {props.attribute.startsWith("series") && (
                      <Field
                        component={TextField}
                        name="stub"
                        label="Series Items URL"
                        style={{ width: "46%" }}
                      />
                    )}
                    {values.stub && (
                      <Field
                        component={TextField}
                        name="sites_router_metum.title"
                        label="Brand Items Title"
                        style={{ width: "53%" }}
                      />
                    )}
                  </Box>
                  <Box m={1}>
                    {values.stub && (
                      <Field
                        multiline={true}
                        fullWidth={true}
                        component={TextField}
                        name="sites_router_metum.description"
                        label="Series Items Meta Description"
                      />
                    )}
                  </Box>

                  <Box m={1}>
                    {values.stub && (
                      <Field
                        multiline={true}
                        fullWidth={true}
                        component={TextField}
                        name="sites_router_metum.keywords"
                        label="Series Items Meta Keywords"
                      />
                    )}
                  </Box>
                  <Box m={1}>
                    {values.stub && (
                      <Field
                        multiline={true}
                        fullWidth={true}
                        component={TextField}
                        name="sites_router_metum.headers"
                        label="Series Items Other SEO"
                      />
                    )}
                  </Box>
                </Box>

                <Box m={1} display="flex" justifyContent="space-between">
                  {isSubmitting && <LinearProgress />}
                  {!props.attribute?.endsWith("_group") && (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={props.isDisabled}
                      onClick={submitForm}
                      component={Link}
                      to={`/watch-attribute-affiliated/series`}
                    >
                      Edit Series
                    </Button>
                  )}

                  {props.can_delete && (
                    <ConfirmButton
                      attribute={props.attribute}
                      myValue={values.brand}
                      myBrand={values.brand}
                      myBrandId={values.brand_id}
                      id={props.id}
                      rawAttribute={props.rawAttribute}
                      Add_Edit_Delete={"delete"}
                      color={"secondary"}
                      disabled={false}
                      path={
                        props.attribute.startsWith("series")
                          ? "watch-attribute-affiliated"
                          : "watch-attribute-groups"
                      }
                    />
                  )}
                </Box>
              </Box>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}
