import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Box,
  TextField as MUITextField,
  Button,
  Checkbox,
  FormControlLabel,
  Badge,
} from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useForceUpdate } from "../../../lib";
import { useSelector, shallowEqual } from "react-redux";
import { useFormikContext, Field } from "formik";
import { TextField, CheckboxWithLabel } from "formik-material-ui";
import { CustomAutoComplete } from "./CustomAutoComplete";
import { WatchActions, WatchSelectors } from "../../../state";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

interface Props {
  classes: any;
  setFieldValue: any;
  activeStep: number;
  setActiveStep: any;
  requiredBrandBadge: any;
  setRequiredBrandBadge: any;
  setOpenAccordion: any;
  openAccordion: any;
  watchId: number;
  isEdit: boolean;
  setOriginalModelNum: any;
  originalModelNum: any;
}
/**
 *
 * @param {*} props
 * @returns
 */
export const WatchModel = (props: Props) => {
  let forceUpdate = useForceUpdate();
  const { values: _values } = useFormikContext();
  const siteId = useSelector((state: any) => state.scopes.site_id);
  const values: any = _values;
  const {
    classes,
    setFieldValue,
    setActiveStep,
    requiredBrandBadge,
    setRequiredBrandBadge,
    isEdit,
    watchId,
    openAccordion,
    setOpenAccordion,
    setOriginalModelNum,
    originalModelNum,
  } = props;
  const {
    brand: brand_list,
    size: size_list,
    series: series_list,
  } = useSelector(WatchSelectors.watches);
  const [regex, setRegex] = useState(
    '{"flags":"g","source":"[\\\\/\\\\\\\\\\\\-\\\\._\\\\s]"}',
  );
  const [prefix, setPrefix] = useState("");
  const [suffix, setSuffix] = useState("");
  const handleNext = () => {
    setActiveStep(1);
  };
  useEffect(() => {
    if (values.brand_id) {
      (async function () {
        const data = await WatchActions.getRegex(values.brand_id);
        setRegex(data?.aka_regex);
        setPrefix(data?.prefix || "");
        setSuffix(data?.suffix || "");
        if (values?.model_no) {
          setFieldValue(
            "model_aka",
            (data?.prefix || "") +
              values.model_no?.replace(
                new RegExp(data?.aka_regex.source, data?.aka_regex.flags),
                "",
              ) +
              (data?.suffix || "") || "",
          );
        }
      })();

      forceUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.brand_id]);
  useEffect(() => {
    if (!values.edition_id) {
      setFieldValue("limited_quantity", null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.edition_id]);
  useEffect(() => {
    if (!values.limited_edition) {
      setFieldValue("numbered_edition", false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.limited_edition]);
  useEffect(() => {
    const obj = JSON.parse(regex);
    if (values?.model_no) {
      setFieldValue(
        "model_aka",
        prefix +
          values?.model_no?.replace(new RegExp(obj.source, obj.flags), "") ||
          "",
        +suffix,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.brand_id, values.model_no]);
  useEffect(() => {
    if (!values.item_id || values.item_id > 26273 || values.seo_stub) {
      var seo_stub = `${
        brand_list?.find((x: any) => x?.brand_id === values?.brand_id)?.brand ||
        ""
      } ${
        series_list?.find((x: any) => x?.series_id === values?.series_id)
          ?.series || ""
      } ${values.model_no}`;
      seo_stub += values.model_no_2 ? ` ${values.model_no_2}` : "";

      if (/^.*( [0-9][0-9]\.[0-9]mm).*$/.test(seo_stub)) {
        var match = /^.*( [0-9][0-9]\.[0-9]mm).*$/.exec(seo_stub);
        var pos = match ? seo_stub.indexOf(match[1]) : seo_stub.length;
        seo_stub = seo_stub.substring(0, pos) + seo_stub.substring(pos + 7);
      }
      setFieldValue("seo_stub", seo_stub.replace(/\W+/g, "-"));

      setFieldValue(
        //awaiting for the details how twc & tpc want their title and stub for now we are using prestige as  a default
        "title",
        `${values.model_no || ""}${
          values.model_no_2 ? " " + values.model_no_2 : ""
        } ${
          brand_list?.find((x: any) => x?.brand_id === values?.brand_id)
            ?.brand || ""
        } ${
          series_list?.find((x: any) => x?.series_id === values?.series_id)
            ?.series || ""
        } ${
          size_list?.find((x: any) => x?.size_id === values?.size_id)?.size ||
          ""
        } Watch`.trim(),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.model_no,
    values.model_no_2,
    values.brand_id,
    values.series_id,
    values.size_id,
  ]);
  const [autoSelect, setAutoSelect] = useState(false);
  const [brandAutoSelect, setBrandAutoSelect] = useState(false);
  const [deleteSeries, setDeleteSeries] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [showSeo, setShowSeo] = useState(false);
  const filterOptions = createFilterOptions({
    matchFrom: "start",
  });
  useEffect(() => {
    if (deleteSeries) {
      setFieldValue("series_id", null);
      setDeleteSeries(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSeries]);
  useEffect(() => {
    if (props.activeStep > 0 || requiredBrandBadge) {
      setErrorAlert(true);
      let required = 0;
      if (!values.brand_id) {
        required = required + 1;
      }
      if (!values.series_id) {
        required = required + 1;
      }
      if (!values.title && !values.default_title) {
        required = required + 1;
      }
      if (!values.seo_stub) {
        required = required + 1;
      }
      if (!values.size_id) {
        required = required + 1;
      }
      if (!values.model_no) {
        required = required + 1;
      }
      setRequiredBrandBadge(required);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.activeStep,
    values.brand_id,
    values.model_no,
    values.seo_stub,
    values.series_id,
    values.size_id,
    values.title,
  ]);

  useEffect(() => {
    if (values.model_no !== originalModelNum) {
      setOriginalModelNum(false);
    } else setOriginalModelNum(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.model_no]);
  const handleChange = (event: any) => {
    setShowSeo(!showSeo);
  };
  return (
    <Accordion
      expanded={
        props.activeStep === 0 ||
        props.activeStep === 7 ||
        props.openAccordion.watch
      }
      style={{
        width: "100%",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        onClick={() => {
          setOpenAccordion(() => ({
            ...openAccordion,
            watch: !openAccordion.watch,
          }));
        }}
      >
        <Typography>
          <Badge badgeContent={props.requiredBrandBadge} color="secondary">
            Brand & Model
          </Badge>
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        className={classes.grid}
        style={{
          display: "grid",
        }}
      >
        <Box
          display="flex"
          flexWrap="wrap"
          flexDirection="column"
          alignItems="left"
        >
          <Box my={0.5} mx={1} display="flex" justifyContent="space-between">
            <Autocomplete
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : values.brand_id
                  ? classes.gridTwoBlue
                  : errorAlert
                  ? classes.gridTwoRed
                  : classes.gridTwo
              }
              disabled={siteId !== 0}
              autoSelect={brandAutoSelect}
              autoHighlight={brandAutoSelect}
              clearOnBlur={true}
              options={brand_list}
              getOptionLabel={(option: any) => option.brand || ""}
              onChange={(e, value: any) => {
                if (value === null) {
                  setFieldValue("brand_id", null);
                } else {
                  setFieldValue("brand_id", value.brand_id);
                }
              }}
              onInputChange={(e, value) => {
                setDeleteSeries(true);
                if (value.length > 0) {
                  setBrandAutoSelect(true);
                } else setBrandAutoSelect(false);
                if (brand_list?.find((x: any) => x.brand === value)) {
                  setFieldValue(
                    "brand_id",
                    brand_list?.find((x: any) => x.brand === value).brand_id,
                  );
                } else setFieldValue("brand_id", null);
              }}
              filterOptions={filterOptions}
              style={{ width: "19%" }}
              renderInput={(params: any) => {
                if (values.brand_id) {
                  params.inputProps.value =
                    brand_list?.find((x: any) => x.brand_id === values.brand_id)
                      ?.brand || "";
                  return <MUITextField {...params} label={"Brand"} multiline />;
                } else {
                  return (
                    <MUITextField
                      {...params}
                      label={"Brand"}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  );
                }
              }}
            />
            <Autocomplete
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : values.series_id
                  ? classes.gridTwoBlue
                  : errorAlert
                  ? classes.gridTwoRed
                  : classes.gridTwo
              }
              disabled={siteId !== 0}
              autoSelect={autoSelect}
              autoHighlight={autoSelect}
              clearOnBlur={true}
              options={series_list
                .filter((series: any) => series.brand_id === values.brand_id)
                .map((i: any) => ({
                  series_id: i.series_id,
                  series: i.series,
                }))}
              getOptionLabel={(option: any) => option.series || ""}
              getOptionSelected={(option: any) => option.select}
              onChange={(e, value: any) => {
                if (value === null) {
                  setFieldValue("series_id", null);
                } else {
                  setFieldValue("series_id", value.series_id);
                }
              }}
              onInputChange={(e, value) => {
                if (value.length > 0) {
                  setAutoSelect(true);
                } else setAutoSelect(false);
                if (
                  series_list
                    .filter(
                      (series: any) => series.brand_id === values.brand_id,
                    )
                    .map((i: any) => ({
                      series_id: i.series_id,
                      series: i.series,
                    }))
                    ?.find((x: any) => x.series === value)
                ) {
                  setFieldValue(
                    "series_id",
                    series_list
                      .filter(
                        (series: any) => series.brand_id === values.brand_id,
                      )
                      .map((i: any) => ({
                        series_id: i.series_id,
                        series: i.series,
                      }))
                      ?.find((x: any) => x.series === value).series_id,
                  );
                } else setFieldValue("series_id", null);
              }}
              filterOptions={filterOptions}
              style={{ width: "28%" }}
              renderInput={(params: any) => {
                if (values.series_id) {
                  params.inputProps.value =
                    series_list
                      .filter(
                        (series: any) => series.brand_id === values.brand_id,
                      )
                      .map((i: any) => ({
                        series_id: i.series_id,
                        series: i.series,
                      }))
                      ?.find((x: any) => x.series_id === values.series_id)
                      ?.series || "";
                  return (
                    <MUITextField {...params} label={"Series"} multiline />
                  );
                } else {
                  return (
                    <MUITextField {...params} label={"Series"} multiline />
                  );
                }
              }}
            />
            <CustomAutoComplete
              classes={classes}
              setFieldValue={setFieldValue}
              label={"Size"}
              name={"size"}
              formik={"size_id"}
              list={size_list}
              width={"9%"}
              errorAlert={errorAlert}
              disabled={siteId !== 0}
            />
            <Field
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : values.model_no
                  ? classes.gridTwoBlue
                  : errorAlert
                  ? classes.gridTwoRed
                  : classes.gridTwo
              }
              onFocus={(event: any) => {
                event.target.select();
              }}
              disabled={siteId !== 0}
              component={TextField}
              name="model_no"
              type="text"
              label="Strict Model Number"
              style={{ width: "23%" }}
              multiline
            />
            <Field
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : values.model_no_2
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              onFocus={(event: any) => {
                event.target.select();
              }}
              disabled={siteId !== 0}
              component={TextField}
              name="model_no_2"
              type="text"
              label="Add On Model Number"
              style={{ width: "19%" }}
              multiline
            />
          </Box>
          <Box display="flex" mt={1} mx={1} justifyContent="space-between">
            <Box
              display="flex"
              justifyContent="space-between"
              style={{ width: "37%" }}
            >
              <Field
                className={
                  siteId !== 0
                    ? classes.gridTwoBlack
                    : values.model_aka
                    ? classes.gridTwoBlue
                    : classes.gridTwo
                }
                onFocus={(event: any) => {
                  event.target.select();
                }}
                disabled={siteId !== 0}
                component={TextField}
                name="model_aka"
                value={
                  values.model_aka === null || values.model_aka === undefined
                    ? ""
                    : values.model_aka
                }
                type="text"
                label="AKA"
                style={{ width: "65%" }}
                multiline
              />
              <Field
                className={
                  siteId !== 0
                    ? classes.gridTwoBlack
                    : values.gtin
                    ? classes.gridTwoBlue
                    : classes.gridTwo
                }
                onFocus={(event: any) => {
                  event.target.select();
                }}
                component={TextField}
                name="gtin"
                type="text"
                label="GTIN"
                style={{ width: "34%" }}
                disabled={siteId !== 0}
                multiline
              />
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              style={{ width: "64%" }}
            >
              <FormControlLabel
                control={<Checkbox onChange={handleChange} name="checkedA" />}
                label="SEO"
                labelPlacement="start"
                className={classes.gridTwo}
              />
              {showSeo && (
                <>
                  <Field
                    className={
                      values.title || values.default_title
                        ? classes.gridTwoBlue
                        : errorAlert
                        ? classes.gridTwoRed
                        : classes.gridTwo
                    }
                    onFocus={(event: any) => {
                      event.target.select();
                    }}
                    component={TextField}
                    name={values.title ? "title" : "default_title"}
                    label="Title"
                    style={{ width: "62%" }}
                    multiline
                  />
                  <Field
                    className={
                      values.seo_stub
                        ? classes.gridTwoBlue
                        : errorAlert
                        ? classes.gridTwoRed
                        : classes.gridTwo
                    }
                    onFocus={(event: any) => {
                      event.target.select();
                    }}
                    component={TextField}
                    name="seo_stub"
                    label="Stub"
                    style={{ width: "29%" }}
                    multiline
                  />
                </>
              )}
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" mt={1}>
            <Box
              display="flex"
              justifyContent="space-between"
              style={{ width: "15%" }}
            >
              <Field
                disabled={siteId !== 0}
                component={CheckboxWithLabel}
                type="checkbox"
                indeterminate={false}
                name="special_edition"
                Label={{ label: "Special", labelPlacement: "start" }}
                className={classes.gridTwo}
              />
              <Field
                disabled={siteId !== 0}
                component={CheckboxWithLabel}
                type="checkbox"
                indeterminate={false}
                name="limited_edition"
                Label={{ label: "Limited", labelPlacement: "start" }}
                className={classes.gridTwo}
              />
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                indeterminate={false}
                name="numbered_edition"
                disabled={siteId !== 0}
                Label={{
                  label: "Number",
                  labelPlacement: "start",
                }}
                multiline
                className={classes.gridTwo}
              />
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              style={{ width: "67%" }}
              mt={1}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                style={{ width: "50%" }}
              >
                {values.special_edition ? (
                  <Field
                    className={
                      siteId !== 0
                        ? classes.gridTwoBlack
                        : values.edition_notes
                        ? classes.gridTwoBlue
                        : classes.gridTwo
                    }
                    onFocus={(event: any) => {
                      event.target.select();
                    }}
                    disabled={siteId !== 0}
                    component={TextField}
                    name="edition_notes"
                    type="text"
                    label="Edition Notes"
                    multiline
                    style={{
                      width: "79%",
                    }}
                  />
                ) : values.limited_edition ? (
                  <Field
                    className={
                      siteId !== 0
                        ? classes.gridTwoBlack
                        : values.edition_notes
                        ? classes.gridTwoBlue
                        : classes.gridTwo
                    }
                    onFocus={(event: any) => {
                      event.target.select();
                    }}
                    disabled={siteId !== 0}
                    component={TextField}
                    name="edition_notes"
                    type="text"
                    label="Edition Notes"
                    multiline
                    style={{
                      width: "79%",
                    }}
                  />
                ) : (
                  <></>
                )}
                {values.limited_edition && (
                  <>
                    <Field
                      className={
                        siteId !== 0
                          ? classes.gridTwoBlack
                          : values.limited_quantity
                          ? classes.gridTwoBlue
                          : classes.gridTwo
                      }
                      onFocus={(event: any) => {
                        event.target.select();
                      }}
                      disabled={siteId !== 0}
                      component={TextField}
                      name="limited_quantity"
                      type="number"
                      label="Quantity"
                      style={{ width: "20%" }}
                      multiline
                    />
                  </>
                )}
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                style={{ width: "50%" }}
              ></Box>
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              style={{ width: "7%" }}
            >
              <Button
                color="primary"
                onClick={handleNext}
                className={classes.button}
                endIcon={<ArrowDownwardIcon />}
              >
                Next
              </Button>
            </Box>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
