import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  IconButton,
  Button,
  DialogContent,
  Dialog,
  CardContent,
  Card,
} from "@material-ui/core";
import { useStyles } from "../AddWatchPage.styles";
import Alert from "@material-ui/lab/Alert";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { green, red, blue, purple, brown } from "@material-ui/core/colors";
import { format } from "date-fns";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import { Field, useFormikContext, FastField } from "formik";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

export const WatchHeader = (props) => {
  const classes = useStyles();
  const defaultProps = {
    bgcolor: "background.paper",
    m: 1,
    style: { width: "110%", height: "110%" },
    borderColor: "text.primary",
  };
  const [openStats, setOpenStats] = useState(false);
  const handleClickOpenStats = () => () => {
    setOpenStats(true);
  };
  const handleCloseStats = () => {
    setOpenStats(false);
  };
  const statusColor = [
    { status_id: 1, status: "Active", color: green[500] },
    { status_id: 3, status: "Backordered", color: purple[500] },
    { status_id: 5, status: "Coming Soon", color: brown[500] },
    { status_id: 0, status: "Disabled", color: red[700] },
    { status_id: 2, status: "Discontinued", color: blue[500] },
    { status_id: 4, status: "Hidden", color: "" },
  ];
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      flexDirection="column"
      alignItems="left"
    >
      <Box display="flex" justifyContent="space-between" mb={-3}>
        {props.isEdit ? (
          <>
            <Typography variant="h6">
              Watch: {props.data.default_title}
            </Typography>

            <IconButton
              m={-1}
              disabled={true}
              classes={{ label: classes.iconButton }}
            >
              <FiberManualRecordIcon
                style={{
                  color: statusColor.find(
                    (x) => x.status_id === props.data.status_id,
                  )?.color,
                }}
              />
              <Typography
                style={{
                  color: statusColor.find(
                    (x) => x.status_id === props.data.status_id,
                  )?.color,
                }}
              >
                {
                  statusColor.find((x) => x.status_id === props.data.status_id)
                    ?.status
                }
              </Typography>
            </IconButton>
          </>
        ) : (
          <></>
        )}
      </Box>
      <Box m={-1} mb={-1} display="flex" justifyContent="space-between">
        {props.isEdit && (
          <>
            <Box display="flex">
              <Button
                color={"primary"}
                endIcon={<EqualizerIcon />}
                onClick={handleClickOpenStats(true)}
              >
                Statistics
              </Button>
              <Dialog
                open={openStats}
                onClose={handleCloseStats}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              >
                <DialogContent>
                  <div>
                    <Typography variant="h6">Statistics</Typography>
                    <Box display="flex" justifyContent="space-between">
                      <Box m={1}>
                        <Card>
                          <CardContent>
                            <Typography color="textSecondary" gutterBottom>
                              Page Views
                            </Typography>
                            <Typography variant="h5" component="h2">
                              {props.data.item_stat?.viewed || 0}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Box>
                      <Box m={1}>
                        <Card>
                          <CardContent>
                            <Typography color="textSecondary" gutterBottom>
                              Added to Cart
                            </Typography>
                            <Typography variant="h5" component="h2">
                              {props.data.item_stat?.added_to_cart || 0}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Box>
                      <Box m={1}>
                        <Card>
                          <CardContent>
                            <Typography color="textSecondary" gutterBottom>
                              Conversion
                            </Typography>
                            <Typography variant="h5" component="h2">
                              {(100 * props.data.item_stat?.added_to_cart) /
                              props.data.item_stat?.viewed
                                ? (
                                    (100 *
                                      props.data.item_stat?.added_to_cart) /
                                    props.data.item_stat?.viewed
                                  ).toFixed(2)
                                : 0}
                              %
                            </Typography>
                          </CardContent>
                        </Card>
                      </Box>
                    </Box>
                  </div>
                </DialogContent>
              </Dialog>
            </Box>
          </>
        )}
        {props.isEdit && (
          <>
            <Typography variant="h3"></Typography>
            <Box mt={1} mb={1} ml={1}>
              <Box>
                <Button
                  color={"primary"}
                  endIcon={<OpenInNewIcon />}
                  onClick={() =>
                    window.open(`/history/${props.watchId}`, "_blank")
                  }
                >
                  History
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Box>
      <Box mb={1} ml={1} display="flex" justifyContent="space-between">
        {props.isEdit && props.data.act_date_added && (
          <>
            Added on{" "}
            {format(new Date(props.data.act_date_added), "MMM-do yyyy")}{" "}
            {props.data.user
              ? `by: ${props.data.user.first_name} 
          ${props.data.user.last_name}`
              : " "}
            {props.retired && (
              <Box m={-1} display="flex" justifyContent="flex-end">
                <Alert severity="warning">Watch is marked for retirement</Alert>
              </Box>
            )}
            <Button
              color={"primary"}
              endIcon={<OpenInNewIcon />}
              onClick={() =>
                window.open(
                  `https://prestigetime.bitbean.dev/${props.data.seo_stub}.html`,
                  "_blank",
                )
              }
            >
              Website
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};
