import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Box,
  Button,
  Tooltip,
  TextField as MUITextField,
  Badge,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import { useForceUpdate } from "../../../lib";
import { CustomAutoComplete } from "./CustomAutoComplete";
import { AddOnTheFlyAutoComplete } from "./AddOnTheFlyAutoComplete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSelector, shallowEqual } from "react-redux";
import { useFormikContext, Field } from "formik";
import { TextField } from "formik-material-ui";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
export const BandAndBuckle = (props) => {
  let forceUpdate = useForceUpdate();
  const siteId = useSelector((state) => state.scopes.site_id);
  const { classes } = props;
  const { values, setFieldValue } = useFormikContext();
  const [showAdditionalDetails, setShowAdditionalDetails] = useState(
    values.description,
  );
  const handleNext = () => {
    props.setActiveStep(6);
  };
  const {
    band_material: band_material_list,
    band_color: band_color_list,
    band_name: band_name_list,
    clasp_name: clasp_name_list,
    clasp_type: clasp_type_list,
    clasp_characteristic: clasp_characteristic_list,
  } = useSelector((state) => state.watches.watchLists, shallowEqual);
  const [autoSelect, setAutoSelect] = useState(false);
  const filterOptions = createFilterOptions({
    matchFrom: "start",
  });
  return (
    <Accordion
      expanded={
        props.activeStep === 5 ||
        props.activeStep === 7 ||
        props.openAccordion.band
      }
      style={{
        width: "100%",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        onClick={() => {
          props.setOpenAccordion(() => ({
            ...props.openAccordion,
            band: !props.openAccordion.band,
          }));
        }}
      >
        <Typography>
          <Badge badgeContent={props.requiredBandBadge} color="secondary">
            Band & Buckle + Additional Details
          </Badge>
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        className={classes.grid}
        style={{
          display: "grid",
        }}
      >
        <Box
          display="flex"
          flexWrap="wrap"
          flexDirection="column"
          alignItems="left"
        >
          <Box m={1} display="flex" justifyContent="space-between">
            <Autocomplete
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : values.band_name_id
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              autoSelect={autoSelect}
              autoHighlight={autoSelect}
              clearOnBlur={true}
              options={band_name_list
                ?.filter((band_name) => band_name.brand_id === values.brand_id)
                .map((i) => ({
                  band_name_id: i.band_name_id,
                  band_name: i.band_name,
                }))}
              getOptionLabel={(option) => option.band_name || ""}
              onChange={(e, value) => {
                if (value === null) {
                  setFieldValue(" band_name_id", null);
                } else {
                  setFieldValue(" band_name_id", value.band_name_id);
                }
              }}
              onInputChange={(e, value) => {
                if (value.length > 0) {
                  setAutoSelect(true);
                } else setAutoSelect(false);
                if (
                  band_name_list
                    ?.filter(
                      (band_name) => band_name.brand_id === values.brand_id,
                    )
                    .map((i) => ({
                      band_name_id: i.band_name_id,
                      band_name: i.band_name,
                    }))
                    ?.find((x) => x.band_name === value)
                ) {
                  setFieldValue(
                    " band_name_id",
                    band_name_list
                      ?.filter(
                        (band_name) => band_name.brand_id === values.brand_id,
                      )
                      .map((i) => ({
                        band_name_id: i.band_name_id,
                        band_name: i.band_name,
                      }))
                      ?.find((x) => x.band_name === value).band_name_id,
                  );
                } else setFieldValue(" band_name_id", null);
              }}
              filterOptions={filterOptions}
              style={{ width: "15%" }}
              renderInput={(params) => {
                if (values.band_name_id) {
                  params.inputProps.value =
                    band_name_list
                      ?.filter(
                        (band_name) => band_name.brand_id === values.brand_id,
                      )
                      .map((i) => ({
                        band_name_id: i.band_name_id,
                        band_name: i.band_name,
                      }))
                      ?.find((x) => x.band_name_id === values.band_name_id)
                      ?.band_name || "";
                  return <MUITextField {...params} label={"Band Name"} />;
                } else {
                  return <MUITextField {...params} label={"Band Name"} />;
                }
              }}
            />
            <AddOnTheFlyAutoComplete
              classes={classes}
              disabled={siteId !== 0}
              setFieldValue={setFieldValue}
              label={"Band Material"}
              name={"band_material"}
              formik={"band_material_id"}
              list={band_material_list}
              width={"20%"}
            />

            <AddOnTheFlyAutoComplete
              classes={classes}
              disabled={siteId !== 0}
              setFieldValue={setFieldValue}
              label={"Band Color"}
              name={"band_color"}
              formik={"band_color_id"}
              list={band_color_list}
              width={"15%"}
            />
            <Field
              disabled={siteId !== 0}
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : values.band_notes
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              component={TextField}
              onFocus={(event) => {
                event.target.select();
              }}
              name="band_notes"
              type="text"
              label="Band Notes"
              style={{ width: "48%" }}
              multiline
            />
          </Box>
          <Box m={1} display="flex" justifyContent="space-between">
            <Autocomplete
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : values.clasp_name_id
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              autoSelect={autoSelect}
              autoHighlight={autoSelect}
              clearOnBlur={true}
              options={clasp_name_list
                ?.filter(
                  (clasp_name) => clasp_name.brand_id === values.brand_id,
                )
                .map((i) => ({
                  clasp_name_id: i.clasp_name_id,
                  clasp_name: i.clasp_name,
                }))}
              getOptionLabel={(option) => option.clasp_name || ""}
              onChange={(e, value) => {
                if (value === null) {
                  setFieldValue("clasp_name_id", null);
                } else {
                  setFieldValue("clasp_name_id", value.clasp_name_id);
                }
              }}
              onInputChange={(e, value) => {
                if (value.length > 0) {
                  setAutoSelect(true);
                } else setAutoSelect(false);
                if (
                  clasp_name_list
                    ?.filter(
                      (clasp_name) => clasp_name.brand_id === values.brand_id,
                    )
                    .map((i) => ({
                      clasp_name_id: i.clasp_name_id,
                      clasp_name: i.clasp_name,
                    }))
                    ?.find((x) => x.clasp_name === value)
                ) {
                  setFieldValue(
                    " clasp_name_id",
                    clasp_name_list
                      ?.filter(
                        (clasp_name) => clasp_name.brand_id === values.brand_id,
                      )
                      .map((i) => ({
                        clasp_name_id: i.clasp_name_id,
                        clasp_name: i.clasp_name,
                      }))
                      ?.find((x) => x.clasp_name === value).clasp_name_id,
                  );
                } else setFieldValue(" clasp_name_id", null);
              }}
              filterOptions={filterOptions}
              style={{ width: "16.5%" }}
              renderInput={(params) => {
                if (values.clasp_name_id) {
                  params.inputProps.value =
                    clasp_name_list
                      ?.filter(
                        (clasp_name) => clasp_name.brand_id === values.brand_id,
                      )
                      .map((i) => ({
                        clasp_name_id: i.clasp_name_id,
                        clasp_name: i.clasp_name,
                      }))
                      ?.find((x) => x.clasp_name_id === values.clasp_name_id)
                      ?.clasp_name || "";
                  return <MUITextField {...params} label={"Buckle Name"} />;
                } else {
                  return <MUITextField {...params} label={"Buckle Name"} />;
                }
              }}
            />
            <CustomAutoComplete
              classes={classes}
              disabled={siteId !== 0}
              setFieldValue={setFieldValue}
              label={"Buckle Type"}
              name={"clasp_type"}
              formik={"clasp_type_id"}
              list={clasp_type_list}
              width={"16.5%"}
            />

            <Autocomplete
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : values.clasp_characteristic_id
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              autoSelect={autoSelect}
              autoHighlight={autoSelect}
              clearOnBlur={true}
              options={clasp_characteristic_list
                ?.filter(
                  (clasp_characteristic) =>
                    clasp_characteristic.brand_id === values.brand_id,
                )
                .map((i) => ({
                  clasp_characteristic_id: i.clasp_characteristic_id,
                  clasp_characteristic: i.clasp_characteristic,
                }))}
              getOptionLabel={(option) => option.clasp_characteristic || ""}
              onChange={(e, value) => {
                if (value === null) {
                  setFieldValue("clasp_characteristic_id", null);
                } else {
                  setFieldValue(
                    "clasp_characteristic_id",
                    value.clasp_characteristic_id,
                  );
                }
              }}
              onInputChange={(e, value) => {
                if (value.length > 0) {
                  setAutoSelect(true);
                } else setAutoSelect(false);
                if (
                  clasp_characteristic_list
                    ?.filter(
                      (clasp_characteristic) =>
                        clasp_characteristic.brand_id === values.brand_id,
                    )
                    .map((i) => ({
                      clasp_characteristic_id: i.clasp_characteristic_id,
                      clasp_characteristic: i.clasp_characteristic,
                    }))
                    ?.find((x) => x.clasp_characteristic === value)
                ) {
                  setFieldValue(
                    " clasp_characteristic_id",
                    clasp_characteristic_list
                      ?.filter(
                        (clasp_characteristic) =>
                          clasp_characteristic.brand_id === values.brand_id,
                      )
                      .map((i) => ({
                        clasp_characteristic_id: i.clasp_characteristic_id,
                        clasp_characteristic: i.clasp_characteristic,
                      }))
                      ?.find((x) => x.clasp_characteristic === value)
                      .clasp_characteristic_id,
                  );
                } else setFieldValue("clasp_characteristic_id", null);
              }}
              filterOptions={filterOptions}
              style={{ width: "16.5%" }}
              renderInput={(params) => {
                if (values.clasp_characteristic_id) {
                  params.inputProps.value =
                    clasp_characteristic_list
                      ?.filter(
                        (clasp_characteristic) =>
                          clasp_characteristic.brand_id === values.brand_id,
                      )
                      .map((i) => ({
                        clasp_characteristic_id: i.clasp_characteristic_id,
                        clasp_characteristic: i.clasp_characteristic,
                      }))
                      ?.find(
                        (x) =>
                          x.clasp_characteristic_id ===
                          values.clasp_characteristic_id,
                      )?.clasp_characteristic || "";
                  return <MUITextField {...params} label={"Buckle Char."} />;
                } else {
                  return <MUITextField {...params} label={"Buckle Char."} />;
                }
              }}
            />
            <Field
              disabled={siteId !== 0}
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : values.clasp_notes
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              component={TextField}
              onFocus={(event) => {
                event.target.select();
              }}
              name="clasp_notes"
              type="text"
              label="Buckle Notes"
              style={{ width: "48%" }}
              multiline
            />
          </Box>
          {values.item_additional_band_refs?.map((value, index) => {
            return (
              <Box mx={1} display="flex" justifyContent="space-between">
                <Box style={{ width: "12.5%" }}>
                  <Typography>Additional Band # {index + 1}</Typography>
                </Box>
                <Autocomplete
                  className={
                    siteId !== 0
                      ? classes.gridTwoBlack
                      : values.item_additional_band_refs[index].band_material_id
                      ? classes.gridTwoBlue
                      : classes.gridTwo
                  }
                  autoSelect
                  autoHighlight
                  options={band_material_list}
                  getOptionLabel={(option) => option.band_material || ""}
                  onChange={(e, value) => {
                    values.item_additional_band_refs[index].band_material_id =
                      value.band_material_id;
                    forceUpdate();
                  }}
                  style={{ width: "24.5%" }}
                  renderInput={(params) => {
                    params.inputProps.value =
                      band_material_list?.find(
                        (x) =>
                          x.band_material_id ===
                          values.item_additional_band_refs[index]
                            .band_material_id,
                      )?.band_material || null;
                    return (
                      <MUITextField
                        {...params}
                        label={"Additional Band Material #" + (index + 1)}
                      />
                    );
                  }}
                />
                <Autocomplete
                  className={
                    siteId !== 0
                      ? classes.gridTwoBlack
                      : values.item_additional_band_refs[index].band_color_id
                      ? classes.gridTwoBlue
                      : classes.gridTwo
                  }
                  autoSelect
                  autoHighlight
                  options={band_color_list}
                  getOptionLabel={(option) => option.band_color || ""}
                  onChange={(e, value) => {
                    values.item_additional_band_refs[index].band_color_id =
                      value.band_color_id;
                    forceUpdate();
                  }}
                  style={{ width: "24.5%" }}
                  renderInput={(params) => {
                    params.inputProps.value =
                      band_color_list?.find(
                        (x) =>
                          x.band_color_id ===
                          values.item_additional_band_refs[index].band_color_id,
                      )?.band_color || "";
                    return (
                      <MUITextField
                        {...params}
                        label={"Additional Band Color #" + (index + 1)}
                      />
                    );
                  }}
                />
                <MUITextField
                  className={
                    siteId !== 0
                      ? classes.gridTwoBlack
                      : values.item_additional_band_refs[index].band_notes
                      ? classes.gridTwoBlue
                      : classes.gridTwo
                  }
                  value={values.item_additional_band_refs[index].band_notes}
                  onChange={(e) => {
                    values.item_additional_band_refs[index].band_notes =
                      e.target.value;
                    forceUpdate();
                  }}
                  onFocus={(event) => {
                    event.target.select();
                  }}
                  label={"Additional Band Notes #" + (index + 1)}
                  style={{ width: "32%" }}
                  multiline
                />
                <Box>
                  <Tooltip title="Delete Row">
                    <Button
                      mb={-1}
                      disabled={siteId !== 0}
                      onClick={(event) => {
                        values.item_additional_band_refs.splice(index, 1);
                        forceUpdate();
                      }}
                    >
                      <RemoveCircleOutlineOutlinedIcon color="secondary" />
                    </Button>
                  </Tooltip>
                </Box>
              </Box>
            );
          })}

          <Box mx={1} display="flex" justifyContent="space-between">
            <FormControlLabel
              checked={showAdditionalDetails}
              control={
                <Checkbox
                  onChange={(e, value) => {
                    if (value) {
                      setShowAdditionalDetails(true);
                    } else {
                      setShowAdditionalDetails(false);
                      setFieldValue("description", null);
                    }
                  }}
                  labelPlacement="start"
                  name="checkedA"
                />
              }
              label="Show Additional Details"
            />
            <Button
              disabled={siteId !== 0}
              color="primary"
              variant="outlined"
              onClick={(event) => {
                values.item_additional_band_refs.push({
                  additional_band: true,
                  band_material_id: null,
                  band_color_id: null,
                  band_notes: null,
                });
                forceUpdate();
              }}
            >
              Add Additional band
            </Button>
            <Button
              color="primary"
              onClick={handleNext}
              className={classes.button}
              endIcon={<ArrowDownwardIcon />}
            >
              Next
            </Button>
          </Box>
          {showAdditionalDetails && (
            <Box m={1}>
              <ReactQuill
                value={values.description}
                onChange={(value) => {
                  if (value) {
                    setFieldValue("description", value);
                  } else {
                    setFieldValue("description", null);
                  }
                }}
              />
            </Box>
          )}
          <Box m={1} display="flex" justifyContent="space-between"></Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
