import React from "react";
import {
  Typography,
  Menu,
  Button,
  MenuItem,
  Grid,
  Box,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Link } from "react-router-dom";

export function ManageWatch() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit"
        endIcon={<ArrowDropDownIcon />}
      >
        Manage Watch
      </Button>

      <Menu
        PaperProps={{
          style: {
            width: "100%",
            left: 0,
            right: 0,
            margin: 0,
            padding: 0,
            maxWidth: "100%",
          },
        }}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClick={handleClose}
      >
        <Box m={3} pt={3}>
          <Grid container direction="row">
            <Grid item>
              <Grid container direction="column">
                <Typography align="left" variant="h6" display="initial">
                  MODEL
                </Typography>
                <MenuItem>
                  <Link to="/watch-attribute-affiliated/brand">Brands</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/watch-attribute-affiliated/series">Series</Link>
                </MenuItem>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="column">
                <Typography align="left" variant="h6" display="initial">
                  BAND & BUCKLE
                </Typography>

                <MenuItem>
                  <Link to="/watch-attribute-affiliated/band_color">
                    Band Color
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/watch-attribute-affiliated/band_material">
                    Band Material
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/watch-attribute-affiliated/band_name">
                    Band Name
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/watch-attribute-affiliated/clasp_name">
                    Buckle Name
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/watch-attributes/clasp_type">Buckle type</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/watch-attribute-affiliated/clasp_characteristic">
                    Buckle Characteristic
                  </Link>
                </MenuItem>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="column">
                <Typography align="left" variant="h6" display="initial">
                  CASE
                </Typography>
                <MenuItem>
                  <Link to="/watch-attributes/bezel">Bezel Type</Link>
                </MenuItem>

                <MenuItem>
                  <Link to="/watch-attributes/bezel_characteristics">
                    Bezel Characteristics
                  </Link>
                </MenuItem>

                <MenuItem>
                  <Link to="/watch-attributes/case_back">
                    Case Back Material
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/watch-attribute-affiliated/case_material">
                    Material Extended 
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/watch-attributes/case_finish">
                    Finish Extended
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/watch-attribute-affiliated/case_shape">
                    Case Shape
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/watch-attributes/crystal">Crystal Material</Link>
                </MenuItem>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="column">
                <Typography align="left" variant="h6" display="initial">
                  DIAL
                </Typography>
                <MenuItem>
                  <Link to="/watch-attribute-affiliated/dial_color">
                    Dial, Dial Flange Color
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/watch-attributes/flange_indication">
                    Flange Indication
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/watch-attributes/dial_finish">
                    Main Dial Finish
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/watch-attributes/subdial_color">
                    SubDial Color
                  </Link>
                </MenuItem>

                <MenuItem>
                  <Link to="/watch-attributes/hands_finish">Hands Finish</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/watch-attribute-affiliated/hands_material">
                    Hands Material
                  </Link>
                </MenuItem>

                <MenuItem>
                  <Link to="/watch-attributes/hour_marker">
                    Hour Markers Characteristics
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/watch-attributes/hour_marker_color">
                    Hour Markers Color
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/watch-attributes/hour_marker_type">
                    Hour Markers Type
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/watch-attributes/second_hand">
                    Second Hand Type
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/watch-attributes/track_type">Track Type</Link>
                </MenuItem>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container direction="column">
                <Typography align="left" variant="h6" display="initial">
                  GROUPS
                </Typography>

                <MenuItem>
                  <Link to="/watch-attribute-groups/band_color_group">
                    Band Color
                  </Link>
                </MenuItem>

                <MenuItem>
                  <Link to="/watch-attribute-groups/band_material_group">
                    Band Material
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/watch-attribute-groups/bezel_material_group">
                    Bezel Material
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/watch-attribute-groups/case_shape_group">
                    Case Shape
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/watch-attribute-groups/case_material_group">
                    Material Extended 
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/watch-attribute-groups/dial_color_group">
                    Dial, Dial Flange Color
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/watch-attribute-affiliated/series_group">
                    Series Groups
                  </Link>
                </MenuItem>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="column">
                <Typography align="left" variant="h6" display="initial">
                  MOVEMENT
                </Typography>
                <MenuItem>
                  <Link to="/movement-attribute/calibre">Movement</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/watch-attributes/calibre_complication">
                    Complications
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/watch-attribute-affiliated/certification">
                    Certifications
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/watch-attributes/movement">Movement Type</Link>
                </MenuItem>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Menu>
    </div>
  );
}
