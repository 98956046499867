export const WatchSelectors = {
  gridConfig(state) {
    return state.watches.gridConfig;
  },
  gridData(state) {
    return state.watches.gridData;
  },
  watches(state) {
    return state.watches.watchLists;
  },
  // material_list(state) {
  //   return (
  //     state.watches.material?.map((i) => ({
  //       case_material_id: i.case_material_id || null,
  //       case_material: i.case_material,
  //     })) ?? []
  //   );
  // },
};
