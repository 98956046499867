import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";
// Local
import {
  AppState,
  useDispatch,
  WatchActions,
  WatchFormActions,
  WatchFormSelectors,
} from "../../state";
import { Formik, Form } from "formik";
import { AddWatchStepper, WatchHeader } from "./Components";
import { ScopeActions } from "../../state/scopes/actions";
import { useStyles } from "./AddWatchPage.styles";
import { authGet, Navigation, authPut } from "../../lib";
import FormData from "form-data";
import { WatchFormState } from "../../state/watchform/state";
import { Photo } from "../../types";

export function AddWatchPage(props) {
  const siteId = useSelector((state: AppState) => state.scopes.site_id);
  const watchId = props.pageRoute.match.params.id;
  const isEdit = props.pageRoute.page.type === "PAGE_EDIT_WATCH";
  // const initialValues = {
  //   data: {
  //     ///Default values
  //     status_id: 1,
  //     item_additional_band_refs: [],
  //     ///HardCoding Required Values
  //     // model_no: "Hardcoded-" + Date.now(),
  //     // brand_id: 103,
  //     // series_id: 1404,
  //     // size_id: 1,
  //   },
  // };
  const [watchData, setWatchData] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      WatchActions.getList([
        "brand",
        "series",
        "size",
        "warranty",
        "status",
        "vendor",
        "ship_tier",
        "delivery",
        "material",
      ]),
    );
  }, [dispatch]);

  if (!isEdit && siteId !== 0) {
    return null;
  }
  return <RenderWatchPage watchId={watchId} isEdit={isEdit} />;
}

export function RenderWatchPage(props) {
  const { watchId, isEdit } = props;
  const apiReqInProgress = useRef(false);
  const axios = require("axios");
  const dispatch = useDispatch();
  const classes = useStyles();
  const [retired, setRetired] = useState(false);
  const [editWatch, setEditWatch] = useState<undefined | WatchFormState>();
  const [statusID, setStatusID] = useState();
  const [duplicate, setDuplicate] = useState(false);
  // const [title, setTitle] = useState("");
  const [tpc_retail, setTpc_retail] = useState(true);
  const [myPhotos, setMyPhotos] = useState<Photo[]>([]);
  const siteId = useSelector((state: AppState) => state.scopes.site_id);
  const watchform = useSelector(WatchFormSelectors.watchform);
  const setFormatEditWatch = (state) => {
    const _editWatch = {
      ...state,
      water_resistance_m: parseInt(state.water_resistance_m),
      water_resistance_ft: parseInt(state.water_resistance_ft),
      //this is where to add parent so props dont re-update
    };
    setEditWatch(_editWatch);
  };
  useEffect(() => {
    (async function () {
      if (isEdit && apiReqInProgress.current === false) {
        apiReqInProgress.current = true;
        const response = await authGet(`item/${watchId}?site=${siteId}`);
        const { error, data } = response;
        if (error) {
          return;
        }
        setFormatEditWatch(data);
      } else if (!isEdit) {
        setFormatEditWatch(watchform);
      } else {
        console.log("Already making call");
      }
    })();
  }, [isEdit, siteId, watchId, watchform]);
  // useEffect(() => {
  //   if (editWatch) {
  //     // setStatusID(editWatch.status_id);
  //     // setTitle(editWatch?.title || "rerw");
  //   }
  // }, [editWatch]);

  useEffect(() => {
    if (isEdit) {
      if (watchform.pic_big) {
        myPhotos.push({
          id: myPhotos.length + 1,
          name: `https://objects.liquidweb.services/prest-dev/${watchform.pic_big}`,
          // `${data.image_url}/${watchform.pic_big}`,
        });
      }
      if (watchform.pic_alt1) {
        myPhotos.push({
          id: myPhotos.length + 1,
          name: `https://objects.liquidweb.services/prest-dev/${watchform.pic_alt1}`,
          caption: watchform.pic_alt1_text,
          // `${data.image_url}/${watchform.pic_big}`,
        });
      }

      if (watchform.pic_alt2) {
        myPhotos.push({
          id: myPhotos.length + 1,
          caption: watchform.pic_alt2_text,
          name: `https://objects.liquidweb.services/prest-dev/${watchform.pic_alt2}`,
          // `${data.image_url}/${watchform.pic_alt2}`,
        });
      }
      if (watchform.pic_alt3) {
        myPhotos.push({
          id: myPhotos.length + 1,
          caption: watchform.pic_alt3_text,
          name: `https://objects.liquidweb.services/prest-dev/${watchform.pic_alt3}`,
          // `${data.image_url}/${watchform.pic_alt3}`,
        });
      }

      if (watchform.pic_alt4) {
        myPhotos.push({
          id: myPhotos.length + 1,
          caption: watchform.pic_alt4_text,
          name: `https://objects.liquidweb.services/prest-dev/${watchform.pic_alt4}`,
          // `${data.image_url}/${watchform.pic_alt4}`,
        });
      }

      if (watchform.pic_alt5) {
        myPhotos.push({
          id: myPhotos.length + 1,
          caption: watchform.pic_alt5_text,
          name: `https://objects.liquidweb.services/prest-dev/${watchform.pic_alt5}`,
          // `${data.image_url}/${watchform.pic_alt5}`,
        });
      }

      if (watchform.pic_alt6) {
        myPhotos.push({
          id: myPhotos.length + 1,
          caption: watchform.pic_alt6_text,
          name: `https://objects.liquidweb.services/prest-dev/${watchform.pic_alt6}`,
          // `${data.image_url}/${watchform.pic_alt6}`,
        });
      }
    }
  }, []);

  // const [myPhotos, setMyPhotos] = useState([watchform.pic_big]);
  const token = useSelector((state: AppState) => state.auth.token);

  if (!editWatch) {
    return null;
  }
  return (
    <Box
      style={{
        width: "100%",
      }}
    >
      <Formik
        initialValues={{ ...editWatch }}
        // enableReinitialize
        onSubmit={async (values, { setSubmitting }) => {
          console.log("onSubmit", values);
          if (duplicate) {
            console.log(
              "if duplicate im saving this watch then opening add watch page with all the data needed",
            );

            const data: any = await dispatch(
              WatchActions.addWatch(
                `/item?site=${siteId}`, //add
                values,
                duplicate,
              ),
            );
            if (tpc_retail) {
              await authPut(`/site_share/${data?.item_id}?site=2`, {
                price_retail: parseInt(data?.price_retail),
              });
              Navigation.go(`/edit-watch/${data?.item_id}`);
            } else {
              Navigation.go(`/edit-watch/${data?.item_id}`);
            }
          } else if (isEdit) {
            const data: any = await dispatch(
              WatchActions.editWatch(
                `/item/${watchId}?site=${siteId}`, //edit
                values,
              ),
            );
            if (data?.item_id) {
              if (tpc_retail) {
                await authPut(`/site_share/${data?.item_id}?site=2`, {
                  price_retail: parseInt(data?.price_retail),
                });
              }
              window.location.reload();
            }
          } else {
            console.log(values);
            const data: any = await dispatch(
              WatchActions.addWatch(
                `/item?site=${siteId}`, //add
                values,
              ),
            );

            const config = {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
            };
            if (data?.item_id) {
              //Upload images
              let fd = new FormData();
              myPhotos.map((file: any) => fd.append("File", file.image));
              myPhotos.map((file: any, index: any) =>
                fd.append(index, file.caption),
              );
              axios
                .post(
                  `http://localhost:3304/api/V1/images/${values.model_no}?site=${siteId}&item=${data.item_id}`,
                  fd,
                  config,
                )
                .then((response) => {
                  // callback(response);
                  console.log("image Success");
                })
                .catch((error) => {
                  console.log("image error");
                });
              if (tpc_retail) {
                await authPut(`/site_share/${data?.item_id}?site=2`, {
                  price_retail: parseInt(data?.price_retail),
                });
                Navigation.go(`/edit-watch/${data?.item_id}`);
              } else {
                Navigation.go(`/edit-watch/${data?.item_id}`);
              }
            }
          }

          setTimeout(() => {
            setSubmitting(true);
            // alert(JSON.stringify(values, null, 2));
          }, 500);
        }}
      >
        {({ submitForm, isSubmitting, setFieldValue }) => (
          <Form className={classes.centerMaxWidth}>
            <AddWatchStepper
              tpc_retail={tpc_retail}
              setTpc_retail={setTpc_retail}
              classes={classes}
              watchId={watchId}
              isEdit={isEdit}
              setFieldValue={setFieldValue}
              submitForm={submitForm}
              setStatus={setStatusID}
              setDuplicate={setDuplicate}
              setRetired={setRetired}
              setMyPhotos={setMyPhotos}
              myPhotos={myPhotos}
              // retired={retired}
              // statusID={statusID}
            />
          </Form>
        )}
      </Formik>
    </Box>
  );
}
