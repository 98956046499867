import { WatchFormActions } from "./actions";
/**
 * Preferences state (**persisted**).
 * @example
 * {
 *    dialogEdit: true,
 * }
 */
const initialValues = {
  screw_down_crown: false,
  water_resistance_ft: null,
  status_id: 1,
  item_additional_band_refs: [],
  model_no: "",
  brand_id: null,
  series_id: null,
  size_id: null,
  date_added: null,
  band_color_id: null,
  band_material_id: null,
  band_name_id: null,
  band_notes: null,
  bezel_characteristics_id: null,
  bezel_finish_id: null,
  bezel_id: null,
  bezel_material_id: null,
  bezel_notes: null,
  button_material_id: null,
  button_notes: null,
  calibre_id: null,
  case_back_id: null,
  case_back_notes: null,
  case_finish_id: null,
  case_finish_notes: null,
  case_length: null,
  case_material_id: null,
  case_shape_id: null,
  case_thickness: null,
  case_width: null,
  clasp_characteristic_id: null,
  clasp_name_id: null,
  clasp_notes: null,
  clasp_type_id: null,
  crown_material_id: null,
  crown_notes: null,
  crystal_id: null,
  crystal_notes: null,
  date_display: null,
  date_reduced: null,
  date_notes: null,
  delivery_id: null,
  description: null,
  dial_color_id: null,
  dial_finish_id: null,
  dial_flange_color_id: null,
  dimension_notes: null,
  edition_notes: null,
  flange_indication_id: null,
  gtin: null,
  hands_finish_id: null,
  hands_luminescence: null,
  hands_luminescence_notes: null,
  hands_material_id: null,
  hands_notes: null,
  height_and_lugs: null,
  helium_escape_valve: null,
  helium_escape_valve_notes: null,
  hour_marker_color_id: null,
  hour_marker_luminescence: null,
  hour_marker_luminescence_notes: null,
  hour_marker_notes: null,
  hour_marker_type_id: null,
  limited_edition: null,
  limited_quantity: null,
  lug_width: null,
  luminescence_id: null,
  luminescence_notes: null,
  main_dial_notes: null,
  model_aka: null,
  model_no_2: null,
  numbered_edition: null,
  papers_included: null,
  price_on_special: false,
  price_our: null,
  price_retail: null,
  price_special: null,
  retired: null,
  screw_down_buttons: null,
  second_hand_id: null,
  second_hand_luminescence: null,
  second_hand_material_id: null,
  second_hand_notes: null,
  seo_stub: null,
  ship_tier_id: null,
  show_recent: false,
  show_reduced: false,
  special_edition: null,
  subdial_color_id: null,
  subdial_notes: null,
  title: null,
  track_notes: null,
  track_type_id: null,
  vendor_id: null,
  vendor_exp: null,
  warranty_id: null,
  water_resistance_m: null,
  item_id: undefined,
  pic_big: null,
  pic_alt1: null,
  pic_alt1_text: null,
  pic_alt2: null,
  pic_alt2_text: null,
  pic_alt3: null,
  pic_alt3_text: null,
  pic_alt4: null,
  pic_alt4_text: null,
  pic_alt5: null,
  pic_alt5_text: null,
  pic_alt6: null,
  pic_alt6_text: null,
};
//  interface WatchFormState {
//   name: string;
//   persist: boolean;
//   defaults: typeof initialValues;
//   handlers: any;
// }
export type WatchFormState = typeof initialValues;

export const watchform = {
  name: "watchform",
  persist: true,
  defaults: {
    ...initialValues,
  },
  handlers: {
    [WatchFormActions.type.CLEAR_FORM](state: WatchFormState, action: any) {
      return initialValues;
    },
    [WatchFormActions.type.SAVE_FORM](state: WatchFormState, { payload }: any) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
