import React, { useEffect, useState } from "react";
import { WatchActions } from "../../../../state";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {} from "../../../../lib";
import { AddShipping } from ".";
import {
  Typography,
  Box,
  FormControlLabel,
  Switch,
  Paper,
  Collapse,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid/";
import {
  defaultRate,
  defaultHigh,
  defaultLow,
  format_Word,
  getDetailButton,
  CustomToolbar,
} from "../../../../lib";
import { ConfirmButton } from ".";
import { withStyles } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";

export function ShippingSiteAttributes(props) {
  const [defaultShippingRows, setDefaultShippingRows] = useState([]);
  const [checked2, setChecked2] = useState(false);
  const dispatch = useDispatch();
  const GreenSwitch = withStyles({
    switchBase: {
      color: "#C8C8C8",
      "&$checked": {
        color: "#315179",
      },
      "&$checked + $track": {
        backgroundColor: "#C8C8C8",
      },
    },
    checked: {},
    track: {},
  })(Switch);
  const {
    pageRoute: {
      params: { attribute },
    },
  } = props;
  const siteId = useSelector((state) => state.scopes.site_id);

  const [attrRows, setAttrRows] = useState([]);
  const attrData = useSelector(
    (state) => state.watches.attributeList,
    shallowEqual,
  );
  useEffect(() => {
    (async function () {
      const defaultShipping = await WatchActions.getDefaultShippingDetails(
        siteId,
      );
      setDefaultShippingRows(defaultShipping);
      dispatch(WatchActions.getShippingAttribute(siteId));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attribute, siteId]);
  const [defaultShippingData, setDefaultShippingData] = useState([]);

  useEffect(() => {
    const rows = attrData.map((row) => {
      let id = row[`${attribute}_id`];
      var tableRow = {
        id: id,
        button: row,
        name: row[attribute],
        count: row.count,
      };
      return tableRow;
    });
    setAttrRows(rows);
  }, [attrData, attribute]);
  const attrColumns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    { field: "name", headerName: "Name", flex: 1 },
    {
      field: "count",
      headerName: "Count",
      type: "number",
      flex: 0.4,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "button",
      headerName: "Details",
      flex: 1,
      filterable: false,
      renderCell: (params) => {
        return getDetailButton(
          "shipping-details",
          params.value,
          attribute,
          siteId,
        );
      },
    },
  ];

  const shipColumns = [
    { field: "description", headerName: "Description", flex: 1 },
    {
      field: "rate",
      headerName: "Default Rate",
      renderCell: (params) => {
        return defaultRate(
          params.value,
          defaultShippingData,
          setDefaultShippingData,
        );
      },
      flex: 1,
    },
    {
      field: "price_low",
      headerName: "Default Price Low",
      renderCell: (params) => {
        return defaultLow(
          params.value,
          defaultShippingData,
          setDefaultShippingData,
        );
      },
      flex: 1,
    },
    {
      field: "price_high",
      headerName: "Default Price High",
      renderCell: (params) => {
        return defaultHigh(
          params.value,
          defaultShippingData,
          setDefaultShippingData,
        );
      },
      flex: 1,
    },
  ];

  let shipRows = [];
  if (defaultShippingRows) {
    shipRows = defaultShippingRows.map((row) => {
      return {
        id: row.ship_rate_id,
        description: row.description,
        rate: row,
        price_low: row,
        price_high: row,
        // additional_amount: row,
      };
    });
  }
  const handleChange2 = () => {
    if (checked2) {
      setChecked2(false);
    } else {
      setChecked2(true);
    }
  };
  const closeBox = () => setChecked2(false);
  return (
    <Box
      style={{
        width: "100%",
      }}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="h3">Shipping Tiers</Typography>
      </div>
      {siteId === 0 && (
        <AddShipping
          attribute={format_Word(attribute)}
          rawAttribute={attribute}
          path={"manage-shipping-tiers"}
        />
      )}
      <div>
        <FormControlLabel
          control={<GreenSwitch checked={checked2} onChange={handleChange2} />}
          label="Show Default Shipping Rates"
        />

        <Collapse in={checked2}>
          <Paper elevation={4}>
            <Box
              boxShadow={5}
              display="flex"
              justifyContent="center"
              my={1}
              p={2}
              pt={2}
            >
              <DataGrid
                rows={shipRows}
                columns={shipColumns}
                disableColumnMenu={true}
                isRowSelectable={false}
                autoHeight={true}
                pageSize={100}
                checkboxSelection={false}
                rowHeight={50}
                hideFooter={true}
              />
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              my={1}
              p={2}
              pt={1}
              onClick={closeBox}
            >
              <ConfirmButton
                attribute={"Default Shipping Rates"}
                checked2={checked2}
                rawAttribute={"ship_tier"}
                Add_Edit_Delete={"edit"}
                color={"primary"}
                path={"manage-shipping-tiers"}
                shipData={defaultShippingData}
                myValue={"ship_tier"}
              />
            </Box>
          </Paper>
        </Collapse>
      </div>
      <Box
        boxShadow={5}
        display="flex"
        justifyContent="center"
        mt={2}
        mb={2}
        p={2}
        pt={2}
      >
        {attrRows[0]?.id && (
          <DataGrid
            rows={attrRows}
            columns={attrColumns}
            disableColumnMenu={true}
            isRowSelectable={false}
            pageSize={100}
            checkboxSelection={false}
            autoHeight={true}
            rowHeight={38}
            components={{
              Toolbar: CustomToolbar,
            }}
            sortModel={[
              {
                field: "name",
                sort: "asc",
              },
            ]}
          />
        )}
      </Box>
    </Box>
  );
}
