import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Typography, Box } from "@material-ui/core";
import { WatchActions } from "../../../../state";
import { format_Word } from "../../../../lib";
import { EditWatchDetails, GroupTransferList } from ".";

export function WatchDetailsGroups(props) {
  const [rows, setRows] = useState([]);
  const siteId = useSelector((state) => state.scopes.site_id);

  const {
    pageRoute: {
      params: { attribute, id, detail, brand },
    },
  } = props;

  const [editBrand, setEditBrand] = useState();
  const [groupId, setGroupId] = useState();
  const [editName, setEditName] = useState(detail);
  const [myBrandId, setMyBrandId] = useState();
  const [groupDetailsData, setGroupDetailsData] = useState();
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    (async function () {
      const data = await WatchActions.getDetailsGroups(attribute, id, siteId);
      setRows(data);
      const brand = await WatchActions.getDetailsValue(
        attribute,
        id,
        true,
        siteId,
      );
      setGroupId(brand[attribute + "_id"]);
      setEditBrand(brand.brand_id);
    })();
  }, [attribute, id, siteId]);
  return (
    <Box
      style={{
        width: "100%",
      }}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="h3">
          {format_Word(attribute) + " - " + detail + " (ID:" + id + ")"}
        </Typography>
      </div>
      <EditWatchDetails
        attribute={attribute}
        brandTitle={brand}
        id={id}
        detail={detail}
        rawAttribute={attribute}
        path={
          detail === "series_group"
            ? "watch-attribute-affiliated"
            : "watch-attribute-groups"
        }
        setEditName={setEditName}
        setEditBrand={setEditBrand}
        setGroupDetailsData={setGroupDetailsData}
        myBrandId={myBrandId}
        setMyBrandId={setMyBrandId}
        setIsDisabled={setIsDisabled}
        isDisabled={isDisabled}
      ></EditWatchDetails>

      <GroupTransferList
        groupId={groupId}
        series={rows}
        attribute={attribute}
        editName={editName}
        editBrand={editBrand}
        groupDetailsData={groupDetailsData}
        setGroupDetailsData={setGroupDetailsData}
        myBrandId={myBrandId}
        setIsDisabled={setIsDisabled}
        isDisabled={isDisabled}
      />
    </Box>
  );
}
