import React from "react";
import { useSelector } from "react-redux";
import { Button, Grid, Box } from "@material-ui/core";
import { useStyles } from "./WatchAttributes.styles";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

export const MoveButtons = (props) => {
  const siteId = useSelector((state) => state.scopes.site_id);

  return (
    <Grid container>
      {/* <Box item style={{ display: "flex" }}> */}
      <Box style={{ display: "flex" }}>
        <Button
          onClick={props.handleCheckedUp}
          disabled={props.disableUpArrow || siteId !== 0}
          aria-label="move selected up"
        >
          <KeyboardArrowUpIcon />
        </Button>
        <Button
          onClick={props.handleCheckedDown}
          disabled={props.disableDownArrow || siteId !== 0}
          aria-label="move selected down"
        >
          <KeyboardArrowDownIcon />
        </Button>
      </Box>
    </Grid>
  );
};
