import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import {
  Box,
  LinearProgress,
  Button,
  TextField as TxtField,
  FormControlLabel,
  FormGroup,
  FormLabel,
  FormControl,
  Checkbox,
  InputAdornment,
  Tooltip,
} from "@material-ui/core";
import { TextField, CheckboxWithLabel } from "formik-material-ui";
import { ConfirmButton } from ".";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { WatchActions, useDispatch, useSelector } from "../../../../state";
import { Link } from "react-router-dom";
import { shallowEqual } from "react-redux";
import { useForceUpdate } from "../../../../lib";
import List from "material-ui/List/List";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
interface Props {
  brand: any;
  stub: any;
  sites_router_metum: any;
  custom_aka: boolean;

  myTitle: any;
  id: any;
  myBrand: any;
  brandListTitle: any;
  setMyBrand: any;
  setMyBrandId: any;
  setEditBrand: any;
  myBrandId: any;
  complicationsList: any;
  certificationList: any;
  movementList: any;
  calibreList: any;
  defaultCert: any;
  defaultMove: any;
  defaultCalibre: any;

  brandTitle: any;
  rawAttribute: any;
  attribute: any;
  base_calibre: any;
  brand_id: number;
  calibre: any;
  can_delete: true;
  certification_id: any;
  components: any;
  description: any;
  description_gb: any;
  item_calibre_complications: any;
  movement: any;
  calibre_complication_details: any;
  calibre_id: number;
  parent_calibre_id: number;
  reserve_days: number;
  reserve_hours: number;
  reserve_notes: any;
  vph: number;
  jewels: number;
  in_house: boolean;
}

export function CalibreDetails(props: Props) {
  let forceUpdate = useForceUpdate();
  const dispatch = useDispatch();
  const [bgColor, setBgColor] = useState();
  const [isDisabled, setIsDisabled] = useState(true);

  const compList: any[] = props.complicationsList;
  const compCheck: any[] = props.item_calibre_complications;
  const compNotes: any[] = props.calibre_complication_details;

  const [certificationValue, setCertificationValue] = useState<any>();

  const [movementValue, setMovementValue] = useState<any>();
  const [calibreValue, setCalibreValue] = useState<any>();
  const [in_house_Movement, set_In_house_Movement] = useState<any>(
    props.in_house,
  );

  const [vph, setVph] = useState(props.vph);
  const [hz, setHz] = useState(props.vph / 7200);
  const [errorDialog, setErrorDialog] = useState(false);

  function handleChangeHz(e: any) {
    setHz(e.target.value);
    setVph(e.target.value * 7200);
    if (e.target.value % 0.5 !== 0) {
      setErrorDialog(true);
    } else setErrorDialog(false);
  }
  function handleChangeVph(e: any) {
    setVph(e.target.value);
    setHz(e.target.value / 7200);
    if (e.target.value % 7200 !== 0) {
      setErrorDialog(true);
    } else setErrorDialog(false);
  }

  return (
    <div style={{ width: "100%" }}>
      <Formik
        initialValues={{
          ...props,
        }}
        onSubmit={(values) => {
          dispatch(
            WatchActions.editMovement(`/calibre-details/${props.id}`, {
              in_house: in_house_Movement ? in_house_Movement : false,
              brand_id: props.myBrandId ? props.myBrandId : values.brand_id,
              calibre: values.calibre,
              certification_id: certificationValue?.certification_id
                ? certificationValue?.certification_id
                : props.defaultCert[0]?.certification_id,
              movement: movementValue?.movement_id
                ? movementValue?.movement_id
                : props.defaultMove[0]?.movement_id,
              base_calibre: calibreValue?.calibre_id
                ? calibreValue?.calibre_id
                : props.defaultCalibre[0]?.calibre_id,
              description: values.description,
              // parent_calibre_id: movementValue[0].id,
              reserve_days: Number(values.reserve_days),
              reserve_hours: Number(values.reserve_hours),
              reserve_notes: values.reserve_notes,
              vph: vph,
              components: values.components,
              complications: compCheck.map((checkedId: any) => {
                return checkedId.calibre_complication_id;
              }),
              complication_details: compNotes,
              jewels: values.jewels ? values.jewels : 0,
            }),
          );
        }}
      >
        {({ submitForm, isSubmitting, values }) => (
          <Form>
            <Box
              boxShadow={5}
              display="flex"
              flexWrap="wrap"
              flexDirection="column"
              alignItems="left"
              my={4}
              p={2}
              pt={2}
              bgcolor={bgColor}
              onKeyUp={(e: any) => {
                setIsDisabled(false);
              }}
            >
              <Box m={1} display="flex" justifyContent="space-between">
                <Field
                  component={TextField}
                  name="calibre"
                  label="Calibre"
                  style={{ width: "49.50%" }}
                />
                <Autocomplete
                  inputValue={props.myBrand}
                  defaultValue={props.myTitle}
                  id="combo-box-demo"
                  options={props.brandListTitle}
                  getOptionLabel={(option) => option.title || ""}
                  onChange={(event, value) => {
                    if (value) {
                      props.setMyBrand(value?.title);
                      props.setMyBrandId(value?.id);
                      setIsDisabled(false);
                      if (props.setEditBrand) {
                        props.setEditBrand(value?.id);
                      }
                    }
                  }}
                  style={{ width: "49.50%" }}
                  renderInput={(params) => (
                    <TxtField
                      {...params}
                      label="Brand Affiliation"
                      defaultValue={props.brandTitle}
                    />
                  )}
                />
              </Box>
              <Box m={1} display="flex" justifyContent="space-between">
                <Box>
                  <FormControlLabel
                    value="start"
                    control={
                      <Checkbox
                        color="primary"
                        checked={in_house_Movement}
                        onClick={() => {
                          set_In_house_Movement(!in_house_Movement);
                          setIsDisabled(false);
                        }}
                      />
                    }
                    label={"In House"}
                    labelPlacement="end"
                  />
                </Box>
                {/* <Autocomplete
                  inputValue={movementValue}
                  // defaultValue={movementValue[0]}
                  id="combo-box-demo"
                  options={props.movementList}
                  getOptionLabel={(option: any) => option.movement}
                  onChange={(event, value) => {
                    if (value) {
                      setMovementValue([value]);
                      setIsDisabled(false);
                    }
                  }}
                  style={{ width: "36%" }}
                  renderInput={(params) => (
                    <TxtField
                      {...params}
                      label="Movement Type"
                      defaultValue={movementValue[0]}
                    />
                  )}
                /> */}
                {props.movementList && props.defaultMove && (
                  <Autocomplete
                    inputValue={movementValue}
                    defaultValue={props.defaultMove[0]}
                    id="cert-box"
                    options={props.movementList}
                    getOptionLabel={(option) => option.movement}
                    onChange={(event, value) => {
                      if (value) {
                        setMovementValue(value);
                        setIsDisabled(false);
                      }
                    }}
                    style={{ width: "36%" }}
                    renderInput={(params) => (
                      <TxtField {...params} label="Movement Type" />
                    )}
                  />
                )}
                {props.certificationList && props.defaultCert && (
                  <Autocomplete
                    inputValue={certificationValue}
                    defaultValue={props.defaultCert[0]}
                    id="cert-box"
                    options={props.certificationList.filter(
                      (cert: any) =>
                        cert.brand_id === values.brand_id ||
                        cert.brand_id === null,
                    )}
                    getOptionLabel={(option) => option.certification}
                    onChange={(event, value) => {
                      if (value) {
                        setCertificationValue(value);
                        setIsDisabled(false);
                      }
                    }}
                    style={{ width: "49.50%" }}
                    renderInput={(params) => (
                      <TxtField {...params} label="Brand Certification" />
                    )}
                  />
                )}
              </Box>
              <Box m={1} display="flex" justifyContent="space-between">
                {props.calibreList && props.defaultCalibre && (
                  <Autocomplete
                    inputValue={calibreValue}
                    defaultValue={props.defaultCalibre[0]}
                    id="cert-box"
                    options={props.calibreList}
                    getOptionLabel={(option) => option.calibre}
                    onChange={(event, value) => {
                      if (value) {
                        setCalibreValue(value);
                        setIsDisabled(false);
                      }
                    }}
                    style={{ width: "33%" }}
                    renderInput={(params) => (
                      <TxtField {...params} label="Base Calibre" />
                    )}
                  />
                )}

                <TxtField
                  error={errorDialog}
                  id="outlined-basic"
                  label={errorDialog ? "Please enter a full number" : "HZ"}
                  variant="outlined"
                  value={hz}
                  onChange={handleChangeHz}
                />
                <TxtField
                  type="number"
                  id="outlined"
                  label="VPH"
                  variant="outlined"
                  value={vph}
                  onChange={handleChangeVph}
                />
                <Field
                  component={TextField}
                  type="number"
                  name="jewels"
                  label="Jewels"
                  style={{ width: "16%" }}
                />
                <Field
                  component={TextField}
                  type="number"
                  name="components"
                  label="Components"
                  style={{ width: "16%" }}
                />
              </Box>
              <Box m={1} display="flex" justifyContent="space-between">
                <Field
                  component={TextField}
                  name="reserve_hours"
                  label={
                    values.reserve_hours > 48
                      ? "More then 48 hours use days"
                      : "Hours"
                  }
                  style={{ width: "16%" }}
                  error={values.reserve_hours > 48}
                />
                <Field
                  component={TextField}
                  name="reserve_days"
                  label={
                    values.reserve_days <= 2 && values.reserve_days > 0
                      ? "Less then 2 days use hours"
                      : "Days"
                  }
                  style={{ width: "16%" }}
                  error={values.reserve_days <= 2 && values.reserve_days > 0}
                />
                <Field
                  component={TextField}
                  name="reserve_notes"
                  label="Power Reserve Notes"
                  style={{ width: "66%" }}
                />
              </Box>

              <Box m={1} display="flex" justifyContent="space-between">
                <Field
                  multiline={true}
                  fullWidth={true}
                  component={TextField}
                  name="description"
                  label="Movement Notes"
                />
              </Box>
              {props.complicationsList && (
                <FormControl component="fieldset">
                  <FormLabel component="legend">Select Complications</FormLabel>
                  <FormGroup aria-label="position" row>
                    {compList.map((list: any, index: any) => {
                      for (let i = 0; i < compCheck.length; i++) {
                        if (
                          compCheck[i].calibre_complication_id ===
                          list.calibre_complication_id
                        ) {
                          compList.splice(index, 1);
                          forceUpdate();
                        }
                      }
                      return (
                        <Box mr={2}>
                          <FormControlLabel
                            value="start"
                            control={
                              <Checkbox
                                color="primary"
                                size="medium"
                                checked={false}
                                onClick={(
                                  event: React.MouseEvent<HTMLElement>,
                                ) => {
                                  compList.splice(index, 1);
                                  compCheck.push(list);
                                  setIsDisabled(false);
                                  forceUpdate();
                                }}
                              />
                            }
                            label={list.calibre_complication}
                            labelPlacement="end"
                          />
                        </Box>
                      );
                    })}
                  </FormGroup>
                </FormControl>
              )}

              <Box>
                {compCheck.map((value: any, index: any) => {
                  return (
                    <Box>
                      <Box display="flex">
                        <Box>
                          <FormControlLabel
                            value="start"
                            control={
                              <Checkbox
                                color="primary"
                                checked={true}
                                onClick={(event) => {
                                  compList.push(value);
                                  compCheck.splice(index, 1);
                                  setIsDisabled(false);
                                  forceUpdate();
                                }}
                              />
                            }
                            label={value.calibre_complication}
                            labelPlacement="end"
                          />
                        </Box>
                        {/* <Box mt={1}> */}
                        <Tooltip title="Add Details Field">
                          <Button
                            onClick={(event) => {
                              compNotes.push({
                                calibre_id: Number(props.id),
                                calibre_complication_id:
                                  value.calibre_complication_id,
                                details: "",
                              });
                              setIsDisabled(false);
                              forceUpdate();
                            }}
                          >
                            <AddCircleOutlineIcon />
                          </Button>
                        </Tooltip>
                        {/* </Box> */}
                      </Box>

                      {compNotes.map((note: any, noteIndex: number) => {
                        if (
                          note.calibre_complication_id ===
                          value.calibre_complication_id
                        ) {
                          return (
                            <Box ml={5} pt={1}>
                              <TxtField
                                style={{ width: "99%" }}
                                value={note.details}
                                label={
                                  "Additional " +
                                  value.calibre_complication +
                                  " Details"
                                }
                                onChange={(event: any) => {
                                  compNotes[noteIndex].details =
                                    event.target.value;
                                  forceUpdate();
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Tooltip title="Delete">
                                        <Button
                                          onClick={(event) => {
                                            compNotes.splice(noteIndex, 1);
                                            setIsDisabled(false);
                                            forceUpdate();
                                          }}
                                        >
                                          <RemoveCircleOutlineOutlinedIcon color="secondary" />
                                        </Button>
                                      </Tooltip>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Box>
                          );
                        }
                      })}
                    </Box>
                  );
                })}
              </Box>
              <Box m={1} display="flex" justifyContent="space-between">
                {isSubmitting && <LinearProgress />}
                {!props.attribute?.endsWith("_group") && (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      isDisabled ||
                      (values.reserve_days <= 2 && values.reserve_days > 0) ||
                      values.reserve_hours > 48
                    }
                    onClick={submitForm}
                    component={Link}
                    to={`/movement-attribute/calibre`}
                  >
                    Edit Calibre
                  </Button>
                )}

                {props.can_delete && (
                  <ConfirmButton
                    attribute={props.attribute}
                    myValue={values.brand}
                    myBrand={values.brand}
                    myBrandId={values.brand_id}
                    id={props.id}
                    rawAttribute={props.rawAttribute}
                    Add_Edit_Delete={"delete"}
                    color={"secondary"}
                    disabled={false}
                    path={"movement-attribute"}
                  />
                )}
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
}
