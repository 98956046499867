import React from "react";

export function NotFoundPage() {
  return (
    <div className="paper">
      <h1>PAGE NOT FOUND</h1>
      <img
        src="https://mattalltrades.files.wordpress.com/2010/03/watch.jpg"
        alt="walla"
      ></img>
    </div>
  );
}
