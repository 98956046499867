import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    formControl: {
      margin: theme.spacing(1),
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    centerMaxWidth: {
      maxWidth: 1600,
      margin: "0 auto",
    },
    grid: {
      display: "grid",
      gridGap: 10,
      alignItems: "center",
      gridTemplateColumns: "repeat(auto-fit, minmax(120px, 1fr))",
      padding: 0,
    },
    gridTwo: {
      gridColumn: "span 2",
      "& .MuiOutlinedInput-input": { fontSize: 14, padding: 3 },
      "& .MuiInputLabel-marginDense": {
        margin: "-6px",
      },
      "& .MuiCheckbox-root.PrivateSwitchBase-root-43": {
        padding: "0px",
      },
      "& ..MuiCheckbox-colorSecondary.Mui-checked": {
        padding: "0px",
      },
      "& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense ": {
        padding: 0,
      },
      "& .MuiAutocomplete-inputRoot[class*=MuiOutlinedInput-root][class*=MuiOutlinedInput-marginDense]": {
        padding: 3,
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] .MuiAutocomplete-input': {
        padding: 0,
      },
      '& .MuiAutocomplete-option[data-focus="true"]': {
        background: "#C8C8C8",
      },
      "& .MuiInputBase-input.MuiAutocomplete-input": {
        fontSize: 14,
      },
      "& .MuiFormLabel-root": {
        fontSize: 14,
      },
    },
    gridTwoBlue: {
      gridColumn: "span 2",
      padding: -3,
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#3f50b5",
      },
      "& .MuiInputLabel-outlined": {
        color: "#3f50b5",
        margin: "-6px",
      },
      "& .MuiInputLabel-marginDense": {
        margin: "-6px",
      },

      "& .MuiOutlinedInput-input": {
        fontSize: 14,
        padding: 3,
        "& .MuiAutocomplete-inputRoot[class*=MuiOutlinedInput-root][class*=MuiOutlinedInput-marginDense]": {
          padding: 0,
        },
      },
      "& .MuiAutocomplete-inputRoot[class*=MuiOutlinedInput-root][class*=MuiOutlinedInput-marginDense]": {
        padding: 3,
      },
      "& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense ": {
        padding: 0,
      },

      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] .MuiAutocomplete-input': {
        padding: 0,
      },
      '& .MuiAutocomplete-option[data-focus="true"]': {
        background: "#C8C8C8",
      },
      "& .MuiInputBase-input.MuiAutocomplete-input": {
        fontSize: 14,
      },
      "& .MuiFormLabel-root": {
        fontSize: 14,
      },
    },
    gridTwoBlack: {
      padding: -3,
      gridColumn: "span 2",
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#000000",
      },
      "& .MuiInputLabel-outlined": {
        color: "#000000",
        margin: "-6px",
      },
      "& .MuiInputLabel-marginDense": {
        margin: "-6px",
      },

      "& .MuiOutlinedInput-input": { fontSize: 14, padding: 3 },
      "& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense ": {
        padding: 0,
      },
      "& .MuiAutocomplete-inputRoot[class*=MuiOutlinedInput-root][class*=MuiOutlinedInput-marginDense]": {
        padding: 3,
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] .MuiAutocomplete-input ': {
        padding: 0,
      },
      '& .MuiAutocomplete-option[data-focus="true"]': {
        background: "#C8C8C8",
      },
      "& .MuiInputBase-input.MuiAutocomplete-input": {
        fontSize: 14,
      },
      "& .MuiFormLabel-root": {
        fontSize: 14,
      },
    },
    gridAbove50: {
      gridColumn: "span 2",
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#3f50b5",
      },
      "& .MuiInputLabel-outlined": {
        color: "#d32f2f",
        margin: "-6px",
      },
      "& .MuiInputLabel-marginDense": {
        margin: "-6px",
      },

      "& .MuiOutlinedInput-input": { fontSize: 14, padding: 3 },
      "& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense ": {
        padding: 0,
      },
      "& .MuiAutocomplete-inputRoot[class*=MuiOutlinedInput-root][class*=MuiOutlinedInput-marginDense]": {
        padding: 3,
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] .MuiAutocomplete-input': {
        padding: 0,
      },
      "& .MuiInputBase-input.MuiAutocomplete-input": {
        fontSize: 14,
      },
      "& .MuiFormLabel-root": {
        fontSize: 14,
      },
    },
    gridTwoRed: {
      padding: -3,
      gridColumn: "span 2",
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#d32f2f",
      },
      "& .MuiInputLabel-outlined": {
        color: "#d32f2f",
        margin: "-6px",
      },
      "& .MuiInputLabel-marginDense": {
        margin: "-6px",
      },
      "& .MuiOutlinedInput-input": { fontSize: 14, padding: 3 },
      "& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense ": {
        padding: 0,
      },
      "& .MuiAutocomplete-inputRoot[class*=MuiOutlinedInput-root][class*=MuiOutlinedInput-marginDense]": {
        padding: 3,
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] .MuiAutocomplete-input': {
        padding: 0,
      },
      '& .MuiAutocomplete-option[data-focus="true"]': {
        background: "#C8C8C8",
      },
      expanded: {
        margin: "0 auto",
      },
      "& .MuiInputBase-input.MuiAutocomplete-input": {
        fontSize: 14,
      },
      "& .MuiFormLabel-root": {
        fontSize: 14,
      },
    },

    iconButton: {
      display: "flex",
    },
    stickToBottom: {
      width: "100%",
      position: "fixed",
      bottom: 0,
    },
  }),
  {
    classNamePrefix: "AddWatchPage",
  },
);
