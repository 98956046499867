import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  TextField as MUITextField,
  Box,
  Checkbox,
  FormControlLabel,
  Button,
  Badge,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSelector, shallowEqual } from "react-redux";
import { Autocomplete } from "@material-ui/lab";
import { AddOnTheFlyAutoComplete } from "./AddOnTheFlyAutoComplete";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { useFormikContext, Field } from "formik";
import { TextField, CheckboxWithLabel } from "formik-material-ui";
import { WatchFormState } from "../../../state/watchform/state";
import { AppState, WatchSelectors } from "../../../state";
/**
 */
export const Case = (props) => {
  const { classes } = props;
  const {
    values,
    setFieldValue,
    handleChange: formikHandleChange,
  } = useFormikContext<WatchFormState>();

  const [waterResistAtm, setWaterResistAtm] = useState<
    undefined | number | null
  >(values.water_resistance_m);
  const [waterResistM, setWaterResistM] = useState<undefined | number | null>(
    values?.water_resistance_m
      ? // @ts-ignore: Object is possibly 'null'.

        values.water_resistance_m / 10
      : // @ts-ignore: Object is possibly 'null'.

      values?.water_resistance_ft
      ? // @ts-ignore: Object is possibly 'null'.

        values?.water_resistance_ft * 3.3
      : null,
  );
  const [waterResistFt, setWaterResistFt] = useState<undefined | number | null>(
    values.water_resistance_ft || null,
  );
  const [case_shape_category, setCase_shape_category] = useState();
  const [showButtonMaterial, setShowButtonMaterial] = useState(false);
  const handleNext = () => {
    props.setActiveStep(4);
  };
  const {
    case_finish: case_finish_list,
    bezel: bezel_list,
    bezel_material: bezel_material_list,
    bezel_finish: bezel_finish_list,
    bezel_characteristics: bezel_characteristics_list,
    crystal: crystal_list,
    case_shape: case_shape_list,
    case_back: case_back_list,
    material: material_list,
  } = useSelector((state: AppState) => state.watches.watchLists, shallowEqual);

  const handleWaterResistChangeAtm = (event) => {
    const wra = event.target.value;
    setWaterResistAtm(wra || null);
    setWaterResistM(wra * 10 || null);
    setWaterResistFt(wra * 33 || null);
  };
  const handleWaterResistChangeM = (event) => {
    const wrm = event.target.value;
    setWaterResistM(wrm || null);
    setWaterResistAtm(wrm / 10 || null);
    setWaterResistFt(wrm * 3.3 || null);
  };
  const handleWaterResistChangeFt = (event) => {
    const wrf = event.target.value;
    setWaterResistFt(wrf || null);
  };
  const handleWaterResistBlurFt = (event) => {
    if (!waterResistM) {
      const meters = Math.floor(event.target.value / 3.3);
      setWaterResistM(meters || null);
      setWaterResistAtm(meters / 10 || null);
    }
  };

  const handleChange = (event) => {
    setFieldValue("button_notes", "");
    setFieldValue("screw_down_buttons", false);
    setFieldValue("button_material_id", null);
    setShowButtonMaterial(!showButtonMaterial);
  };
  useEffect(() => {
    setFieldValue("water_resistance_ft", waterResistFt);
    setFieldValue("water_resistance_m", waterResistM);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterResistM, waterResistFt]); // removed setFieldValue if causing issues
  const siteId = useSelector((state: AppState) => state.scopes.site_id);

  return (
    <Accordion
      expanded={
        props.activeStep === 3 ||
        props.activeStep === 7 ||
        props.openAccordion.case
      }
      style={{
        width: "100%",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        onClick={() => {
          props.setOpenAccordion(() => ({
            ...props.openAccordion,
            case: !props.openAccordion.case,
          }));
        }}
      >
        <Typography>
          <Badge badgeContent={props.requiredCaseBadge} color="secondary">
            Case Description
          </Badge>
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        className={classes.grid}
        style={{
          display: "grid",
        }}
      >
        <Box
          display="flex"
          flexWrap="wrap"
          flexDirection="column"
          alignItems="left"
        >
          <Box mx={1} display="flex" justifyContent="space-between">
            <AddOnTheFlyAutoComplete
              disabled={siteId !== 0}
              classes={classes}
              setFieldValue={setFieldValue}
              formik={"case_material_id"}
              label={"Case Material"}
              width={"19%"}
              name={"case_material"}
              list={material_list.filter(
                (material: any) =>
                  material.brand_id === values.brand_id ||
                  material.brand_id === null,
              )}
              brandAffiliated={values.brand_id}
            />
            <AddOnTheFlyAutoComplete
              disabled={siteId !== 0}
              classes={classes}
              setFieldValue={setFieldValue}
              formik={"case_finish_id"}
              label={"Case Finish"}
              width={"19%"}
              name={"case_finish"}
              list={case_finish_list}
            />

            <Field
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : values.case_finish_notes
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              onFocus={(event) => {
                event.target.select();
              }}
              component={TextField}
              name="case_finish_notes"
              type="text"
              label="Case Finish Notes"
              style={{ width: "19%" }}
              multiline
            />
            <AddOnTheFlyAutoComplete
              disabled={siteId !== 0}
              classes={classes}
              setFieldValue={setFieldValue}
              formik={"bezel_id"}
              label={"Bezel Type"}
              width={"19%"}
              name={"bezel"}
              list={bezel_list}
            />
            <AddOnTheFlyAutoComplete
              disabled={siteId !== 0}
              classes={classes}
              setFieldValue={setFieldValue}
              formik={"bezel_material_id"}
              label={"Bezel Material"}
              width={"19%"}
              name={"case_material"}
              list={material_list.filter(
                (material: any) =>
                  material.brand_id === values.brand_id ||
                  material.brand_id === null,
              )}
              brandAffiliated={values.brand_id}
            />
          </Box>
          <Box
            mx={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <AddOnTheFlyAutoComplete
              disabled={siteId !== 0}
              classes={classes}
              setFieldValue={setFieldValue}
              formik={"bezel_finish_id"}
              label={"Bezel Finish"}
              width={"19%"}
              name={"case_finish"}
              list={case_finish_list}
            />
            <AddOnTheFlyAutoComplete
              disabled={siteId !== 0}
              classes={classes}
              setFieldValue={setFieldValue}
              formik={"bezel_characteristics_id"}
              label={"Bezel Characteristics"}
              width={"12%"}
              name={"bezel_characteristics"}
              list={bezel_characteristics_list}
            />
            <Field
              className={
                values.bezel_notes ? classes.gridTwoBlue : classes.gridTwo
              }
              onFocus={(event) => {
                event.target.select();
              }}
              component={TextField}
              name="bezel_notes"
              type="text"
              label="Bezel Notes"
              style={{ width: "19%" }}
              multiline
            />
            <AddOnTheFlyAutoComplete
              disabled={siteId !== 0}
              classes={classes}
              setFieldValue={setFieldValue}
              formik={"crown_material_id"}
              label={"Crown Material"}
              width={"19%"}
              name={"case_material"}
              list={material_list
                .filter(
                  (material: any) =>
                    material.brand_id === values.brand_id ||
                    material.brand_id === null,
                )
                .map((i: any) => ({
                  case_material_id: i.case_material_id || null,
                  case_material: i.case_material,
                }))}
              brandAffiliated={values.brand_id}
            />
            <Box style={{ width: "10%" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!values.screw_down_crown}
                    onChange={formikHandleChange}
                    disabled={siteId !== 0}
                    name="screw_down_crown"
                  />
                }
                label="Screw Down Crown"
                labelPlacement="start"
              />
            </Box>
            <Field
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : values.crown_notes
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              disabled={siteId !== 0}
              onFocus={(event) => {
                event.target.select();
              }}
              component={TextField}
              name="crown_notes"
              type="text"
              label="Crown Notes"
              style={{ width: "19%" }}
              multiline
            />
          </Box>
          <Box mx={1} mb={1} display="flex" justifyContent="space-between">
            <AddOnTheFlyAutoComplete
              disabled={siteId !== 0}
              classes={classes}
              setFieldValue={setFieldValue}
              formik={"crystal_id"}
              label={"Crystal"}
              width={"19%"}
              name={"crystal"}
              list={crystal_list}
            />
            <Field
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : values.crystal_notes
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              disabled={siteId !== 0}
              onFocus={(event) => {
                event.target.select();
              }}
              component={TextField}
              name="crystal_notes"
              type="text"
              label="Crystal Notes"
              style={{ width: "19%" }}
              multiline
            />
            <AddOnTheFlyAutoComplete
              disabled={siteId !== 0}
              classes={classes}
              setFieldValue={setFieldValue}
              formik={"case_back_id"}
              label={"Case Back"}
              width={"19%"}
              name={"case_material"}
              list={material_list
                .filter(
                  (material: any) =>
                    material.brand_id === values.brand_id ||
                    material.brand_id === null,
                )
                .map((i: any) => ({
                  case_material_id: i.case_material_id || null,
                  case_material: i.case_material,
                }))}
              brandAffiliated={values.brand_id}
            />
            <Field
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : values.case_back_notes
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              disabled={siteId !== 0}
              onFocus={(event) => {
                event.target.select();
              }}
              component={TextField}
              name="case_back_notes"
              type="text"
              label="Case Back Notes"
              style={{ width: "19%" }}
              multiline
            />
            <Box
              display="flex"
              justifyContent="space-between"
              style={{ width: "22%" }}
            >
              <Autocomplete
                className={
                  siteId !== 0
                    ? classes.gridTwoBlack
                    : values.case_shape_id
                    ? classes.gridTwoBlue
                    : classes.gridTwo
                }
                disabled={siteId !== 0}
                autoSelect
                autoHighlight
                options={case_shape_list.map((i: any) => ({
                  case_shape_id: i.case_shape_id,
                  case_shape: i.case_shape,
                  category: i.category,
                }))}
                getOptionLabel={(option) => option.case_shape || ""}
                onChange={(e, value) => {
                  setFieldValue(`case_shape_id`, value?.case_shape_id || "");
                  setCase_shape_category(value?.category ?? "");
                }}
                style={{ width: "50%" }}
                // renderInput={(params) => {
                //   params.inputProps.value =
                //     case_shape_list
                //       .map((i: any) => ({
                //         case_shape_id: i.case_shape_id,
                //         case_shape: i.case_shape,
                //         category: i.category,
                //       }))
                //       .find((x) => x.case_shape_id === values?.case_shape_id)
                //       ?.case_shape || "";
                //   return <MUITextField {...params} label={"Case Shape"} />;
                // }}
                renderInput={(params) => (
                  <MUITextField {...params} label={"Case Shape"} />
                )}
              />
              {case_shape_category === "Diameter" && (
                <Field
                  className={
                    siteId !== 0
                      ? classes.gridTwoBlack
                      : values.case_width
                      ? classes.gridTwoBlue
                      : classes.gridTwo
                  }
                  disabled={siteId !== 0}
                  onFocus={(event) => {
                    event.target.select();
                  }}
                  component={TextField}
                  name="case_width"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  label={"Diam."}
                  style={{
                    width: "22%",
                  }}
                  multiline
                />
              )}
              {case_shape_category === "Width" && (
                <Field
                  className={
                    siteId !== 0
                      ? classes.gridTwoBlack
                      : values.case_width
                      ? classes.gridTwoBlue
                      : classes.gridTwo
                  }
                  disabled={siteId !== 0}
                  onFocus={(event) => {
                    event.target.select();
                  }}
                  component={TextField}
                  name="case_width"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  label={"Width"}
                  style={{
                    width: "22%",
                  }}
                  multiline
                />
              )}
              {case_shape_category === "Width+Height" && (
                <>
                  <Field
                    className={
                      siteId !== 0
                        ? classes.gridTwoBlack
                        : values.case_width
                        ? classes.gridTwoBlue
                        : classes.gridTwo
                    }
                    disabled={siteId !== 0}
                    onFocus={(event) => {
                      event.target.select();
                    }}
                    component={TextField}
                    name="case_width"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    label="Width"
                    style={{ width: "22%" }}
                    multiline
                  />
                  <Field
                    className={
                      siteId !== 0
                        ? classes.gridTwoBlack
                        : values.case_length
                        ? classes.gridTwoBlue
                        : classes.gridTwo
                    }
                    disabled={siteId !== 0}
                    onFocus={(event) => {
                      event.target.select();
                    }}
                    component={TextField}
                    name="case_length"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    label="Height"
                    style={{ width: "22%" }}
                    multiline
                  />
                </>
              )}
            </Box>
          </Box>

          <Box mt={0.5} mx={1} display="flex" justifyContent="space-between">
            <Field
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : values.height_and_lugs
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              disabled={siteId !== 0}
              onFocus={(event) => {
                event.target.select();
              }}
              component={TextField}
              name="height_and_lugs"
              type="number"
              onWheel={(e) => e.target.blur()}
              label="Height + Lugs"
              style={{ width: "9%" }}
              multiline
            />
            <Field
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : values.case_thickness
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              disabled={siteId !== 0}
              onFocus={(event) => {
                event.target.select();
              }}
              component={TextField}
              name="case_thickness"
              type="number"
              onWheel={(e) => e.target.blur()}
              label="Thickness"
              style={{ width: "6%" }}
              multiline
            />
            <Field
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : values.lug_width
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              disabled={siteId !== 0}
              onFocus={(event) => {
                event.target.select();
              }}
              component={TextField}
              name="lug_width"
              type="number"
              onWheel={(e) => e.target.blur()}
              label="Lug Width"
              style={{ width: "6%" }}
              multiline
            />
            <Field
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : values.dimension_notes
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              disabled={siteId !== 0}
              onFocus={(event) => {
                event.target.select();
              }}
              component={TextField}
              name="dimension_notes"
              type="text"
              label="Dimensions Notes"
              style={{ width: "19%" }}
              multiline
            />
            <MUITextField
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : waterResistAtm
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              onFocus={(event) => {
                event.target.select();
              }}
              disabled={siteId !== 0}
              onChange={handleWaterResistChangeAtm}
              label="ATM"
              value={waterResistAtm || ""}
              style={{ width: "6%" }}
              multiline
            />
            <MUITextField
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : waterResistM
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              onFocus={(event) => {
                event.target.select();
              }}
              disabled={siteId !== 0}
              onChange={handleWaterResistChangeM}
              label="M"
              value={waterResistM || ""}
              style={{ width: "6%" }}
              multiline
            />
            <MUITextField
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : waterResistFt
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              onFocus={(event) => {
                event.target.select();
              }}
              disabled={siteId !== 0}
              onChange={handleWaterResistChangeFt}
              onBlur={handleWaterResistBlurFt}
              label="Ft"
              value={waterResistFt || ""}
              style={{ width: "6%" }}
              multiline
            />
            <Box mb={-3} style={{ width: "11%" }}>
              <Field
                component={CheckboxWithLabel}
                disabled={siteId !== 0}
                id="helium_escape_valve"
                type="checkbox"
                indeterminate={false}
                name="helium_escape_valve"
                Label={{
                  label: "Helium Escape Valve",
                  labelPlacement: "start",
                }}
              />
            </Box>
            <Field
              className={
                siteId !== 0
                  ? classes.helium_escape_valve_notes
                  : values.dimension_notes
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              disabled={siteId !== 0}
              onFocus={(event) => {
                event.target.select();
              }}
              component={TextField}
              name="helium_escape_valve_notes"
              type="text"
              label="Water Resis. Notes"
              style={{ width: "19%" }}
              multiline
            />
            {/* </Box>
          <Box mx={1} display="flex" justifyContent="space-between"> */}
            <Box mb={-3} style={{ width: "10%" }}>
              <FormControlLabel
                control={<Checkbox onChange={handleChange} name="checkedA" />}
                label="Show Button Material"
                labelPlacement="start"
              />
            </Box>
          </Box>

          <Box mx={1} display="flex" justifyContent="space-between">
            <Box
              display="flex"
              justifyContent="space-between"
              width="55%"
              alignItems="center"
            >
              {showButtonMaterial && (
                <>
                  <AddOnTheFlyAutoComplete
                    disabled={siteId !== 0}
                    classes={classes}
                    setFieldValue={setFieldValue}
                    formik={"button_material_id"}
                    label={"Button Material"}
                    width={"33%"}
                    name={"case_material"}
                    list={material_list
                      .filter(
                        (material: any) =>
                          material.brand_id === values.brand_id ||
                          material.brand_id === null,
                      )
                      .map((i: any) => ({
                        case_material_id: i.case_material_id || null,
                        case_material: i.case_material,
                      }))}
                    brandAffiliated={values.brand_id}
                  />
                  <Box style={{ width: "33%" }}>
                    <Field
                      component={CheckboxWithLabel}
                      disabled={siteId !== 0}
                      id="screw_down_buttons"
                      type="checkbox"
                      indeterminate={false}
                      name="screw_down_buttons"
                      Label={{
                        label: "Screw Down Buttons",
                        labelPlacement: "start",
                      }}
                    />
                  </Box>
                  <Field
                    className={
                      siteId !== 0
                        ? classes.gridTwoBlack
                        : values.button_notes
                        ? classes.gridTwoBlue
                        : classes.gridTwo
                    }
                    disabled={siteId !== 0}
                    onFocus={(event) => {
                      event.target.select();
                    }}
                    component={TextField}
                    name="button_notes"
                    type="text"
                    label="Button Notes"
                    multiline
                    style={{ width: "33%" }}
                  />
                </>
              )}
            </Box>
            <Box mt={1}>
              <Button
                color="primary"
                onClick={handleNext}
                className={classes.button}
                endIcon={<ArrowDownwardIcon />}
              >
                Next
              </Button>
            </Box>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
