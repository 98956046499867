import React, { useState } from "react";
import { TextField, Box } from "@material-ui/core";
import { ConfirmButton } from ".";

interface Props {
  attribute: string;
  path?: string;
  rawAttribute: string;
}

export const AddShipping = (props: Props) => {
  const [myValue, setValue] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [bgColor, setBgColor] = useState("");

  return (
    <div style={{ width: "100%" }}>
      <Box
        boxShadow={5}
        display="flex"
        justifyContent="center"
        my={4}
        p={2}
        pt={2}
        bgcolor={bgColor}
      >
        <Box p={1}>
          <TextField
            value={myValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              let myValue: string = e.target.value;
              setValue(myValue);
              setDisabled(false);
              setBgColor("background.paper");
            }}
            label={props.attribute}
          />
        </Box>
        <Box p={1}></Box>
        <Box p={1}>
          <ConfirmButton
            attribute={props.attribute}
            myValue={myValue}
            rawAttribute={props.rawAttribute}
            Add_Edit_Delete={"add new"}
            color={"primary"}
            disabled={disabled}
            path={props.path}
          ></ConfirmButton>
        </Box>
      </Box>
    </div>
  );
};
