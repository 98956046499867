import { useEffect, useState } from "react";
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { useForceUpdate } from "../../../lib";
import { useFormikContext } from "formik";
import { buttonSubmitted } from "../../../lib/helpers";
import { useDispatch } from "../../../state";
import { useSelector, shallowEqual } from "react-redux";
const filter = createFilterOptions({
  matchFrom: "start",
});

export function AddOnTheFlyAutoComplete(props) {
  let forceUpdate = useForceUpdate();
  const {
    errorAlert,
    classes,
    setFieldValue,
    width,
    label,
    list,
    name,
    formik,
    disabled,
  } = props;
  const [open, toggleOpen] = useState(false);
  const { values } = useFormikContext();
  const [listLength, setListLength] = useState(true);
  const [newValue, setNewValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [newFormik, setNewFormik] = useState("");
  const [myBrand, setBrand] = useState("");
  const [myBrandId, setMyBrandId] = useState(Number);
  const [state, setState] = useState(false);

  const handleChange = () => {};
  const { brand: brand_list } = useSelector(
    (state) => state.watches.watchLists,
    shallowEqual,
  );
  const brandListTitle = brand_list.filter(
    (brands) => brands.brand_id === props.brandAffiliated,
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (newFormik) {
      list.push(newFormik);
      setFieldValue(formik, newFormik[`${name}_id`]);
      setNewFormik(null);
      forceUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newFormik]);
  useEffect(() => {
    if (values[`${name}_id`]) {
      forceUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values[`${name}_id`]]);

  const handleClose = () => {
    toggleOpen(false);
  };

  return (
    <>
      <Autocomplete
        className={
          disabled
            ? classes.gridTwoBlack
            : errorAlert
            ? values[formik]
              ? classes.gridTwoBlue
              : classes.gridTwoRed
            : values[formik]
            ? classes.gridTwoBlue
            : classes.gridTwo
        }
        onChange={(e, value) => {
          if (value === null) {
            setFieldValue(formik, null);
            setNewValue("");
            forceUpdate();
          } else if (value?.inputValue) {
            setFieldValue(formik, null);
            setNewValue(value.name);
            setTimeout(() => {
              toggleOpen(true);
            });
            forceUpdate();
          } else if (value[name]) {
            setFieldValue(formik, value[`${name}_id`]);
            forceUpdate();
          }
        }}
        onInputChange={(e, value) => {
          if (e.type !== "blur" && e.type !== "click") {
            if (!value[`${name}_id`]) {
              setFieldValue(formik, null);
              setInputValue(value);
              forceUpdate();
            }
          } else if (e.type === "blur") {
            setInputValue("");
          } else if (e.type === "click") {
            setInputValue(value);
          }
        }}
        multiline
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          setListLength(filtered.length);
          if (params.inputValue !== "") {
            if (
              !options?.find(
                (x) =>
                  x[name].toLowerCase() === params?.inputValue?.toLowerCase(),
              )
            ) {
              filtered.push({
                inputValue: `Add "${params.inputValue}"`,
                name: params.inputValue,
              });
            }
          }
          return filtered;
        }}
        id="add_on_the_fly_autocomplete"
        disabled={disabled}
        options={list || ""}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option[name];
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option[name];
        }}
        clearOnBlur={!values[`${name}_id`]}
        autoSelect={listLength && inputValue.length > 0 ? true : false}
        autoHighlight={listLength && inputValue.length > 0 ? true : false}
        handleHomeEndKeys
        groupBy={(option) => option.firstLetter}
        style={{ width: width }}
        // freeSolo
        //if there are issues about the name staying uncomment freesolo (will remove the dropdown arrow....)
        renderInput={(params) => {
          if (values[formik]) {
            params.inputProps.value =
              list?.find((x) => x[`${name}_id`] === values[formik])?.[name] ||
              "";
            return <TextField {...params} label={label} multiline />;
          } else {
            params.inputProps.value = inputValue || "";
            return <TextField {...params} label={label} multiline />;
          }
        }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="form-dialog-title">
          {"Add " + newValue + " To " + label + "?"}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            id="name"
            value={newValue}
            onChange={(e, value) => setNewValue(e.target.value)}
            label="Name"
            type="text"
            style={{ width: "100%" }}
          />
          {props.brandAffiliated && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.checkedB}
                  onChange={(event, value) => {
                    if (value) {
                      setBrand(brandListTitle[0].brand);
                      setMyBrandId(brandListTitle[0].brand_id);
                    } else {
                      setBrand(null);
                      setMyBrandId(null);
                    }
                  }}
                  color="primary"
                />
              }
              label={`Add affiliation with ${brandListTitle[0]?.brand}?`}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setInputValue("");
              handleClose();
            }}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              buttonSubmitted({
                Add_Edit_Delete: "add",
                rawAttribute: name,
                myValue: newValue,
                attribute: label,
                dispatch,
                siteId: 0,
                setNewFormik: setNewFormik,
                myBrandId: myBrandId,
              });
              handleClose();
            }}
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
