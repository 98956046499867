import React, { useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
// Local
import { useSelector } from "../../state";
import { useMobile } from "../../themes";
import { useStyles } from "./WatchesPage.styles";

function _WatchesPage() {
  //const dispatch = useDispatch();
  // const gridConfig = useSelector(WatchSelectors.gridConfig);
  // const gridData = useSelector(WatchSelectors.gridData);
  const classes = useStyles();

  const { REACT_APP_API_URL } = process.env;
  const token = useSelector((state) => state.auth.token);
  return (
    <Box>
      <Typography variant="h3">Watches Page</Typography>
      <treegrid
        style={{
          maxWidth: 300,
        }}
        is="treegrid"
        debug="check"
        layout_url={`${REACT_APP_API_URL}/grid-layout`}
        layout_Header_Authorization={`Bearer ${token}`}
        data_url={`${REACT_APP_API_URL}/watches-grid`}
        data_Header_Authorization={`Bearer ${token}`}
        upload_url={`${REACT_APP_API_URL}/update-grid`}
        upload_Header_Authorization={`Bearer ${token}`}
        upload_Format="JSON"
      ></treegrid>
    </Box>
  );
}

export const WatchesPage = React.memo(_WatchesPage);
