import React, { useState } from "react";
import { TextField as MUITextField } from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import { useFormikContext } from "formik";

export function CustomAutoComplete(props) {
  const {
    errorAlert,
    classes,
    setFieldValue,
    width,
    label,
    list,
    name,
    formik,
    disabled,
  } = props;
  const { values } = useFormikContext();
  const [autoSelect, setAutoSelect] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const filterOptions = createFilterOptions({
    matchFrom: "start",
  });
  return (
    <Autocomplete
      className={
        disabled
          ? classes.gridTwoBlack
          : errorAlert
          ? values[formik]
            ? classes.gridTwoBlue
            : classes.gridTwoRed
          : values[formik]
          ? classes.gridTwoBlue
          : classes.gridTwo
      }
      disabled={disabled}
      autoSelect={autoSelect && inputValue.length > 0}
      autoHighlight={autoSelect && inputValue.length > 0}
      clearOnBlur={true}
      options={list}
      getOptionLabel={(option) => option[name] || ""}
      getOptionSelected={(option, value) =>
        option[`${name}_id`] === value[`${name}_id`]
      }
      onChange={(e, value) => {
        if (value === null) {
          setFieldValue(formik, null);
        } else {
          setFieldValue(formik, value[`${name}_id`]);
        }
      }}
      onInputChange={(e, value) => {
        setAutoSelect(true);
        setInputValue(value);
        if (list?.find((x) => x[name] === value)) {
          setFieldValue(
            formik,
            list?.find((x) => x[name] === value)[`${name}_id`],
          );
        } else setFieldValue(formik, null);
      }}
      filterOptions={filterOptions}
      style={{ width: width }}
      multiline
      renderInput={(params) => {
        //has to be hardcoded bec status disabled value is 0 :\
        if (values[formik] === 0 && name === "status") {
          params.inputProps.value =
            list?.find((x) => x[`${name}_id`] === 0)?.[name] || "";
          return <MUITextField {...params} label={label} />;
        } else if (values[formik]) {
          params.inputProps.value =
            list?.find((x) => x[`${name}_id`] === values[formik])?.[name] || "";
          return <MUITextField {...params} label={label} />;
        } else {
          return (
            <MUITextField
              multiline
              {...params}
              label={label}
              InputProps={{
                ...params.InputProps,
              }}
            />
          );
        }
      }}
    />
  );
}
