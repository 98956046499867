import { ScopeActions } from "./actions";

export const ScopeState = {
  name: "scopes",
  persist: true,
  defaults: {
    scopes_allowed: [],
    selected_scope: "",
    site_id: -1,
  },
  handlers: {
    [ScopeActions.type.SET_SCOPE](state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    [ScopeActions.type.UPDATE_SCOPE](state, { payload }) {
      return {
        ...state,
        selected_scope: payload.role,
        site_id: payload.id,
      };
    },
  },
};
