import React, { useEffect, useState } from "react";
import { Typography, Box } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid/";
import { WatchActions } from "../../../../state";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { format_Word, getDetailButton, CustomToolbar } from "../../../../lib";
import { AddSiteWatchAttribute } from ".";

export function SiteAttributes(props) {
  const dispatch = useDispatch();
  const {
    pageRoute: {
      params: { attribute },
    },
  } = props;
  const siteId = useSelector((state) => state.scopes.site_id);
  const [attrRows, setAttrRows] = useState([]);
  const attrData = useSelector(
    (state) => state.watches.attributeList,
    shallowEqual,
  );
  useEffect(() => {
    (async function () {
      dispatch(WatchActions.getSiteAttribute(attribute, siteId));
    })();
  }, [attribute,  siteId]);

  useEffect(() => {
    if (attrData[0]?.hasOwnProperty(`${attribute}_id`)) {
      const rows = attrData.map((row) => {
        let id = row[`${attribute}_id`];

        var tableRow = {
          id: id,
          button: row,
          name: row[attribute],
          count: row.count,
        };

        return tableRow;
      });
      setAttrRows(rows);
    }
  }, [attrData, attribute]);
  const attrColumns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    { field: "name", headerName: "Name", flex: 1 },
    {
      field: "count",
      headerName: "Count",
      type: "number",
      flex: 0.4,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "button",
      headerName: "Details",
      flex: 1,
      filterable: false,
      renderCell: (params) => {
        if (attribute === "brand") {
          return getDetailButton(
            "watch-details-seo",
            params.value,
            attribute,
            siteId,
          );
        } else
          return getDetailButton(
            "watch-details",
            params.value,
            attribute,
            siteId,
          );
      },
    },
  ];

  return (
    <Box
      style={{
        width: "100%",
      }}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="h3">{format_Word(attribute)}</Typography>
      </div>
      {siteId === 0 && (
        <AddSiteWatchAttribute
          attribute={format_Word(attribute)}
          rawAttribute={attribute}
          path={"manage-site-attributes"}
        />
      )}
      <div>
        <DataGrid
          rows={attrRows}
          columns={attrColumns}
          disableColumnMenu={true}
          isRowSelectable={false}
          pageSize={100}
          checkboxSelection={false}
          autoHeight={true}
          rowHeight={38}
          components={{
            Toolbar: CustomToolbar,
          }}
          sortModel={[
            {
              field: "name",
              sort: "asc",
            },
          ]}
        />
      </div>
    </Box>
  );
}
