import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid/";
import { useSelector } from "react-redux";
import {
  ButtonLink,
  getSiteSpecificStatus,
  statusColor,
} from "../../../../lib";
import { WatchActions } from "../../../../state";
import { format } from "date-fns";
export function PriceNotSetRecords(props) {
  const siteId = useSelector((state) => state.scopes.site_id);

  const [rows, setRows] = useState([]);
  useEffect(() => {
    (async function () {
      const priceNotSet = await WatchActions.getPriceNotSet(siteId);
      setRows(priceNotSet);
    })();
  }, [siteId]);

  const renameValues = rows?.map((row) => ({
    id: row.item_id,
    model_no: row.model_no,
    date: format(new Date(row.act_date_added), "MMM-do yyyy"),
    status: getSiteSpecificStatus(row, siteId),
    button: row.item_id,
  }));
  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.4,
      headerAlign: "center",
      align: "center",
    },
    { field: "date", headerName: "Date", flex: 0.5 },
    {
      field: "model_no",
      headerName: "Model",
      flex: 2,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.4,
      renderCell: (params) => {
        return statusColor(params.value);
      },
      filterable: false,
    },
    {
      field: "button",
      headerName: "Edit Watch",
      flex: 0.3,
      filterable: false,
      renderCell: (params) => {
        let link = `/edit-watch/${params.value}`;
        return (
          <ButtonLink
            variant="contained"
            color="primary"
            size="small"
            to={link}
          >
            View
          </ButtonLink>
        );
      },
      headerAlign: "right",
      align: "right",
    },
  ];

  return (
    <Box
      style={{
        width: "100%",
      }}
    >
      <div style={{ display: "flex", justifyContent: "center" }}></div>

      <div>
        {rows && (
          <DataGrid
            rows={renameValues}
            columns={columns}
            disableColumnMenu={true}
            isRowSelectable={false}
            pageSize={100}
            checkboxSelection={false}
            autoHeight={true}
            rowHeight={38}
            sortModel={[
              {
                field: "id",
                sort: "desc",
              },
            ]}
          />
        )}
      </div>
    </Box>
  );
}
