import React, { useEffect, useState } from "react";
import { Typography, Box, AppBar, Tabs, Tab } from "@material-ui/core";
import PropTypes from "prop-types";
import { RecentlyAddedRecords } from "./RecentlyAddedRecords";
import { PriceNotSetRecords } from "./PriceNotSetRecords";
import { StatusNotMatch } from "./StatusNotMatch";
import { SpecialsNotMatch } from "./SpecialsNotMatch";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export function RecordsPage(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div style={{ width: "100%" }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Recently Added" {...a11yProps(0)} />
          <Tab label="Prices Not Set" {...a11yProps(1)} />
          <Tab label="Status Does Not Match" {...a11yProps(2)} />
          <Tab label="Specials Does Not Match" {...a11yProps(3)} />
          <Tab label="Recently Changed" {...a11yProps(4)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <RecentlyAddedRecords />
      </TabPanel>
      <TabPanel value={value} index={1}>
       <PriceNotSetRecords/>
      </TabPanel>
      <TabPanel value={value} index={2}>
      <StatusNotMatch/>
      </TabPanel>
      <TabPanel value={value} index={3}>
      <SpecialsNotMatch/>
      </TabPanel>
      <TabPanel value={value} index={4}>
        Item Five
      </TabPanel>
    </div>
  );
}
