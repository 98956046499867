import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Badge,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";
import {
  DataGrid,
  GridToolbarContainer,
  GridFilterToolbarButton,
} from "@material-ui/data-grid/";
import {
  getSiteSpecificPrice,
  getSiteSpecificRetailPrice,
  getSiteSpecificStatus,
  getSiteSpecificDateAdded,
} from "../../../../lib";
import { QuickSearchToolbar, escapeRegExp } from "../../../../lib/utils";
interface Props {
  attribute: string;
  rows: any;
  columns: any;
  rows2: any;
  rows3: any;
  site: any;
  tabTwoName: any;
  tabThreeName: any;
  checked: boolean;
  setChecked: any;
}
function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export const GroupTabs = (props: Props) => {
  const GreenSwitch = withStyles({
    switchBase: {
      color: "#C8C8C8",
      "&$checked": {
        color: "#315179",
      },
      "&$checked + $track": {
        backgroundColor: "#C8C8C8",
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const detailsRows2 = props?.rows2.map((row: any) => {
    return {
      id: row.item_id,
      model: row.model_no,
      title: row.title,
      price: getSiteSpecificPrice(row.price, props.site),
      retail: getSiteSpecificRetailPrice(row, props.site),
      status: getSiteSpecificStatus(row, props.site),
      dateAdded: getSiteSpecificDateAdded(row, props.site),
      buttons: row,
    };
  });
  const detailsRows3 = props?.rows3.map((row: any) => {
    return {
      id: row.item_id,
      model: row.model_no,
      title: row.title,
      price: getSiteSpecificPrice(row.price, props.site),
      retail: getSiteSpecificRetailPrice(row, props.site),
      status: getSiteSpecificStatus(row, props.site),
      dateAdded: getSiteSpecificDateAdded(row, props.site),
      buttons: row,
    };
  });
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const handleChecked = () => {
    if (props.checked) {
      props.setChecked(false);
    } else {
      props.setChecked(true);
    }
  };
  const [searchText1, setSearchText1] = useState("");
  const [rows1, setRows1] = useState(props?.rows);
  const requestSearch = (searchValue: any) => {
    setSearchText1(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = props?.rows.filter((row: any) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field]?.toString());
      });
    });
    setRows1(filteredRows);
  };
  useEffect(() => {
    if (props?.rows?.length) {
      setRows1(props?.rows);
    }
    if (detailsRows2?.length) {
      setRows2(detailsRows2);
    }
    if (detailsRows3?.length) {
      setRows3(detailsRows3);
    }
  }, [props.rows]);
  const [searchText2, setSearchText2] = useState("");
  const [rows2, setRows2] = useState(detailsRows2);
  const requestSearch2 = (searchValue: any) => {
    setSearchText2(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows2 = detailsRows2.filter((row: any) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field]?.toString());
      });
    });
    setRows2(filteredRows2);
  };
  const [searchText3, setSearchText3] = useState("");
  const [rows3, setRows3] = useState(detailsRows3);
  const requestSearch3 = (searchValue: any) => {
    setSearchText3(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows3 = detailsRows3.filter((row: any) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field]?.toString());
      });
    });
    setRows3(filteredRows3);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab
            label={
              <Badge
                badgeContent={props?.rows.length}
                max={999}
                color="secondary"
                showZero
              >
                {props.attribute}
              </Badge>
            }
          />
          <Tab
            label={
              <Badge
                badgeContent={props?.rows2.length}
                max={999}
                color="secondary"
                showZero
              >
                {props.tabTwoName}
              </Badge>
            }
          />
          {props.attribute === "Case Material" && (
            <Tab
              label={
                <Badge
                  badgeContent={props?.rows3.length}
                  max={999}
                  color="secondary"
                  showZero
                >
                  {props.tabThreeName}
                </Badge>
              }
            />
          )}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <FormControlLabel
          control={
            <GreenSwitch
              checked={props.checked}
              onChange={handleChecked}
              name="checked"
              color="primary"
            />
          }
          label={"All Watches"}
        />
        <DataGrid
          rows={rows1}
          columns={props.columns}
          disableColumnMenu={true}
          // isRowSelectable={false}
          autoHeight={true}
          pageSize={100}
          checkboxSelection={false}
          rowHeight={38}
          // components={{
          //   Toolbar: CustomToolbar,
          // }}
          sortModel={[
            {
              field: "title",
              sort: "asc",
            },
          ]}
          components={{ Toolbar: QuickSearchToolbar }}
          componentsProps={{
            toolbar: {
              value: searchText1,
              onChange: (event: any) => {
                requestSearch(event.target.value);
              },
              clearSearch: () => requestSearch(""),
            },
          }}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <FormControlLabel
          control={
            <GreenSwitch
              checked={props.checked}
              onChange={handleChecked}
              name="checked"
              color="primary"
            />
          }
          label={"All Watches"}
        />
        <DataGrid
          rows={rows2}
          columns={props.columns}
          disableColumnMenu={true}
          // isRowSelectable={false}
          autoHeight={true}
          pageSize={100}
          checkboxSelection={false}
          rowHeight={38}
          // components={{
          //   Toolbar: CustomToolbar,
          // }}
          sortModel={[
            {
              field: "title",
              sort: "asc",
            },
          ]}
          components={{ Toolbar: QuickSearchToolbar }}
          componentsProps={{
            toolbar: {
              value: searchText2,
              onChange: (event: any) => {
                requestSearch2(event.target.value);
              },
              clearSearch: () => requestSearch2(""),
            },
          }}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <FormControlLabel
          control={
            <GreenSwitch
              checked={props.checked}
              onChange={handleChecked}
              name="checked"
              color="primary"
            />
          }
          label={"All Watches"}
        />
        <DataGrid
          rows={rows3}
          columns={props.columns}
          disableColumnMenu={true}
          // isRowSelectable={false}
          autoHeight={true}
          pageSize={100}
          checkboxSelection={false}
          rowHeight={38}
          // components={{
          //   Toolbar: CustomToolbar,
          // }}
          sortModel={[
            {
              field: "title",
              sort: "asc",
            },
          ]}
          components={{ Toolbar: QuickSearchToolbar }}
          componentsProps={{
            toolbar: {
              value: searchText3,
              onChange: (event: any) => {
                requestSearch3(event.target.value);
              },
              clearSearch: () => requestSearch3(""),
            },
          }}
        />
      </TabPanel>
    </div>
  );
};
