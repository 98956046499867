import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  TextField as MUITextField,
  Box,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Button,
  IconButton,
  Tooltip,
  DialogContent,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContentText,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { WatchActions } from "../../../state";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSelector, shallowEqual } from "react-redux";
import RestoreIcon from "@material-ui/icons/Restore";
import { Field, useFormikContext, FastField } from "formik";
import { TextField, CheckboxWithLabel } from "formik-material-ui";
import { useForceUpdate } from "../../../lib";
import { authPost, authDelete } from "../../../lib";
import HistoryIcon from "@material-ui/icons/History";

export const Movement = (props) => {
  const siteId = useSelector((state) => state.scopes.site_id);
  const { childCalibreData, setChildCalibreData } = props;
  let forceUpdate = useForceUpdate();
  const { classes } = props;
  const { values, setFieldValue } = useFormikContext();
  const [open, setOpen] = useState(false);
  const [createDialog, setCreateDialog] = useState(true);
  const [canBeDeleted, setCanBeDeleted] = useState(false);
  const [newValue, setNewValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [calibreData, setCalibreData] = useState({});
  const [checkboxes, setCheckboxes] = useState({});
  const [oldCalibre, setOldCalibre] = useState();
  const [newCalibre, setNewCalibre] = useState("");
  const [reserve_hours, set_reserve_hours] = useState("");
  const [reserve_notes, set_reserve_notes] = useState("");
  const [certification_state, set_certification_state] = useState(0);
  const [movement_state, set_movement_state] = useState(0);
  const [base_calibre_state, set_base_calibre_state] = useState(0);
  const [reserve_days, set_reserve_days] = useState("");
  const [vph, set_vph] = useState("");
  const [components, set_components] = useState("");
  const [jewels, set_jewels] = useState("");
  const [in_house, set_in_house] = useState(false);
  const [hz, set_hz] = useState("");
  const [errorDialog, setErrorDialog] = useState(false);

  const handleCalibreChange = (e) => {
    if (values.calibre_id && createDialog) {
      setOpen(true);
      setCreateDialog(false);
    }
  };
  const deepCopy = (v) => JSON.parse(JSON.stringify(v));
  const updateCalibre = (value, revert) => {
    if (value && !revert) {
      setOldCalibre(value);
      (async function () {
        const data = await WatchActions.getMovementDetails(value.calibre_id);
        console.log("data", data);
        if (data.parent_calibre_id) {
          setNewValue(data?.calibre);
          setOldCalibre(
            calibre_list.find((r) => r.calibre_id === data.parent_calibre_id),
          );
          setNewCalibre(value);
          forceUpdate();
        }
        setCalibreData(deepCopy(data));
        let complications = {
          complications: deepCopy(
            data.item_calibre_complications.map(
              (i) => i.calibre_complication_id,
            ),
          ),
        };
        let complication_details = {
          complication_details: deepCopy(
            data.calibre_complication_details.map((i) => ({
              calibre_id: newValue.calibre_id,
              calibre_complication_id: i[`calibre_complication_id`],
              details: i["details"],
            })),
          ),
        };
        let newChildCalibreData = {
          ...deepCopy(data),
          ...complications,
          ...complication_details,
        };
        setChildCalibreData(newChildCalibreData);
        set_jewels(data.jewels || "");
        set_components(data.components || "");
        set_vph(data.vph || "");
        set_reserve_days(data.reserve_days || "");
        set_reserve_hours(data.reserve_hours || "");
        set_hz(data.vph / 7200 || "");
        set_reserve_notes(data.reserve_notes || "");
        set_in_house(!!data.in_house);
        set_certification_state(
          certification_list.find(
            (r) => r.certification_id === data.certification_id,
          ) || "",
        );
        set_base_calibre_state(
          calibre_list.find((r) => r.calibre_id === data.base_calibre) || "",
        );
        set_movement_state(
          movement_list.find((r) => r.movement_id === data.movement) || "",
        );
        setCheckboxes(
          data.item_calibre_complications.reduce((prev, curr) => {
            curr.checked = true;
            curr.calibre_complication_details = data.calibre_complication_details.filter(
              (value) =>
                value.calibre_complication_id === curr.calibre_complication_id,
            );
            curr.calibre_complication_details.map((i) => (i.checked = true));
            prev[curr.calibre_complication_id] = curr;
            return prev;
          }, {}),
        );
      })();
    }
    setFieldValue(`calibre_id`, value ? value[`calibre_id`] : "");
    forceUpdate();
  };
  const handleNext = () => {
    props.setActiveStep(7);
  };
  const handleClose = () => {
    setOpen(false);
  };
  function handleChangeHz(e) {
    set_hz(e.target.value);
    set_vph(e.target.value * 7200);
    if (e.target.value % 0.5 !== 0) {
      setErrorDialog(true);
    } else setErrorDialog(false);
  }
  function handleChangeVph(e) {
    set_vph(e.target.value);
    set_hz(e.target.value / 7200);
    if (e.target.value % 7200 !== 0) {
      setErrorDialog(true);
    } else setErrorDialog(false);
  }

  const {
    certification: certification_list,
    movement: movement_list,
    calibre: calibre_list,
    calibre_complication: calibre_complication_list,
  } = useSelector((state) => state.watches.watchLists, shallowEqual);

  useEffect(() => {
    if (
      !oldCalibre &&
      calibre_list?.find((x) => x?.calibre_id === values.calibre_id)
    ) {
      updateCalibre(
        calibre_list?.find((x) => x?.calibre_id === values.calibre_id),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calibre_list]);
  useEffect(() => {
    if (newCalibre && childCalibreData.calibre_complication_details) {
      console.log(childCalibreData);
      childCalibreData.parent_calibre_id = oldCalibre.calibre_id;
      childCalibreData.calibre_id = newCalibre.calibre_id;
      let complication_details = childCalibreData.calibre_complication_details.map(
        (i) => ({
          calibre_id: newCalibre.calibre_id,
          calibre_complication_id: i[`calibre_complication_id`],
          details: i["details"],
        }),
      );
      childCalibreData.complication_details = complication_details;
      childCalibreData.calibre = newCalibre.calibre;
    } else if (!newCalibre) {
      childCalibreData.parent_calibre_id = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newCalibre]);
  useEffect(() => {
    set_reserve_hours(reserve_days * 24);
  }, [reserve_days]);
  return (
    <Accordion
      expanded={
        props.activeStep === 6 ||
        props.activeStep === 7 ||
        props.openAccordion.movement
      }
      style={{
        width: "100%",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        onClick={() => {
          props.setOpenAccordion(() => ({
            ...props.openAccordion,
            movement: !props.openAccordion.movement,
          }));
        }}
      >
        <Typography>Movement </Typography>
      </AccordionSummary>
      <AccordionDetails
        className={classes.grid}
        style={{
          display: "grid",
        }}
      >
        <Box
          display="flex"
          flexWrap="wrap"
          flexDirection="column"
          alignItems="left"
        >
          <Box
            mb={1}
            ml={1}
            display="flex"
            justifyContent="start"
            style={{ width: "100%" }}
          >
            {!newCalibre ? (
              <Autocomplete
                className={
                  siteId !== 0
                    ? classes.gridTwoBlack
                    : values.calibre_id
                    ? classes.gridTwoBlue
                    : classes.gridTwo
                }
                disabled={siteId !== 0}
                name={"calibre"}
                autoSelect
                autoHighlight
                options={calibre_list
                  ?.filter(
                    (calib) =>
                      calib.brand_id === values.brand_id ||
                      calib.brand_id === null,
                  )
                  .map((i) => ({
                    calibre_id: i[`calibre_id`] || null,
                    calibre: i["calibre"],
                  }))}
                getOptionLabel={(option) => option["calibre"] || ""}
                onChange={(e, value) => {
                  updateCalibre(value);
                  setCreateDialog(true);
                }}
                style={{ width: "12%" }}
                renderInput={(params) => {
                  if (values.calibre_id) {
                    params.inputProps.value =
                      calibre_list?.find(
                        (x) => x?.calibre_id === values.calibre_id,
                      )?.calibre || "Loading...";
                    return <MUITextField {...params} label={"Calibre"} />;
                  } else {
                    params.inputProps.value = inputValue || "";
                    return <MUITextField {...params} label={"Calibre"} />;
                  }
                }}
              />
            ) : (
              <>
                <Box
                  m={1}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Tooltip
                    title={
                      "Revert Back To- " + oldCalibre.calibre || "Loading..."
                    }
                    arrow
                  >
                    <IconButton
                      mr={4}
                      disabled={siteId !== 0}
                      onClick={() => {
                        if (canBeDeleted) {
                          setCanBeDeleted(false);
                          (async function () {
                            const { data, error } = await authDelete(
                              `/calibre-details/${newCalibre.calibre_id}`,
                            );
                            if (error) {
                              alert(
                                "ERROR: could not revert to parent" +
                                  error.message,
                              );
                            }
                          })();
                        }
                        setNewCalibre("");
                        updateCalibre(oldCalibre);
                        setCreateDialog(true);
                        setNewValue("");
                        forceUpdate();
                      }}
                    >
                      <Box pl={1}>
                        <RestoreIcon
                          style={{
                            position: "absolute",
                            left: "0px",
                            top: "0px",
                            fontSize: 40,
                          }}
                        />
                      </Box>
                    </IconButton>
                  </Tooltip>
                  <MUITextField
                    disabled={siteId !== 0}
                    className={
                      siteId !== 0
                        ? classes.gridTwoBlack
                        : newCalibre.calibre
                        ? classes.gridTwoBlue
                        : classes.gridTwo
                    }
                    label={
                      "New Calibre - Sub of- " + oldCalibre.calibre ||
                      "Loading..."
                    }
                    value={newCalibre.calibre}
                    style={{ width: "80%" }}
                    onFocus={(event) => {
                      event.target.select();
                    }}
                  />
                </Box>
              </>
            )}
          </Box>
          <Box mt={1} mx={1} display="flex" justifyContent="space-between">
            <Autocomplete
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : movement_state
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              autoSelect
              autoHighlight
              options={movement_list}
              getOptionLabel={(option) => option.movement}
              onChange={(e, value) => {
                set_movement_state(value);
                childCalibreData.movement_id = value?.movement_id;
                handleCalibreChange();
              }}
              style={{ width: "12%" }}
              disabled={!values.calibre_id || siteId !== 0}
              renderInput={(params) => {
                if (movement_state) {
                  params.inputProps.value =
                    movement_list?.find(
                      (x) => x?.movement_id === movement_state.movement_id,
                    )?.movement || "";
                  return <MUITextField {...params} label={"Movement"} />;
                } else {
                  return (
                    <MUITextField
                      {...params}
                      label={"Movement"}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  );
                }
              }}
            />
            <Box style={{ width: "6%" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={siteId !== 0 || !values.calibre_id}
                    checked={in_house}
                    onChange={() => {
                      childCalibreData.in_house = !in_house;
                      set_in_house(!in_house);
                      handleCalibreChange();
                    }}
                    name="in_house"
                  />
                }
                label="In House"
                labelPlacement="start"
              />
            </Box>

            <MUITextField
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : errorDialog
                  ? classes.gridTwoRed
                  : hz
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              onChange={(e) => {
                handleChangeHz(e);
                handleCalibreChange();
              }}
              onFocus={(event) => {
                event.target.select();
              }}
              label={errorDialog ? "Please enter a full number" : "HZ"}
              value={hz}
              style={{ width: "6%" }}
              multiline
              type="number"
              disabled={!values.calibre_id || siteId !== 0}
            />

            <MUITextField
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : vph
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              onFocus={(event) => {
                event.target.select();
              }}
              onChange={(e) => {
                handleChangeVph(e);
                childCalibreData.vph = e.target.value;
                // handleChangeVph();
                handleCalibreChange();
              }}
              label="VPH"
              value={vph}
              style={{ width: "6%" }}
              multiline
              type="text"
              disabled={!values.calibre_id || siteId !== 0}
            />
            <MUITextField
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : jewels
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              onFocus={(event) => {
                event.target.select();
              }}
              onChange={(e) => {
                set_jewels(e.target.value);
                childCalibreData.jewels = e.target.value;
                handleCalibreChange();
              }}
              label="Jewels"
              value={jewels}
              style={{ width: "6%" }}
              multiline
              type="text"
              disabled={!values.calibre_id || siteId !== 0}
            />
            <MUITextField
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : components
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              onFocus={(event) => {
                event.target.select();
              }}
              onChange={(e) => {
                set_components(e.target.value);
                childCalibreData.components = e.target.value;
                handleCalibreChange();
              }}
              label="Comp."
              value={components}
              style={{ width: "6%" }}
              multiline
              type="text"
              disabled={!values.calibre_id || siteId !== 0}
            />
            <MUITextField
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : reserve_hours
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              onFocus={(event) => {
                event.target.select();
              }}
              onChange={(e) => {
                set_reserve_hours(e.target.value);
                childCalibreData.reserve_hours = e.target.value;
                handleCalibreChange();
              }}
              label="Rsrv Hr"
              value={reserve_hours}
              style={{ width: "6%" }}
              multiline
              type="text"
              disabled={!values.calibre_id || siteId !== 0}
            />
            <MUITextField
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : reserve_days === "1"
                  ? classes.gridTwoRed
                  : reserve_days
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              onFocus={(event) => {
                event.target.select();
              }}
              onChange={(e) => {
                set_reserve_days(e.target.value);
                childCalibreData.reserve_days = e.target.value;
                handleCalibreChange();
              }}
              label="Rsrv Days"
              value={reserve_days}
              style={{ width: "6%" }}
              multiline
              type="text"
              disabled={!values.calibre_id || siteId !== 0}
            />
            <MUITextField
              className={reserve_notes ? classes.gridTwoBlue : classes.gridTwo}
              onChange={(e) => {
                set_reserve_notes(e.target.value);
                childCalibreData.reserve_notes = e.target.value;
                handleCalibreChange();
              }}
              onFocus={(event) => {
                event.target.select();
              }}
              label="Power Reserve Note"
              value={reserve_notes}
              style={{ width: "12%" }}
              multiline
              type="text"
              disabled={!values.calibre_id || siteId !== 0}
            />

            <Autocomplete
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : certification_state
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              autoSelect
              autoHighlight
              options={certification_list}
              getOptionLabel={(option) => option.certification}
              onChange={(e, value) => {
                childCalibreData.certification_id = value?.certification_id;
                set_certification_state(value);
                handleCalibreChange();
              }}
              style={{ width: "12%" }}
              disabled={!values.calibre_id || siteId !== 0}
              renderInput={(params) => {
                if (certification_state) {
                  params.inputProps.value =
                    certification_list?.find(
                      (x) =>
                        x?.certification_id ===
                        certification_state.certification_id,
                    )?.certification || "";
                  return <MUITextField {...params} label={"Certification"} />;
                } else {
                  return (
                    <MUITextField
                      {...params}
                      label={"Certification"}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  );
                }
              }}
            />
            <Autocomplete
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : base_calibre_state
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              autoSelect
              autoHighlight
              options={calibre_list}
              getOptionLabel={(option) => option.calibre}
              onChange={(e, value) => {
                childCalibreData.base_calibre = value?.calibre_id;
                set_base_calibre_state(value);
                handleCalibreChange();
              }}
              style={{ width: "12%" }}
              disabled={!values.calibre_id || siteId !== 0}
              renderInput={(params) => {
                if (base_calibre_state) {
                  params.inputProps.value =
                    calibre_list?.find(
                      (x) => x?.calibre_id === base_calibre_state.calibre_id,
                    )?.calibre || "";
                  return <MUITextField {...params} label={"Base calibre"} />;
                } else {
                  return (
                    <MUITextField
                      {...params}
                      label={"Base calibre"}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  );
                }
              }}
            />
          </Box>
          {!calibreData?.item_calibre_complications && (
            <Box m={1} display="flex" justifyContent="space-between">
              <FormGroup aria-label="position" row>
                {calibre_complication_list?.map((value, index) => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!value.calibre_complication}
                          onChange={() => {
                            handleCalibreChange();
                          }}
                          disabled={!values.calibre_id || siteId !== 0}
                          name="value.calibre_complication_id"
                        />
                      }
                      label={value.calibre_complication}
                    />
                  );
                })}
              </FormGroup>
            </Box>
          )}

          {calibreData?.item_calibre_complications && (
            <>
              <Box m={1} display="flex" justifyContent="space-between">
                <FormGroup aria-label="position" row>
                  {Object.keys(checkboxes).map((value, index) => {
                    const item = checkboxes[value];
                    if (
                      !checkboxes[value].calibre_complication_details.length
                    ) {
                      return (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={item.checked}
                              onChange={(e) => {
                                const i = checkboxes;
                                i[value].checked = e.target.checked;
                                if (!i[value].checked) {
                                  childCalibreData.complications[index] = false;
                                } else {
                                  childCalibreData.complications[
                                    index
                                  ] = parseInt(value);
                                }
                                handleCalibreChange();
                                forceUpdate();
                              }}
                              disabled={!values.calibre_id || siteId !== 0}
                              name="value.calibre_complication_id"
                            />
                          }
                          label={item.calibre_complication}
                        />
                      );
                    }
                  })}
                </FormGroup>
              </Box>
              <Box
                display="flex"
                flexWrap="wrap"
                flexDirection="column"
                alignItems="left"
              >
                {Object.keys(checkboxes).map((value, index) => {
                  const item = checkboxes[value];
                  const details = item.calibre_complication_details;
                  if (details.length) {
                    return (
                      <>
                        <Box
                          pl={1}
                          display="flex"
                          justifyContent="space-between"
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={item.checked}
                                onChange={(e) => {
                                  const i = checkboxes;
                                  i[value].checked = e.target.checked;
                                  if (!i[value].checked) {
                                    childCalibreData.complications[
                                      index
                                    ] = false;
                                  } else {
                                    childCalibreData.complications[
                                      index
                                    ] = parseInt(value);
                                  }

                                  handleCalibreChange();
                                  forceUpdate();
                                }}
                                disabled={!values.calibre_id || siteId !== 0}
                                name="value.calibre_complication_id"
                              />
                            }
                            label={item.calibre_complication}
                          />
                        </Box>
                        <Box pl={5}>
                          {details.map((val, ind) => {
                            return (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={
                                      details[ind].checked && item.checked
                                    }
                                    onChange={(e) => {
                                      console.log("changed");
                                      details[ind].checked = e.target.checked;
                                      if (!details[ind].checked) {
                                        console.log("not checked", ind);
                                        childCalibreData.complication_details.map(
                                          (i) => {
                                            if (
                                              i.details ===
                                              checkboxes[value]
                                                .calibre_complication_details[
                                                ind
                                              ].details
                                            ) {
                                              i.calibre_id = false;
                                            }
                                          },
                                        );
                                      } else {
                                        childCalibreData.complication_details.map(
                                          (i) => {
                                            if (
                                              i.details ===
                                              checkboxes[value]
                                                .calibre_complication_details[
                                                ind
                                              ].details
                                            ) {
                                              i.calibre_id =
                                                newCalibre.calibre_id;
                                            }
                                          },
                                        );
                                      }
                                      handleCalibreChange();
                                      forceUpdate();
                                    }}
                                    disabled={
                                      !values.calibre_id || siteId !== 0
                                    }
                                    name="value.calibre_complication_id"
                                  />
                                }
                                label={details[ind].details}
                              />
                            );
                          })}
                        </Box>
                      </>
                    );
                  }
                })}
                <Button
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                  endIcon={<HistoryIcon />}
                >
                  REVIEW
                </Button>
              </Box>
            </>
          )}
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick
            disableEscapeKeyDown
          >
            <DialogTitle id="alert-dialog-title">
              You are trying to change{newCalibre ? " Sub " : " "}Calibre -{" "}
              {calibre_list?.find((x) => x?.calibre_id === values.calibre_id)
                ?.calibre || "Loading..."}{" "}
              You can't do that here.
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                To create a{newCalibre ? " new " : " "}sub calibre based on the
                main calibre{" "}
                {newCalibre ? `"${oldCalibre.calibre}" ` : "above "}
                enter the new calibre name.
              </DialogContentText>
              <MUITextField
                onFocus={(event) => {
                  event.target.select();
                }}
                margin="dense"
                id="name"
                value={newValue}
                onChange={(e, value) => setNewValue(e.target.value)}
                label="Sub Calibre Name"
                type="text"
                style={{ width: "100%" }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => {
                  setCreateDialog(true);
                  setCanBeDeleted(false);
                  setInputValue("");
                  setNewValue("");
                  updateCalibre(
                    calibre_list?.find(
                      (x) => x?.calibre_id === values.calibre_id,
                    ),
                  );
                  handleClose();
                }}
                color="secondary"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setCanBeDeleted(true);
                  (async function () {
                    const { data, error } = await authPost(
                      `/details-value?field=calibre`,
                      {
                        name: newValue,
                        // brand: ??,
                        // site: ??,
                      },
                    );
                    if (error) {
                      alert("ERROR: " + error.message);
                    } else {
                      handleClose();
                      setNewCalibre(data);
                      setFieldValue(`calibre_id`, data.calibre_id);
                    }
                  })();
                }}
                color="primary"
              >
                Continue
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
