import React, { useState } from "react";
import {
  Button,
  Chip,
  InputAdornment,
  Box,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  GridToolbarContainer,
  GridFilterToolbarButton,
} from "@material-ui/data-grid/";
import { format } from "date-fns";
import { useDispatch, WatchActions, WatchFormActions } from "../state";
import { ButtonLink } from ".";

export const moneyFormatter = (number, site) => {
  try {
    switch (site) {
      case 0:
        return usFormatter.format(number);
      case 1:
        return ukFormatter.format(number);
      case 2:
        return usFormatter.format(number);
    }
  } catch (error) {}
};
export const moneyFormatter2 = (number, site) => {
  if (number > 0) {
    try {
      switch (site) {
        case 0:
          return usFormatter.format(number);
        case 1:
          return ukFormatter.format(number);
        case 2:
          return usFormatter.format(number);
      }
    } catch (error) {}
  }
};

const usFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
const ukFormatter = new Intl.NumberFormat("en-UK", {
  style: "currency",
  currency: "GBP",
});

/**
 * different data depending on the site being used
 * @param {*} row
 * @param {*} site
 * @returns
 */
export const getSiteSpecificPrice = (row, site) => {
  try {
    switch (site) {
      case 0:
        return row;
      case 1:
        return row;
      case 2:
        return row;
    }
  } catch (error) {}
};
/**
 *
 * @param {*} row
 * @param {*} site
 * @returns
 */
export const getSiteSpecificRetailPrice = (row, site) => {
  try {
    switch (site) {
      case 0:
        return row.pt_site_fields.price_retail;
      case 1:
        return row.tws_site_fields.price_retail;
      case 2:
        return row.tpc_site_fields.price_retail;
    }
  } catch (error) {}
};
export const getSiteSpecificStatus = (row, site) => {
  try {
    switch (site) {
      case 0:
        return statusFormat(row.pt_site_fields.status_id);
      case 1:
        return statusFormat(row.tws_site_fields.status_id);
      case 2:
        return statusFormat(row.tpc_site_fields.status_id);
    }
  } catch (error) {}
};
export const getSiteSpecificSpecials = (row, site) => {
  try {
    switch (site) {
      case 0:
        return statusFormat(row.pt_site_fields.price_special);
      case 1:
        return statusFormat(row.tws_site_fields.price_special);
      case 2:
        return statusFormat(row.tpc_site_fields.price_special);
    }
  } catch (error) {}
};
export const getSiteSpecific_price_our = (row, site) => {
  try {
    switch (site) {
      case 0:
        return statusFormat(row.pt_site_fields.price_our);
      case 1:
        return statusFormat(row.tws_site_fields.price_our);
      case 2:
        return statusFormat(row.tpc_site_fields.price_our);
    }
  } catch (error) {}
};

export const getSiteSpecificDateAdded = (row, site) => {
  try {
    switch (site) {
      case 0:
        if (row.pt_site_fields.date_added) {
          return format(new Date(row.pt_site_fields.date_added), "yyyy/MM/dd");
        }
        break;
      case 1:
        if (row.tws_site_fields.date_added) {
          return format(new Date(row.tws_site_fields.date_added), "yyyy/MM/dd");
        }
        break;
      case 2:
        if (row.tpc_site_fields.date_added) {
          return format(new Date(row.tpc_site_fields.date_added), "yyyy/MM/dd");
        }
        break;
    }
  } catch (error) {}
};

function statusFormat(status) {
  switch (status) {
    case 0:
      return "Disabled";
    case 1:
      return "Active";
    case 2:
      return "Discontinued";
    case 3:
      return "Backordered";
    case 4:
      return "Hidden";
    case 5:
      return "Coming Soon";
    default:
      break;
  }
  return status;
}
export function statusColor(status) {
  switch (status) {
    case "Active":
      return <Typography style={{ color: "#4caf50" }}>{status}</Typography>;
    case "Backordered":
      return <Typography style={{ color: "#9c27b0" }}>{status}</Typography>;

    case "Coming Soon":
      return <Typography style={{ color: "#795548" }}>{status}</Typography>;
    case "Disabled":
      return <Typography style={{ color: "#f44336" }}>{status}</Typography>;
    case "Discontinued":
      return <Typography style={{ color: "#2196f3" }}>{status}</Typography>;
    case "Hidden":
      return <Typography style={{ color: "#000000" }}>{status}</Typography>;
  }
}
export function get_price_our(price, site) {
  try {
    switch (site) {
      case 0:
        return usFormatter.format(price);
      case 1:
        return ukFormatter.format(price);
      case 2:
        return usFormatter.format(price);
    }
  } catch (error) {}
}
export const getLink = (row) => {
  switch (row.link) {
    case "Stub not saved":
      return row.title;
    default:
      return (
        <a target="_blank" href={row.link} rel="noreferrer">
          {row.title}
        </a>
      );
  }
};

export const buttonSubmitted = ({
  Add_Edit_Delete,
  rawAttribute,
  myValue,
  myBrandId,
  id,
  attribute,
  dispatch,
  siteId,
  shipData,
  setNewFormik,
}) => {
  if (Add_Edit_Delete === "add") {
    dispatch(
      WatchActions.addDetails({
        rawAttribute,
        myValue,
        myBrandId,
        attribute,
        siteId,
        setNewFormik,
      }),
    );
  } else if (Add_Edit_Delete === "delete") {
    dispatch(
      WatchActions.deleteDetails({
        rawAttribute,
        id,
        myValue,
        attribute,
        siteId,
      }),
    );
  } else if (shipData) {
    dispatch(
      WatchActions.shipDetails({
        id,
        shipData,
        siteId,
        myValue,
      }),
    );
  } else if (shipData) {
    dispatch(
      WatchActions.shipDetails({
        id,
        shipData,
        siteId,
        myValue,
      }),
    );
  } else if (Add_Edit_Delete === "edit") {
    dispatch(
      WatchActions.editDetails({
        rawAttribute,
        id,
        myValue,
        myBrandId,
        attribute,
        siteId,
      }),
    );
  } else if (Add_Edit_Delete === "add new") {
    dispatch(
      WatchActions.addShipping({
        myValue,
        siteId,
        attribute: undefined, //What is this
      }),
    );
  } else if (Add_Edit_Delete === "add new") {
    dispatch(
      WatchActions.addShipping({
        myValue,
        siteId,
        attribute: undefined, //What is this -shua
      }),
    );
  } else {
    return alert(
      "Error something went wrong: " +
        attribute +
        " - " +
        myValue +
        " was NOT saved",
    );
  }
};

export const GridButtons = (row) => {
  const dispatch = useDispatch();
  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size="small"
        target="_blank"
        href={row.admin_link}
        onClick={() => dispatch(WatchFormActions.getWatchForm(row.item_id))}
      >
        Watch
      </Button>
      {row.link === "Stub not saved" ? (
        <Button size="small" style={{ marginLeft: 16 }} disabled={true}>
          Website
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{ marginLeft: 16 }}
          target="_blank"
          href={row.link}
          disabled={false}
        >
          Website
        </Button>
      )}
    </div>
  );
};
export const getShipAddBox = (row, shippingData, setShippingData) => {
  return (
    <Box mt={1.5}>
      <TextField
        type="number"
        label="Additional Amount"
        id="standard-start-adornment"
        size="medium"
        defaultValue={row.item_ship_tier.additional_amount}
        onClick={(e) => {
          if (row.item_ship_tier.additional_amount === null) {
            row.item_ship_tier.additional_amount = !null;
          }
        }}
        onChange={(e) => {
          let index = shippingData.data.findIndex(
            (x) => x.ship_rate_id === row.ship_rate_id,
          );
          if (index >= 0) {
            shippingData.data[index].additional_amount = e.target.value;
          } else {
            shippingData.data.push({
              ship_rate_id: row.item_ship_tier.ship_rate_id,
              ship_tier_id: row.item_ship_tier.ship_tier_id,
              additional_amount: e.target.value,
            });
          }
          setShippingData(shippingData);
        }}
        InputProps={
          row.item_ship_tier.additional_amount
            ? {
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }
            : undefined
        }
      />
    </Box>
  );
};
export const defaultRate = (
  row,
  defaultShippingData,
  setDefaultShippingData,
) => {
  return (
    <Box mt={1.5}>
      <TextField
        type="number"
        label="Default Rate"
        id="standard-start-adornment"
        size="medium"
        defaultValue={row.rate}
        onChange={(e) => {
          let index = defaultShippingData.findIndex(
            (x) => x.ship_rate_id === row.ship_rate_id,
          );
          if (index >= 0) {
            defaultShippingData[index].rate = e.target.value;
          } else {
            defaultShippingData.push({
              ship_rate_id: row.ship_rate_id,
              rate: e.target.value,
            });
          }
          setDefaultShippingData(defaultShippingData);
        }}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />
    </Box>
  );
};
export const defaultLow = (
  row,
  defaultShippingData,
  setDefaultShippingData,
) => {
  if (row.country !== "US") {
    return (
      <Box mt={1.5}>
        <TextField
          type="number"
          label="Default Price Low"
          id="standard-start-adornment"
          size="medium"
          defaultValue={row.price_low}
          onClick={(e) => {
            if (row.price_low === null) {
              row.price_low = !null;
            }
          }}
          onChange={(e) => {
            let index = defaultShippingData.findIndex(
              (x) => x.ship_rate_id === row.ship_rate_id,
            );
            if (index >= 0) {
              defaultShippingData[index].price_low = e.target.value;
            } else {
              defaultShippingData.push({
                ship_rate_id: row.ship_rate_id,
                price_low: e.target.value,
              });
            }
            setDefaultShippingData(defaultShippingData);
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Box>
    );
  } else return " ";
};
export const defaultHigh = (
  row,
  defaultShippingData,
  setDefaultShippingData,
) => {
  if (row.country !== "US") {
    return (
      <Box mt={1.5}>
        <TextField
          type="number"
          label="Default Price High"
          id="standard-start-adornment"
          size="medium"
          defaultValue={row.price_high}
          onClick={(e) => {
            if (row.price_high === null) {
              row.price_high = !null;
            }
          }}
          onChange={(e) => {
            let index = defaultShippingData.findIndex(
              (x) => x.ship_rate_id === row.ship_rate_id,
            );
            if (index >= 0) {
              defaultShippingData[index].price_high = e.target.value;
            } else {
              defaultShippingData.push({
                ship_rate_id: row.ship_rate_id,
                price_high: e.target.value,
              });
            }
            setDefaultShippingData(defaultShippingData);
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Box>
    );
  } else return " ";
};
export const getDetailButton = (path, row, attribute, siteID) => {
  let link = "";
  if (attribute === "calibre_complication") {
    link = `/${path}/${attribute}/${row[attribute]}/${row[`${attribute}_id`]}`;
  } else if (row.item_brand?.brand === undefined) {
    link = `/${path}/${attribute}/${row[attribute]}/${row[`${attribute}_id`]}`;
  } else {
    link = `/${path}/${attribute}/${row[attribute]}/${row.item_brand?.brand}/${
      row[`${attribute}_id`]
    }`;
  }
  return (
    <ButtonLink variant="contained" color="primary" size="small" to={link}>
      View
    </ButtonLink>
  );
};
export const getMovementButton = (path, row, attribute, siteID) => {
  let link = "";
  if (row.item_brand?.brand === undefined) {
    link = `/${path}/${attribute}/${row["calibre"]}/${row[`${attribute}_id`]}`;
  } else {
    link = `/${path}/${attribute}/${row["calibre"]}/${row.item_brand?.brand}/${
      row[`${attribute}_id`]
    }`;
  }
  return (
    <ButtonLink variant="contained" color="primary" size="small" to={link}>
      View
    </ButtonLink>
  );
};

export const showDisabled = (value) => {
  if (value) {
    return <Chip label="Hidden" disabled />;
  } else return <Chip label="Visible" color="primary" />;
};
export const showBrandsStatus = (value) => {
  if (value) {
    return <Chip label="Active" color="primary" />;
  } else return <Chip label="Inactive" disabled />;
};
/**
 * Formats words to remove _ Underscore And Capitalize First Letter
 * @param {*} word
 * @returns
 */
export function format_Word(word) {
  word = word.replace(/_/g, " ");
  return word.replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
}
export function format_Attribute(word) {
  return word.slice(0, word.length - 6);
}
export function addS(word) {
  if (word.slice(-1) === "s") {
    return word;
  } else {
    return word + "s";
  }
}
export function item_format_Attribute(word) {
  if (word === "series_group") {
    return "item_sery";
  } else return "item_" + word.slice(0, word.length - 6);
}

export function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}
export function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}
export function union(a, b) {
  return [...a, ...not(b, a)];
}

export function useForceUpdate() {
  let [value, setState] = useState(true);
  return () => setState(!value);
}

export function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridFilterToolbarButton />
    </GridToolbarContainer>
  );
}
