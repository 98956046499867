import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  theme => ({
   
  }),
  {
    classNamePrefix: "WatchesPage",
  },
);
