import React, { useEffect, useState } from "react";
import { Typography, Box } from "@material-ui/core";
import {
  DataGrid,
  GridToolbarContainer,
  GridFilterToolbarButton,
} from "@material-ui/data-grid/";
import { WatchActions } from "../../../../state";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { format_Word, getMovementButton, showDisabled } from "../../../../lib";
import { AddWatchAttribute } from ".";
import { QuickSearchToolbar, escapeRegExp } from "../../../../lib/utils";
export function MovementAttributes(props) {
  const dispatch = useDispatch();
  const {
    pageRoute: {
      params: { attribute },
    },
  } = props;
  const siteId = useSelector((state) => state.scopes.site_id);
  const [hide, setHide] = useState(false);
  const [attrRows, setAttrRows] = useState([]);
  const [brandAffiliated, setBrandAffiliated] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState(attrRows);
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = attrRows.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field]?.toString());
      });
    });
    setRows(filteredRows);
  };
  useEffect(() => {
    if (attrRows?.length) {
      setRows(attrRows);
    }
  }, [attrRows]);
  const attrData = useSelector(
    (state) => state.watches.attributeList,
    shallowEqual,
  );
  useEffect(() => {
    (async function () {
      await dispatch(WatchActions.getAttribute(attribute, null, siteId));
    })();
  }, [attribute, siteId]);
  useEffect(() => {
    setBrandAffiliated(
      attrData[0]?.hasOwnProperty("brand_id") &&
        !attrData[0]?.hasOwnProperty("show_brand_page"),
    );

    if (attrData[0]?.hasOwnProperty(`calibre_id`)) {
      const rows = attrData.map((row) => {
        let id = row[`calibre_id`];

        var tableRow = {
          id: id,
          button: row,
          name: row["calibre"],
          count: row.count,

          brand: null,
        };

        if (brandAffiliated) {
          if (row.item_brand) {
            tableRow.brand = row.item_brand.brand;
          }
        }

        return tableRow;
      });
      setAttrRows(rows);
    }
  }, [attrData, attribute, brandAffiliated]);
  const attrColumns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    { field: "name", headerName: "Name", flex: 1 },
    {
      field: "count",
      headerName: "Count",
      type: "number",
      flex: 0.4,
      headerAlign: "left",
      align: "left",
    },

    {
      field: "brand",
      headerName: "Brand Affiliation",
      flex: 1,
      hide: !brandAffiliated,
      filterable: brandAffiliated,
    },

    {
      field: "button",
      headerName: "Details",
      flex: 1,
      hide: hide,
      filterable: false,
      renderCell: (params) => {
        return getMovementButton(
          "movement-details",
          params.value,
          "calibre",
          brandAffiliated,
          siteId,
        );
      },
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridFilterToolbarButton />
      </GridToolbarContainer>
    );
  }

  return (
    <Box
      style={{
        width: "100%",
      }}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="h3">Movement</Typography>
      </div>
      <AddWatchAttribute
        attribute={format_Word(attribute)}
        rawAttribute={attribute}
        brandAffiliated={brandAffiliated}
        path={"movement-attribute"}
      />
      <div>
        <DataGrid
          rows={rows}
          columns={attrColumns}
          disableColumnMenu={true}
          isRowSelectable={false}
          pageSize={100}
          checkboxSelection={false}
          autoHeight={true}
          rowHeight={38}
          sortModel={[
            {
              field: "name",
              sort: "asc",
            },
          ]}
          components={{ Toolbar: QuickSearchToolbar }}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event) => {
                requestSearch(event.target.value);
              },
              clearSearch: () => requestSearch(""),
            },
          }}
        />
      </div>
    </Box>
  );
}
