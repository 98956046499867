import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Checkbox,
  CardHeader,
  Grid,
  Divider,
  Card,
  List,
  ListItem,
} from "@material-ui/core";
import { useStyles } from "./WatchAttributes.styles";
import { not, intersection, union } from "../../../../lib";
import { GroupListItems } from "./GroupListItems";
import { SortGrid } from "./SortGrid";
import { MoveButtons } from "./MoveButtons";

export const GroupTransferBox = (props) => {
  const siteId = useSelector((state) => state.scopes.site_id);
  const numberOfChecked = (items) => intersection(props.checked, items)?.length;
  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items?.length) {
      props.setChecked(not(props.checked, items));
    } else {
      props.setChecked(union(props.checked, items));
    }
  };

  return (
    <div>
      <Card>
        <Grid container>
          <CardHeader
            avatar={
              <Checkbox
                onClick={handleToggleAll(props.items)}
                checked={
                  numberOfChecked(props.items) === props.items?.length &&
                  props.items?.length !== 0
                }
                indeterminate={
                  numberOfChecked(props.items) !== props.items?.length &&
                  numberOfChecked(props.items) !== 0
                }
                disabled={props.items?.length === 0 || siteId !== 0}
                inputProps={{ "aria-label": "all items selected" }}
              />
            }
            title={props.title}
            subheader={`${numberOfChecked(props.items)}/${
              props.items?.length
            } selected`}
          />
          <Grid item alignItems="stretch" style={{ display: "flex" }}>
            {props.sort && (
              <SortGrid
                id={props.id}
                setList={props.setList}
                list={props.list}
                copy={props.copy}
                filterTitle={props.filterTitle}
              />
            )}
            {props.moveList && props.attribute === "series_group" && (
              <MoveButtons
                rightChecked={props.rightChecked}
                handleCheckedUp={props.handleCheckedUp}
                handleCheckedDown={props.handleCheckedDown}
                disableUpArrow={props.disableUpArrow}
                disableDownArrow={props.disableDownArrow}
                setDisableUpArrow={props.setDisableUpArrow}
                setDisableDownArrow={props.setDisableDownArrow}
                selected={props.selected}
                right={props.right}
              />
            )}
          </Grid>
        </Grid>
        <Divider />
        <List>
          {props.items && (
            <GroupListItems
              items={props.items}
              checked={props.checked}
              setChecked={props.setChecked}
              fullRight={props.fullRight}
              attribute={props.attribute}
            />
          )}
          <ListItem />
        </List>
      </Card>
    </div>
  );
};
