import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import {
  Box,
  Checkbox,
  LinearProgress,
  TextField as TxtField,
  Button,
  Chip,
  Avatar,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { WatchActions, useDispatch } from "../../../../state";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";
import { useSelector } from "react-redux";

interface Props {
  brand_id: number;
  aka_rule: any;
  brand: any;
  stub: any;
  sites_router_metum: any;
  custom_aka: boolean;
  can_delete: boolean;
  deactivate_brand: boolean;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export function BrandDetails(props: Props) {
  const GreenSwitch = withStyles({
    switchBase: {
      color: "#C8C8C8",
      "&$checked": {
        color: "#315179",
      },
      "&$checked + $track": {
        backgroundColor: "#C8C8C8",
      },
    },
    checked: {},
    track: {},
  })(Switch);
  const dispatch = useDispatch();
  const siteId = useSelector((state: any) => state.scopes.site_id);

  const handleChecked = () => {
    if (checked) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  };
  function selectFunction(value: any) {
    return props.aka_rule?.strip_chars?.includes(value);
  }
  const _removeChars = {
    Dash: {
      title: "Dash",
      value: "45",
      select: selectFunction("45"),
      icon: "-",
    },
    Space: {
      title: "Space",
      value: "32",
      select: selectFunction("32"),
      icon: " ",
    },
    Period: {
      title: "Period",
      value: "46",
      select: selectFunction("46"),
      icon: ".",
    },
    Underscore: {
      title: "Underscore",
      value: "95",
      select: selectFunction("95"),
      icon: "_",
    },
    Backslash: {
      title: "Backslash",
      value: "92",
      select: selectFunction("92"),
      icon: "\\",
    },
    "Forward Slash": {
      title: "Forward Slash",
      value: "47",
      select: selectFunction("47"),
      icon: "/",
    },
    "All Characters from space": {
      title: "All Characters from space",
      value: "allCharsFromSpace",
      select: selectFunction("allCharsFromSpace"),
      icon: " xx",
    },
  };
  const [checked, setChecked] = useState(props.deactivate_brand);
  const [isDisabled, setIsDisabled] = useState(true);
  const [bgColor, setBgColor] = useState();
  const [removeChars, setRemoveChars] = useState(_removeChars);

  return (
    <div style={{ width: "100%" }}>
      <Formik
        initialValues={{
          ...props,
        }}
        onSubmit={(values) => {
          if (!values.aka_rule && values.custom_aka) {
            values.aka_rule = {};
          }
          if (values.custom_aka) {
            values.aka_rule.strip_chars = Object.values(removeChars)
              .filter((i) => i.select)
              .map((x) => x.value);
          }
          dispatch(
            WatchActions.editBrand(
              `/brand-details/${props.brand_id}?site=${siteId}`,
              values,
              checked,
            ),
          );
        }}
      >
        {({ submitForm, isSubmitting, values }) => (
          <Form>
            {props.brand && (
              <Box
                boxShadow={5}
                display="flex"
                flexWrap="wrap"
                flexDirection="column"
                alignItems="left"
                my={4}
                p={2}
                pt={2}
                bgcolor={bgColor}
                onChange={(e) => {
                  setIsDisabled(false);
                }}
              >
                <Box m={1} display="flex" justifyContent="space-between">
                  <Field
                    component={TextField}
                    name="brand"
                    label="Brand Name"
                    style={{ width: "30%" }}
                    disabled={siteId !== 0}
                  />
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    indeterminate={false}
                    name="hide"
                    Label={{ label: "Hide Brand" }}
                  />
                  <Field
                    component={TextField}
                    name="brand_page_name"
                    label="Brand Page URL"
                    style={{ width: "29%" }}
                  />
                  <Field
                    component={TextField}
                    name="page_title"
                    label="Brand Main Title"
                    style={{ width: "29%" }}
                  />
                </Box>
                <Box m={1}>
                  <Field
                    multiline={true}
                    fullWidth={true}
                    component={TextField}
                    name="meta_description"
                    label="Brand Main Meta Description"
                  />
                </Box>
                <Box m={1}>
                  <Field
                    multiline={true}
                    fullWidth={true}
                    component={TextField}
                    name="meta_keywords"
                    label="Brand Main Meta Keywords"
                  />
                </Box>
                <Box m={1}>
                  <Field
                    multiline={true}
                    fullWidth={true}
                    component={TextField}
                    name="meta_headers"
                    label="Brand Main Other SEO"
                  />
                </Box>

                <Box>
                  <Box m={1} display="flex" justifyContent="space-between">
                    <Field
                      component={TextField}
                      name="stub"
                      label="Brand Items URL"
                      style={{ width: "46%" }}
                    />
                    {values.stub && (
                      <Field
                        component={TextField}
                        name="sites_router_metum.title"
                        label="Brand Items Title"
                        style={{ width: "53%" }}
                      />
                    )}
                  </Box>
                  <Box m={1}>
                    {values.stub && (
                      <Field
                        multiline={true}
                        fullWidth={true}
                        component={TextField}
                        name="sites_router_metum.description"
                        label="Brand Items Meta Description"
                      />
                    )}
                  </Box>

                  <Box m={1}>
                    {values.stub && (
                      <Field
                        multiline={true}
                        fullWidth={true}
                        component={TextField}
                        name="sites_router_metum.keywords"
                        label="Brand Items Meta Keywords"
                      />
                    )}
                  </Box>
                  <Box m={1}>
                    {values.stub && (
                      <Field
                        multiline={true}
                        fullWidth={true}
                        component={TextField}
                        name="sites_router_metum.headers"
                        label="Brand Items Other SEO"
                      />
                    )}
                  </Box>
                </Box>
                <Box ml={1} justifyContent="space-between">
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    indeterminate={false}
                    name="custom_aka"
                    Label={{ label: "Custom AKA" }}
                    disabled={siteId !== 0}
                  />
                  {values.custom_aka && (
                    <Box display="flex" justifyContent="space-between">
                      <Autocomplete
                        debug={true}
                        disabled={siteId !== 0}
                        size="small"
                        multiple={true}
                        id="checkboxes-tags-demo"
                        options={Object.values(removeChars)}
                        disableCloseOnSelect
                        getOptionLabel={(option: any) => option.title}
                        getOptionSelected={(option: any) => option.select}
                        value={Object.values(removeChars).filter(
                          (i) => i.select,
                        )}
                        renderOption={(option: {
                          title: string;
                          value: any;
                          select: boolean;
                        }) => (
                          <Box
                            style={{
                              display: "flex",
                              width: "100%",
                              alignItems: "center",
                            }}
                            onClick={() => {
                              const tmp: any = { ...removeChars };
                              tmp[option.title].select = !tmp[option.title]
                                .select;
                              setRemoveChars(tmp);
                            }}
                          >
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={option.select}
                              onChange={(e) => {
                                const tmp: any = { ...removeChars };
                                tmp[option.title].select = e.target.checked;
                                setRemoveChars(tmp);
                              }}
                            />
                            <Box>{option.title}</Box>
                          </Box>
                        )}
                        style={{ width: "46%" }}
                        renderInput={(params) => {
                          const { InputProps, ...rest } = params;
                          return (
                            <TxtField
                              {...rest}
                              variant="outlined"
                              label="Remove Characters"
                              InputProps={{
                                ...InputProps,
                                startAdornment: Object.values(removeChars)
                                  .filter((i) => i.select)
                                  .map((item: any) => (
                                    <Chip
                                      size="small"
                                      avatar={<Avatar>{item.icon}</Avatar>}
                                      variant="outlined"
                                      onDelete={() => {
                                        const tmp: any = { ...removeChars };
                                        tmp[item.title].select = false;
                                        setRemoveChars(tmp);
                                      }}
                                      label={item.title}
                                    />
                                  )),
                              }}
                            />
                          );
                        }}
                      />

                      <Field
                        component={TextField}
                        name="aka_rule.prefix"
                        label="Prefix"
                        style={{ width: "26%" }}
                        disabled={siteId !== 0}
                      />
                      <Field
                        component={TextField}
                        name="aka_rule.suffix"
                        label="Suffix"
                        style={{ width: "26%" }}
                        disabled={siteId !== 0}
                      />
                    </Box>
                  )}
                </Box>
                <Box m={1} display="flex" justifyContent="space-between">
                  {isSubmitting && <LinearProgress />}
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isDisabled}
                    onClick={submitForm}
                    component={Link}
                    to={`/watch-attribute-affiliated/brand`}
                  >
                    Edit Brands
                  </Button>
                  <FormControlLabel
                    control={
                      <GreenSwitch
                        checked={checked}
                        onChange={handleChecked}
                        name="checked"
                      />
                    }
                    label={"Deactivate Brand"}
                  />
                </Box>
              </Box>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}
