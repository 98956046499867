import { authGet, authPut, authPost, authDelete } from "../../lib";
import { UIActions } from "../ui/actions";
import { format } from "date-fns";
// import { UIActions } from "../ui/actions";

const type = {
  GET_WATCH_LISTS: "GET_WATCH_LISTS",
  GET_ATTRIBUTES_LISTS: "GET_ATTRIBUTES_LISTS",
  GET_WATCH_LIST: "GET_WATCH_LIST",
  ADD_TO_WATCH_LIST: "ADD_TO_WATCH_LIST",
};

export const WatchActions = {
  type,

  getAllLists() {
    return async (dispatch, getState) => {
      const response = await authGet("/get-lists");
      const { data, error } = response;
      if (error) {
        return; // TODO
      }
      dispatch({
        type: type.GET_WATCH_LISTS,
        payload: data,
      });
    };
  },
  getList(listTypes = []) {
    return async (dispatch, getState) => {
      const response = await authGet(["/get-lists", { listTypes }]);
      const { data, error } = response;
      if (error) {
        return; // TODO
      }
      dispatch({
        type: type.GET_WATCH_LIST,
        payload: data,
      });
    };
  },
  getAttribute(attribute, brand_id, siteId) {
    return async (dispatch, getState) => {
      let apiCall = {};
      if (attribute === "series") {
        apiCall = { site: siteId, brand_id: brand_id };
      } else {
        apiCall = { site: siteId };
      }
      const response = await authGet([
        `/manage-attributes/${attribute}`,
        apiCall,
      ]);
      // const response = await authGet([`/manage-attributes/${attribute}`, { site: siteId}]);
      const { data, error } = response;
      if (error) {
        return; // TODO
      }
      dispatch({
        type: type.GET_ATTRIBUTES_LISTS,
        payload: data,
      });
      return data;
    };
  },
  getSiteAttribute(attribute, siteId) {
    return async (dispatch, getState) => {
      const response = await authGet([
        `/manage-site-attributes/${attribute}?site=${siteId}`,
      ]);
      const { data, error } = response;
      if (error) {
        return; // TODO
      }
      dispatch({
        type: type.GET_ATTRIBUTES_LISTS,
        payload: data,
      });
      return data;
    };
  },
  getShippingAttribute(siteId) {
    return async (dispatch, getState) => {
      const response = await authGet([`/manage-shipping-tiers?site=${siteId}`]);
      const { data, error } = response;
      if (error) {
        return; // TODO
      }
      dispatch({
        type: type.GET_ATTRIBUTES_LISTS,
        payload: data,
      });
      return data;
    };
  },
  getAttributeGroups(attribute, siteId) {
    return async (dispatch, getState) => {
      const response = await authGet([
        `/manage-attribute-groups/${attribute}`,
        { site: siteId },
      ]);
      const { data, error } = response;
      if (error) {
        return; // TODO
      }
      dispatch({
        type: type.GET_ATTRIBUTES_LISTS,
        payload: data,
      });
      return data;
    };
  },

  async getDetails(field, id, site, all) {
    const response = await authGet([
      `/details-watches`,
      { field: field, id: id, all: all, site: site },
    ]);
    const { data, error } = response;
    if (error) {
      return; // TODO
    }
    return data;
  },
  async getHistory(id, siteId) {
    const response = await authGet([`/item-history/${id}?site=${siteId}`]);
    const { data, error } = response;
    if (error) {
      return; // TODO
    }
    return data;
  },
  async getRecentHistory(site) {
    const response = await authGet([`records/recentlyAdded?site=${site}`]);
    const { data, error } = response;
    if (error) {
      return; // TODO
    }
    return data;
  },
  async getPriceNotSet(site) {
    const response = await authGet([`records/noPrice?site=${site}`]);
    const { data, error } = response;
    if (error) {
      return; // TODO
    }
    return data;
  },
  async getStatusNotMatch(site) {
    const response = await authGet([`records/status?site=${site}`]);
    const { data, error } = response;
    if (error) {
      return; // TODO
    }
    return data;
  },
  async getSpecialsNotMatch(site) {
    const response = await authGet([`records/specials?site=${site}`]);
    const { data, error } = response;
    if (error) {
      return; // TODO
    }
    return data;
  },
  async getShippingDetails(id, site) {
    const response = await authGet([
      `/shipping-details-values/${id}?site=${site}`,
    ]);
    const { data, error } = response;
    if (error) {
      return; // TODO
    }
    return data;
  },
  async getDefaultShippingDetails(site) {
    const response = await authGet([`/shipping-details-defaults?site=${site}`]);
    const { data, error } = response;
    if (error) {
      return; // TODO
    }
    return data;
  },

  async getDetailsGroups(field, id, site, isAGroup, all) {
    const response = await authGet([
      `/details-groups`,
      { field: field, id: id, all: all, site: site },
    ]);
    const { data, error } = response;
    if (error) {
      return; // TODO
    }
    return data;
  },

  async getDetailsValue(field, id, isAGroup, siteId) {
    const url = isAGroup ? `/group-details-value` : `/details-value`;
    const response = await authGet(
      `${url}/${id}?field=${field}&site=${siteId}`,
    );
    const { data, error } = response;
    if (error) {
      return; // TODO
    }
    return data;
  },
  async getMovementDetails(id) {
    const response = await authGet(`calibre-details/${id}`);
    const { data, error } = response;
    if (error) {
      return; // TODO
    }
    return data;
  },
  async getRegex(id) {
    const response = await authGet(`brand-regex?brand_id=${id}`);
    const { data, error } = response;
    if (error) {
      return; // TODO
    }
    return data;
  },

  addDetails({
    rawAttribute,
    myValue,
    myBrandId,
    attribute,
    siteId,
    setNewFormik,
  }) {
    return async (dispatch, getState) => {
      let values = {
        name: myValue,
        brand_id: myBrandId,
        site_id: siteId,
      };

      const { data, error } = await authPost(
        `/details-value?field=${rawAttribute}&site=${siteId}`,
        values,
      );
      if (setNewFormik) {
        setNewFormik(data);
      }

      if (error) {
        dispatch(
          UIActions.showError(
            error.message + ". " + myValue + " was not saved.",
          ),
        );
      } else {
        // const { scopes: { site_id } = {} } = getState();
        if (
          rawAttribute === "warranty" ||
          rawAttribute === "vendor" ||
          rawAttribute === "delivery"
        ) {
          dispatch(WatchActions.getSiteAttribute(rawAttribute, siteId));
        } else if (
          rawAttribute.slice(-6) === "groups" ||
          rawAttribute.slice(-5) === "group"
        ) {
          dispatch(WatchActions.getAttributeGroups(rawAttribute, null, siteId));
        } else if (rawAttribute === "brand") {
          dispatch(WatchActions.getAttribute(rawAttribute, null, siteId));
        } else if (rawAttribute === "brand") {
          dispatch(WatchActions.getAttribute(rawAttribute, null, siteId));
        } else {
          dispatch({
            type: type.ADD_TO_WATCH_LIST,
            payload: data,
          });
          dispatch(WatchActions.getAttribute(rawAttribute, null, siteId));
        }

        dispatch(
          UIActions.showUpdated(
            attribute + " - " + myValue + " was successfully added",
          ),
        );
      }
    };
  },
  addShipping({ myValue, attribute, siteId }) {
    return async (dispatch, getState) => {
      const { data, error } = await authPost(
        `manage-shipping-tiers?site=${siteId}`,
        {
          name: myValue,
        },
      );
      if (error) {
        dispatch(
          UIActions.showError(
            error.message + ". " + myValue + " was not saved.",
          ),
        );
      } else {
        // const { scopes: { site_id } = {} } = getState();
        dispatch(WatchActions.getShippingAttribute(siteId));

        dispatch({
          type: type.ADD_TO_WATCH_LIST,
          payload: data,
        });
        dispatch(
          UIActions.showUpdated(
            attribute + " - " + myValue + " was successfully added",
          ),
        );
      }
    };
  },

  editBrand(key, groupDetailsData, deactivate_brand) {
    return async (dispatch, getState) => {
      const { data, error } = await authPut(key, {
        brand: groupDetailsData.brand,
        hide: groupDetailsData.hide,
        deactivate_brand: deactivate_brand,
        brand_page_name: groupDetailsData.brand_page_name,
        page_title: groupDetailsData.page_title,
        meta_description: groupDetailsData.meta_description,
        meta_keywords: groupDetailsData.meta_keywords,
        meta_headers: groupDetailsData.meta_headers,
        stub: groupDetailsData.stub,
        title: groupDetailsData.sites_router_metum?.title,
        description: groupDetailsData.sites_router_metum?.description,
        keywords: groupDetailsData.sites_router_metum?.keywords,
        headers: groupDetailsData.sites_router_metum?.headers,
        custom_aka: groupDetailsData.custom_aka,
        prefix: groupDetailsData.aka_rule?.prefix,
        suffix: groupDetailsData.aka_rule?.suffix,
        strip_chars: groupDetailsData.aka_rule?.strip_chars,
      });

      if (error) {
        dispatch(
          UIActions.showError(error.message + ". Brand SEO  was not saved."),
        );
      }
      const { scopes: { site_id } = {} } = getState();
      dispatch(
        UIActions.showUpdated("Success:Brand SEO was successfully updated"),
      );
    };
  },
  editMovement(key, movementData) {
    return async (dispatch, getState) => {
      const { data, error } = await authPut(key, movementData);
      if (error) {
        dispatch(
          UIActions.showError(
            error.message + ". Movement details  was not saved.",
          ),
        );
      }
      const { scopes: { site_id } = {} } = getState();
      dispatch(WatchActions.getAttribute("calibre", null, site_id));
      dispatch(
        UIActions.showUpdated("Success: movement was successfully updated"),
      );
    };
  },
  editSeries(key, groupDetailsData, myBrandId) {
    return async (dispatch, getState) => {
      const { data, error } = await authPut(key, {
        name: groupDetailsData.series,
        brand_id: myBrandId,
        stub: groupDetailsData.stub,
        title: groupDetailsData.sites_router_metum?.title,
        description: groupDetailsData.sites_router_metum?.description,
        keywords: groupDetailsData.sites_router_metum?.keywords,
        headers: groupDetailsData.sites_router_metum?.headers,
      });
      if (error) {
        dispatch(
          UIActions.showError(error.message + ". Brand SEO  was not saved."),
        );
      }
      const { scopes: { site_id } = {} } = getState();
      dispatch(WatchActions.getAttribute("series", null, site_id));
      dispatch(
        UIActions.showUpdated("Success: Series SEO was successfully updated"),
      );
    };
  },

  editWatch(key, watchValues) {
    console.log("EDIT-", watchValues);
    console.log("before-", watchValues.vendor_exp);
    watchValues.vendor_exp
      ? (watchValues.vendor_exp = format(
          new Date(watchValues.vendor_exp),
          "yyyy-MM-dd",
        ))
      : (watchValues.vendor_exp = null);
    console.log("after-", watchValues.vendor_exp);
    const restructuredWatchValues = {
      date_added: watchValues.date_added,
      band_color_id: watchValues.band_color_id,
      band_material_id: watchValues.band_material_id,
      band_name_id: watchValues.band_name_id,
      band_notes: watchValues.band_notes,
      bezel_characteristics_id: watchValues.bezel_characteristics_id,
      bezel_finish_id: watchValues.bezel_finish_id,
      bezel_id: watchValues.bezel_id,
      bezel_material_id: watchValues.bezel_material_id,
      bezel_notes: watchValues.bezel_notes,
      brand_id: watchValues.brand_id,
      button_material_id: parseInt(watchValues.button_material_id),
      button_notes: watchValues.button_notes,
      calibre_id: watchValues.calibre_id,
      case_back_id: watchValues.case_back_id,
      case_back_notes: watchValues.case_back_notes,
      case_finish_id: parseInt(watchValues.case_finish_id),
      case_finish_notes: watchValues.case_finish_notes,
      case_length: parseInt(watchValues.case_length),
      case_material_id: watchValues.case_material_id,
      case_shape_id: watchValues.case_shape_id,
      case_thickness: parseInt(watchValues.case_thickness),
      case_width: parseInt(watchValues.case_width),
      clasp_characteristic_id: watchValues.clasp_characteristic_id,
      clasp_name_id: watchValues.clasp_name_id,
      clasp_notes: watchValues.clasp_notes,
      clasp_type_id: watchValues.clasp_type_id,
      crown_material_id: parseInt(watchValues.crown_material_id),
      crown_notes: watchValues.crown_notes,
      crystal_id: watchValues.crystal_id,
      crystal_notes: watchValues.crystal_notes,
      date_display: watchValues.date_display || false,
      date_reduced: watchValues.date_reduced,
      date_notes: watchValues.date_notes,
      delivery_id: watchValues.delivery_id,
      description: watchValues.description,
      dial_color_id: watchValues.dial_color_id,
      dial_finish_id: parseInt(watchValues.dial_finish_id),
      dial_flange_color_id: watchValues.dial_flange_color_id,
      dimension_notes: watchValues.dimension_notes,
      edition_notes: watchValues.edition_notes,
      flange_indication_id: watchValues.flange_indication_id,
      gtin: watchValues.gtin,
      hands_finish_id: watchValues.hands_finish_id,
      hands_luminescence: watchValues.hands_luminescence || false,
      hands_luminescence_notes: watchValues.hands_luminescence_notes,
      hands_material_id: watchValues.hands_material_id,
      hands_notes: watchValues.hands_notes,
      height_and_lugs: parseInt(watchValues.height_and_lugs),
      helium_escape_valve: watchValues.helium_escape_valve || false,
      helium_escape_valve_notes: watchValues.helium_escape_valve_notes,
      hour_marker_color_id: watchValues.hour_marker_color_id,
      hour_marker_luminescence: watchValues.hour_marker_luminescence || false,
      hour_marker_luminescence_notes:
        watchValues.hour_marker_luminescence_notes,
      hour_marker_notes: watchValues.hour_marker_notes,
      hour_marker_type_id: watchValues.hour_marker_type_id,
      item_additional_band_refs: watchValues.item_additional_band_refs,
      limited_edition: watchValues.limited_edition || false,
      limited_quantity: parseInt(watchValues.limited_quantity),
      lug_width: parseInt(watchValues.lug_width),
      luminescence_id: watchValues.luminescence_id,
      luminescence_notes: watchValues.luminescence_notes,
      main_dial_notes: watchValues.main_dial_notes,
      model_aka: watchValues.model_aka,
      model_no: watchValues.model_no,
      model_no_2: watchValues.model_no_2,
      numbered_edition: watchValues.numbered_edition || false,
      papers_included: watchValues.papers_included || false,
      price_on_special: watchValues.price_on_special || false,
      price_our:
        parseInt(watchValues.price_our?.toString().replace(/,/g, "")) || 0,
      price_retail:
        parseInt(watchValues.price_retail?.toString().replace(/,/g, "")) || 0,
      price_special:
        parseInt(watchValues.price_special?.toString().replace(/,/g, "")) || 0,
      retired: watchValues.retired || false,
      screw_down_buttons: watchValues.screw_down_buttons || false,
      screw_down_crown: watchValues.screw_down_crown || false,
      second_hand_id: watchValues.second_hand_id,
      second_hand_luminescence: watchValues.second_hand_luminescence || false,
      second_hand_material_id: watchValues.second_hand_material_id,
      second_hand_notes: watchValues.second_hand_notes,
      seo_stub: watchValues.seo_stub,
      series_id: watchValues.series_id,
      ship_tier_id: watchValues.ship_tier_id,
      show_recent: watchValues.show_recent,
      show_reduced: watchValues.show_reduced,
      size_id: watchValues.size_id,
      special_edition: watchValues.special_edition || false,
      status_id: watchValues.status_id,
      subdial_color_id: parseInt(watchValues.subdial_color_id),
      subdial_notes: watchValues.subdial_notes,
      title: watchValues.title,
      track_notes: watchValues.track_notes,
      track_type_id: watchValues.track_type_id,
      vendor_id: watchValues.vendor_id,
      vendor_exp: watchValues.vendor_exp,
      warranty_id: watchValues.warranty_id,
      water_resistance_m: parseInt(watchValues.water_resistance_m),
      water_resistance_ft: parseInt(watchValues.water_resistance_ft),
    };
    return async (dispatch, getState) => {
      const { data, error } = await authPut(key, restructuredWatchValues);
      if (error) {
        dispatch(
          UIActions.showError(error.message + ". Watch  was not Edited."),
        );
      } else {
        const { scopes: { site_id } = {} } = getState();
        dispatch(
          UIActions.showUpdated(restructuredWatchValues.title + ` Saved!  `),
        );

        return data;
      }
    };
  },
  addWatch(key, watchValues, duplicate) {
    console.log("ADD-", watchValues);
    watchValues.vendor_exp
      ? (watchValues.vendor_exp = format(
          new Date(watchValues.vendor_exp),
          "yyyy-MM-dd",
        ))
      : (watchValues.vendor_exp = null);
    const restructuredWatchValues = {
      date_added: watchValues.date_added,
      band_color_id: watchValues.band_color_id,
      band_material_id: watchValues.band_material_id,
      band_name_id: watchValues.band_name_id,
      band_notes: watchValues.band_notes,
      bezel_characteristics_id: watchValues.bezel_characteristics_id,
      bezel_finish_id: watchValues.bezel_finish_id,
      bezel_id: watchValues.bezel_id,
      bezel_material_id: watchValues.bezel_material_id,
      bezel_notes: watchValues.bezel_notes,
      brand_id: watchValues.brand_id,
      button_material_id: parseInt(watchValues.button_material_id),
      button_notes: watchValues.button_notes,
      calibre_id: watchValues.calibre_id,
      case_back_id: watchValues.case_back_id,
      case_back_notes: watchValues.case_back_notes,
      case_finish_id: parseInt(watchValues.case_finish_id),
      case_finish_notes: watchValues.case_finish_notes,
      case_length: parseInt(watchValues.case_length),
      case_material_id: watchValues.case_material_id,
      case_shape_id: watchValues.case_shape_id,
      case_thickness: parseInt(watchValues.case_thickness),
      case_width: parseInt(watchValues.case_width),
      clasp_characteristic_id: watchValues.clasp_characteristic_id,
      clasp_name_id: watchValues.clasp_name_id,
      clasp_notes: watchValues.clasp_notes,
      clasp_type_id: watchValues.clasp_type_id,
      crown_material_id: parseInt(watchValues.crown_material_id),
      crown_notes: watchValues.crown_notes,
      crystal_id: watchValues.crystal_id,
      crystal_notes: watchValues.crystal_notes,
      date_display: watchValues.date_display || false,
      date_reduced: watchValues.date_reduced,
      date_notes: watchValues.date_notes,
      delivery_id: watchValues.delivery_id,
      description: watchValues.description,
      dial_color_id: watchValues.dial_color_id,
      dial_finish_id: parseInt(watchValues.dial_finish_id),
      dial_flange_color_id: watchValues.dial_flange_color_id,
      dimension_notes: watchValues.dimension_notes,
      edition_notes: watchValues.edition_notes,
      flange_indication_id: watchValues.flange_indication_id,
      gtin: watchValues.gtin,
      hands_finish_id: watchValues.hands_finish_id,
      hands_luminescence: watchValues.hands_luminescence || false,
      hands_luminescence_notes: watchValues.hands_luminescence_notes,
      hands_material_id: watchValues.hands_material_id,
      hands_notes: watchValues.hands_notes,
      height_and_lugs: parseInt(watchValues.height_and_lugs),
      helium_escape_valve: watchValues.helium_escape_valve || false,
      helium_escape_valve_notes: watchValues.helium_escape_valve_notes,
      hour_marker_color_id: watchValues.hour_marker_color_id,
      hour_marker_luminescence: watchValues.hour_marker_luminescence || false,
      hour_marker_luminescence_notes:
        watchValues.hour_marker_luminescence_notes,
      hour_marker_notes: watchValues.hour_marker_notes,
      hour_marker_type_id: watchValues.hour_marker_type_id,
      item_additional_band_refs: watchValues.item_additional_band_refs,
      limited_edition: watchValues.limited_edition || false,
      limited_quantity: parseInt(watchValues.limited_quantity),
      lug_width: parseInt(watchValues.lug_width),
      luminescence_id: watchValues.luminescence_id,
      luminescence_notes: watchValues.luminescence_notes,
      main_dial_notes: watchValues.main_dial_notes,
      model_aka: watchValues.model_aka,
      model_no: watchValues.model_no,
      model_no_2: watchValues.model_no_2,
      numbered_edition: watchValues.numbered_edition || false,
      papers_included: watchValues.papers_included || false,
      price_on_special: watchValues.price_on_special || false,
      price_our:
        parseInt(watchValues.price_our?.toString().replace(/,/g, "")) || 0,
      price_retail:
        parseInt(watchValues.price_retail?.toString().replace(/,/g, "")) || 0,
      price_special:
        parseInt(watchValues.price_special?.toString().replace(/,/g, "")) || 0,
      retired: watchValues.retired || false,
      screw_down_buttons: watchValues.screw_down_buttons || false,
      screw_down_crown: !!watchValues.screw_down_crown,
      second_hand_id: watchValues.second_hand_id,
      second_hand_luminescence: watchValues.second_hand_luminescence || false,
      second_hand_material_id: watchValues.second_hand_material_id,
      second_hand_notes: watchValues.second_hand_notes,
      seo_stub: watchValues.seo_stub,
      series_id: watchValues.series_id,
      ship_tier_id: watchValues.ship_tier_id,
      show_recent: watchValues.show_recent,
      show_reduced: watchValues.show_reduced,
      size_id: watchValues.size_id,
      special_edition: watchValues.special_edition || false,
      status_id: watchValues.status_id,
      subdial_color_id: parseInt(watchValues.subdial_color_id),
      subdial_notes: watchValues.subdial_notes,
      title: watchValues.title,
      track_notes: watchValues.track_notes,
      track_type_id: watchValues.track_type_id,
      vendor_id: watchValues.vendor_id,
      vendor_exp: watchValues.vendor_exp,
      warranty_id: watchValues.warranty_id,
      water_resistance_m: parseInt(watchValues.water_resistance_m),
      water_resistance_ft: parseInt(watchValues.water_resistance_ft),
    };
    return async (dispatch, getState) => {
      const { data, error } = await authPost(key, restructuredWatchValues);
      if (error) {
        dispatch(
          UIActions.showError(error.message + ". Watch  was not Added."),
        );
      } else {
        const { scopes: { site_id } = {} } = getState();
        dispatch(
          UIActions.showUpdated(restructuredWatchValues.title + " Added"),
        );
        return data;
      }
    };
  },
  addImage(key, imageArray) {
    console.log("ADD Image-", imageArray);
    return async (dispatch, getState) => {
      const { data, error } = await authPost(key, imageArray);
      if (error) {
        dispatch(
          UIActions.showError(error.message + ". Image  was not Added."),
        );
      } else {
        const { scopes: { site_id } = {} } = getState();
        dispatch(UIActions.showUpdated("Image Added"));
        return data;
      }
    };
  },
  editDetails({ rawAttribute, id, myValue, myBrandId, attribute, siteId }) {
    return async (dispatch, getState) => {
      const { data, error } = await authPut(
        `/details-value/${id}?field=${rawAttribute}&site=${siteId}`,
        {
          name: myValue,
          brand_id: myBrandId,
          site_id: siteId,
        },
      );
      if (error) {
        dispatch(
          UIActions.showError(
            error.message + ". " + myValue + " was not saved.",
          ),
        );
      }
      // const { scopes: { site_id } = {} } = getState();
      // dispatch(WatchActions.getAttribute(rawAttribute, id, site_id));
      if (
        rawAttribute === "warranty" ||
        rawAttribute === "vendor" ||
        rawAttribute === "delivery"
      ) {
        dispatch(WatchActions.getSiteAttribute(rawAttribute, siteId));
      } else if (
        rawAttribute.slice(-6) === "groups" ||
        rawAttribute.slice(-5) === "group"
      ) {
        dispatch(WatchActions.getAttributeGroups(rawAttribute, siteId));
      } else dispatch(WatchActions.getAttribute(rawAttribute, id, siteId));
      dispatch(
        UIActions.showUpdated(
          attribute + " - " + myValue + "was successfully updated",
        ),
      );
    };
  },
  shipDetails({ id, shipData, siteId, myValue }) {
    return async (dispatch, getState) => {
      if (myValue === "ship_tier") {
        const { data, error } = await authPut(
          `/shipping-details-defaults?site=${siteId}`,
          shipData,
        );
        if (error) {
          dispatch(
            UIActions.showError(error.message + ". Ship Tier was not saved."),
          );
        }
      } else {
        const { data, error } = await authPut(
          `/shipping-details-values/${id}?site=${siteId}`,
          {
            ship_tier_id: id,
            ship_tier: myValue,
            data: shipData.data,
          },
        );
        if (error) {
          dispatch(
            UIActions.showError(error.message + ". Ship Tier was not saved."),
          );
        }
      }
      dispatch(WatchActions.getShippingAttribute(siteId));
      dispatch(
        UIActions.showUpdated("Success: Ship Tier was successfully updated"),
      );
    };
  },
  saveDetails({ groupDetailsData, right, key, attribute }) {
    return async (dispatch, getState) => {
      const { data, error } = await authPut(key, {
        name: groupDetailsData?.[attribute],
        brand_id: groupDetailsData?.brand_id,
        selected_list: right,
        stub: groupDetailsData?.stub,
        title: groupDetailsData?.sites_router_metum?.title,
        description: groupDetailsData?.sites_router_metum?.description,
        keywords: groupDetailsData?.sites_router_metum?.keywords,
        headers: groupDetailsData?.sites_router_metum?.headers,
      });
      if (error) {
        dispatch(
          UIActions.showError(error.message + ".  + newName +  was not saved."),
        );
      }
      const { scopes: { site_id } = {} } = getState();
      dispatch(WatchActions.getAttributeGroups(attribute));
      dispatch(
        UIActions.showUpdated(
          "Success: Series Groups SEO was successfully updated",
        ),
      );
    };
  },

  deleteDetails({ rawAttribute, id, myValue, attribute, siteId }) {
    return async (dispatch, getState) => {
      if (
        rawAttribute.slice(-6) === "groups" ||
        rawAttribute.slice(-5) === "group"
      ) {
        const { data, error } = await authDelete(
          `/group-details-value/${id}?field=${rawAttribute}&site=${siteId}`,
        );
        if (error) {
          dispatch(
            UIActions.showError(
              error.message + ". " + myValue + " was not saved.",
            ),
          );
        }
      } else if (rawAttribute === "calibre") {
        const { data, error } = await authDelete(`/calibre-details/${id}`);
        if (error) {
          dispatch(UIActions.showError("ERROR calibre Could not be deleted ."));
        }
      } else if (rawAttribute === "ship_tier") {
        const { data, error } = await authDelete(
          `shipping-details-values/${id}`, //missing site
        );
        if (error) {
          dispatch(
            UIActions.showError("ERROR Ship Tier could not be deleted ."),
          );
        }
      } else {
        const { data, error } = await authDelete(
          `/details-value/${id}?field=${rawAttribute}&site=${siteId}`,
        );
        if (error) {
          dispatch(
            UIActions.showError(
              error.message + ". " + myValue + " was not deleted.",
            ),
          );
        }
      }

      // const { scopes: { site_id } = {} } = getState();
      if (
        rawAttribute === "warranty" ||
        rawAttribute === "vendor" ||
        rawAttribute === "delivery"
      ) {
        dispatch(WatchActions.getSiteAttribute(rawAttribute, siteId));
      } else if (
        rawAttribute.slice(-6) === "groups" ||
        rawAttribute.slice(-5) === "group"
      ) {
        dispatch(WatchActions.getAttributeGroups(rawAttribute, siteId));
      } else {
        dispatch(WatchActions.getAttribute(rawAttribute, null, siteId));
      }
      dispatch(
        UIActions.showUpdated(
          attribute + " - " + myValue + " was successfully deleted",
        ),
      );
    };
  },
  addUser(form) {
    return async (dispatch, getState) => {
      const { data, error } = await authPost(`/auth/user`, {
        first_name: form.first_name,
        last_name: form.last_name,
        role_id: form.role_id,
        email: form.email,
      });
      if (error) {
        dispatch(UIActions.showError(error.message + ". User was not added."));
      } else {
        window.location.reload(true);
        dispatch(
          UIActions.showUpdated(
            "Success a email has been sent to " + form.email,
          ),
        );
      }
    };
  },
  deleteUser(id) {
    let object_id = { id: id };
    return async (dispatch, getState) => {
      const { data, error } = await authDelete(`/auth/user/${id}`);
      if (error) {
        dispatch(
          UIActions.showError(error.message + ". User was not deleted."),
        );
      } else {
        window.location.reload(true);
        dispatch(
          UIActions.showUpdated("Success User was successfully deleted"),
        );
      }
    };
  },
};
