import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Box,
  Button,
  Badge,
} from "@material-ui/core";
import { CustomAutoComplete } from "./CustomAutoComplete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSelector, shallowEqual } from "react-redux";
import { AddOnTheFlyAutoComplete } from "./AddOnTheFlyAutoComplete";
import { useFormikContext, Field } from "formik";
import { TextField, CheckboxWithLabel } from "formik-material-ui";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

export const Dial = (props) => {
  const siteId = useSelector((state) => state.scopes.site_id);
  const { classes } = props;
  const { values, setFieldValue } = useFormikContext();
  const handleNext = () => {
    props.setActiveStep(5);
  };
  const {
    dial_color: dial_color_list,
    subdial_color: subdial_color_list,
    hour_marker_color: hour_marker_color_list,
    dial_finish: dial_finish_list,
    hands_finish: hands_finish_list,
    second_hand: second_hand_list,
    track_type: track_type_list,
    hands_material: hands_material_list,
    flange_indication: flange_indication_list,
    // date_type: date_type_list,
    date_location: date_location_list,
  } = useSelector((state) => state.watches.watchLists, shallowEqual);

  return (
    <Accordion
      expanded={
        props.activeStep === 4 ||
        props.activeStep === 7 ||
        props.openAccordion.dial
      }
      style={{
        width: "100%",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        onClick={() => {
          props.setOpenAccordion(() => ({
            ...props.openAccordion,
            dial: !props.openAccordion.dial,
          }));
        }}
        className={classes.expanded}
      >
        <Typography>
          <Badge badgeContent={props.requiredDialBadge} color="secondary">
            Dial Description
          </Badge>
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        className={classes.grid}
        style={{
          display: "grid",
        }}
      >
        <Box
          display="flex"
          flexWrap="wrap"
          flexDirection="column"
          alignItems="left"
        >
          <Box m={1} display="flex" justifyContent="space-between">
            <AddOnTheFlyAutoComplete
              classes={classes}
              disabled={siteId !== 0}
              setFieldValue={setFieldValue}
              formik={"dial_color_id"}
              label={"Main Dial Color"}
              width={"17%"}
              name={"dial_color"}
              list={dial_color_list}
            />
            <AddOnTheFlyAutoComplete
              classes={classes}
              disabled={siteId !== 0}
              setFieldValue={setFieldValue}
              label={"Dial Finish"}
              name={"dial_finish"}
              formik={"dial_finish_id"}
              list={dial_finish_list}
              width={"10%"}
            />
            <Field
              disabled={siteId !== 0}
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : values.main_dial_notes
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              component={TextField}
              onFocus={(event) => {
                event.target.select();
              }}
              name="main_dial_notes"
              type="text"
              label="Dial Notes"
              style={{ width: "16%" }}
              multiline
            />
            <AddOnTheFlyAutoComplete
              classes={classes}
              disabled={siteId !== 0}
              setFieldValue={setFieldValue}
              label={"Subdial Color"}
              name={"subdial_color"}
              formik={"subdial_color_id"}
              list={subdial_color_list}
              width={"10%"}
            />
            <Field
              disabled={siteId !== 0}
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : values.subdial_notes
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              component={TextField}
              onFocus={(event) => {
                event.target.select();
              }}
              name="subdial_notes"
              type="text"
              label="Subdial Notes"
              style={{ width: "15%" }}
              multiline
            />
            <CustomAutoComplete
              classes={classes}
              disabled={siteId !== 0}
              setFieldValue={setFieldValue}
              label={"Lum"}
              name={"luminescence"}
              formik={"luminescence_id"}
              list={luminescence_list}
              width={"15%"}
            />
            <Field
              disabled={siteId !== 0}
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : values.luminescence_notes
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              component={TextField}
              onFocus={(event) => {
                event.target.select();
              }}
              name="luminescence_notes"
              type="text"
              label="Lum Notes"
              style={{ width: "14%" }}
              multiline
            />
          </Box>
          <Box
            mx={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <AddOnTheFlyAutoComplete
              classes={classes}
              disabled={siteId !== 0}
              setFieldValue={setFieldValue}
              label={"Hour Marker Type"}
              name={"hour_marker_type"}
              formik={"hour_marker_type_id"}
              list={hour_marker_type_list}
              width={"10%"}
            />
            <AddOnTheFlyAutoComplete
              classes={classes}
              disabled={siteId !== 0}
              setFieldValue={setFieldValue}
              label={"Hour Marker"}
              name={"hour_marker"}
              formik={"hour_marker_id"}
              list={hour_marker_list}
              width={"14%"}
            />
            <AddOnTheFlyAutoComplete
              classes={classes}
              disabled={siteId !== 0}
              setFieldValue={setFieldValue}
              formik={"hour_marker_color_id"}
              label={"Hour Marker Color"}
              width={"10%"}
              name={"hour_marker_color"}
              list={hour_marker_color_list}
            />
            <Field
              disabled={siteId !== 0}
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : values.hour_marker_notes
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              component={TextField}
              onFocus={(event) => {
                event.target.select();
              }}
              name="hour_marker_notes"
              type="text"
              label="Hour Marker Notes"
              multiline
              style={{ width: "12%" }}
            />
            <Field
              disabled={siteId !== 0}
              component={CheckboxWithLabel}
              type="checkbox"
              indeterminate={false}
              name="hour_marker_luminescence"
              Label={{
                label: "Hour Marker Lum",
                labelPlacement: "start",
              }}
            />
            <Field
              disabled={siteId !== 0}
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : values.hour_marker_luminescence_notes
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              component={TextField}
              onFocus={(event) => {
                event.target.select();
              }}
              name="hour_marker_luminescence_notes"
              type="text"
              label="Hr. Marker Lum Note"
              multiline
              style={{ width: "12%" }}
            />
            <AddOnTheFlyAutoComplete
              classes={classes}
              disabled={siteId !== 0}
              setFieldValue={setFieldValue}
              label={"Track"}
              name={"track_type"}
              formik={"track_type_id"}
              list={track_type_list}
              width={"15%"}
            />
            <Field
              disabled={siteId !== 0}
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : values.track_notes
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              component={TextField}
              onFocus={(event) => {
                event.target.select();
              }}
              name="track_notes"
              type="text"
              label="Track Notes"
              multiline
              style={{ width: "12%" }}
            />
          </Box>
          <Box
            mx={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <AddOnTheFlyAutoComplete
              classes={classes}
              disabled={siteId !== 0}
              setFieldValue={setFieldValue}
              label={"Hands Material"}
              name={"hands_material"}
              formik={"hands_material_id"}
              list={hands_material_list}
              width={"12%"}
            />
            <AddOnTheFlyAutoComplete
              classes={classes}
              disabled={siteId !== 0}
              setFieldValue={setFieldValue}
              label={"Hand Finish"}
              name={"hands_finish"}
              formik={"hands_finish_id"}
              list={hands_finish_list}
              width={"12%"}
            />
            <Field
              disabled={siteId !== 0}
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : values.hands_notes
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              component={TextField}
              onFocus={(event) => {
                event.target.select();
              }}
              name="hands_notes"
              type="text"
              label="Hand Notes"
              multiline
              style={{ width: "11.5%" }}
            />
            <Box width={"8%"}>
              <Field
                disabled={siteId !== 0}
                component={CheckboxWithLabel}
                type="checkbox"
                indeterminate={false}
                name="hands_luminescence"
                Label={{ label: "Hand Lum", labelPlacement: "start" }}
              />
            </Box>
            <Field
              disabled={siteId !== 0}
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : values.hands_luminescence_notes
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              component={TextField}
              onFocus={(event) => {
                event.target.select();
              }}
              name="hands_luminescence_notes"
              type="text"
              label="Hand Lum Notes"
              multiline
              style={{ width: "11.5%" }}
            />
            <AddOnTheFlyAutoComplete
              classes={classes}
              disabled={siteId !== 0}
              setFieldValue={setFieldValue}
              label={"Second Hand"}
              name={"second_hand"}
              formik={"second_hand_id"}
              list={second_hand_list}
              width={"12%"}
            />
            <AddOnTheFlyAutoComplete
              classes={classes}
              disabled={siteId !== 0}
              setFieldValue={setFieldValue}
              label={"Second Hand Mat."}
              name={"hands_material"}
              formik={"second_hand_material_id"}
              list={hands_material_list}
              width={"12%"}
            />
            <Box width={"8%"}>
              <Field
                disabled={siteId !== 0}
                component={CheckboxWithLabel}
                type="checkbox"
                indeterminate={false}
                name="second_hand_luminescence"
                Label={{
                  label: "Second Hand Lum",
                  labelPlacement: "start",
                }}
              />
            </Box>
            <Field
              disabled={siteId !== 0}
              className={
                siteId !== 0
                  ? classes.gridTwoBlack
                  : values.second_hand_notes
                  ? classes.gridTwoBlue
                  : classes.gridTwo
              }
              component={TextField}
              onFocus={(event) => {
                event.target.select();
              }}
              name="second_hand_notes"
              type="text"
              label="Second Hand Notes"
              multiline
              style={{ width: "12%" }}
            />
          </Box>
          <Box mx={1} display="flex" justifyContent="space-between">
            <Box
              display="flex"
              justifyContent="space-between"
              width="60%"
              alignItems="center"
            >
              <AddOnTheFlyAutoComplete
                classes={classes}
                disabled={siteId !== 0}
                setFieldValue={setFieldValue}
                label={"Dial Flange Color"}
                name={"dial_color"}
                formik={"dial_flange_color_id"}
                list={dial_color_list}
                width={"25%"}
              />

              <AddOnTheFlyAutoComplete
                classes={classes}
                disabled={siteId !== 0}
                setFieldValue={setFieldValue}
                label={"Flange Indication"}
                name={"flange_indication"}
                formik={"flange_indication_id"}
                list={flange_indication_list}
                width={"25%"}
              />
              <Box display="flex">
                <Field
                  disabled={siteId !== 0}
                  component={CheckboxWithLabel}
                  className={classes.floatLeft}
                  type="checkbox"
                  indeterminate={false}
                  name="date_display"
                  Label={{ label: "Date Displayed", labelPlacement: "start" }}
                />
              </Box>
              <Field
                disabled={siteId !== 0}
                className={
                  siteId !== 0
                    ? classes.gridTwoBlack
                    : values.date_notes
                    ? classes.gridTwoBlue
                    : classes.gridTwo
                }
                component={TextField}
                onFocus={(event) => {
                  event.target.select();
                }}
                name="date_notes"
                type="text"
                label="Date Notes"
                multiline
                style={{ width: "25%" }}
              />
            </Box>
            <Box>
              <Button
                color="primary"
                onClick={handleNext}
                className={classes.button}
                endIcon={<ArrowDownwardIcon />}
              >
                Next
              </Button>
            </Box>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

const luminescence_list = [
  { luminescence_id: 1, luminescence: "Extremely visible" },
  { luminescence_id: 2, luminescence: "Standard visibility" },
  { luminescence_id: 3, luminescence: "Basically visible" },
  { luminescence_id: 4, luminescence: "Weak visibility" },
];
const hour_marker_type_list = [
  { hour_marker_type_id: 1, hour_marker_type: "Applied" },
  { hour_marker_type_id: 2, hour_marker_type: "Printed" },
];
const hour_marker_list = [
  { hour_marker_id: 1, hour_marker: "Roman Numerals" },
  { hour_marker_id: 2, hour_marker: "Numeric" },
];
