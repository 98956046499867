import React, { useEffect, useState } from "react";
import { TextField, Box } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { ConfirmButton, BrandDetails } from ".";
import { WatchActions } from "../../../../state";
import { format_Word } from "../../../../lib";
import { SeriesSeo } from "./SeriesSeo";
import { CalibreDetails } from "./CalibreDetails";

export const EditWatchDetails = (props) => {
  const dispatch = useDispatch();
  const [myValue, setMyValue] = useState(props.detail);
  const [myBrand, setMyBrand] = useState();
  const [disabled, setDisabled] = useState(true);
  const [bgColor, setBgColor] = useState();
  const [caseBackCategory, setCaseBackCategory] = useState();
  const { brand: brandList } = useSelector(
    (state) => state.watches.watchLists,
    shallowEqual,
  );
  const siteId = useSelector((state) => state.scopes.site_id);
  const brandListTitle = brandList.map((brands) => {
    return { title: brands.brand, id: brands.brand_id };
  });
  const caseCategoryTitle = () => {
    return { title: attrData?.category };
  };
  const [attrData, setAttrData] = useState();
  const [certData, setCertData] = useState();
  const [compData, setCompData] = useState();
  const [moveData, setMoveData] = useState();
  const [calibreData, setCalibreData] = useState();

  const caseCategories = [
    { title: "Diameter" },
    { title: "Width" },
    { title: "Width & Height" },
  ];

  useEffect(() => {
    (async function () {
      if (props.attribute === "calibre") {
        const data = await WatchActions.getMovementDetails(props.id);
        setAttrData(data);
        const cert = await dispatch(
          WatchActions.getAttribute("certification", null, siteId),
        );
        setCertData(cert);
        const comp = await dispatch(
          WatchActions.getAttribute("calibre_complication", null, siteId),
        );
        setCompData(comp);
        const move = await dispatch(
          WatchActions.getAttribute("movement", null, siteId),
        );
        setMoveData(move);
        const calibre = await dispatch(
          WatchActions.getAttribute("calibre", null, siteId),
        );
        setCalibreData(calibre);
      } else {
        const data = await WatchActions.getDetailsValue(
          props.attribute,
          props.id,
          props.attribute.endsWith("_group"),
          siteId,
        );
        setAttrData(data);
      }
    })();
  }, [props.attribute, props.id, siteId]);
  const myTitle = { title: props.brandTitle, id: attrData?.brand_id };
  return (
    <div style={{ width: "100%" }}>
      {siteId === 0 &&
        attrData &&
        !attrData.brand &&
        props.attribute !== "series" &&
        !props.attribute.endsWith("_group") &&
        props.attribute !== "calibre" && (
          <Box
            boxShadow={5}
            display="flex"
            justifyContent="center"
            my={4}
            p={2}
            pt={2}
            bgcolor={bgColor}
          >
            <Box p={1}>
              <TextField
                multiline={true}
                value={myValue}
                onChange={(e) => {
                  setMyValue(e.target.value);
                  setDisabled(false);
                  setBgColor("background.paper");
                  if (props.setEditName) {
                    props.setEditName(e.target.value);
                  }
                }}
                label={format_Word(props.attribute) + " Name"}
              />
            </Box>
            <Box p={1}>
              {attrData?.hasOwnProperty(`brand_id`) && (
                <Autocomplete
                  inputValue={myBrand}
                  defaultValue={myTitle}
                  id="combo-box-demo"
                  options={brandListTitle}
                  getOptionLabel={(option) => option.title || ""}
                  onChange={(event, value) => {
                    if (value) {
                      setMyBrand(value?.title);
                      props.setMyBrandId(value?.id);
                      setDisabled(false);
                      setBgColor("background.paper");
                      if (props.setEditBrand) {
                        props.setEditBrand(value?.id);
                      }
                    }
                  }}
                  style={{ width: 235 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Brand Affiliation"
                      defaultValue={props.brandTitle}
                    />
                  )}
                />
              )}
              {props.attribute === "case_shape" && (
                <Autocomplete
                  inputValue={caseBackCategory}
                  defaultValue={caseCategoryTitle}
                  id="case-shape-category"
                  options={caseCategories}
                  getOptionLabel={(option) => option.title || ""}
                  onChange={(event, value) => {
                    if (value) {
                      setCaseBackCategory(value);
                      setDisabled(false);
                      setBgColor("background.paper");
                    }
                  }}
                  style={{ width: 235 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Case Category"
                      defaultValue={caseCategoryTitle}
                    />
                  )}
                />
              )}
            </Box>
            <Box p={1}>
              <ConfirmButton
                attribute={format_Word(props.attribute)}
                myValue={myValue}
                oldValue={props.detail}
                myBrand={myBrand}
                myBrandId={props.myBrandId}
                id={props.id}
                rawAttribute={props.rawAttribute}
                Add_Edit_Delete={"edit"}
                color={"primary"}
                disabled={disabled}
                path={
                  attrData?.hasOwnProperty(`brand_id`) &&
                  props.attribute !== "series_group" &&
                  props.attribute !== "series" &&
                  props.attribute !== "Series"
                    ? "watch-attribute-affiliated"
                    : props.path
                }
              />
            </Box>
            {attrData.can_delete && (
              <Box p={1}>
                <ConfirmButton
                  attribute={format_Word(props.attribute)}
                  myValue={myValue}
                  myBrand={myBrand}
                  myBrandId={props.myBrandId}
                  id={props.id}
                  rawAttribute={props.rawAttribute}
                  Add_Edit_Delete={"delete"}
                  color={"secondary"}
                  disabled={false}
                  path={
                    attrData?.hasOwnProperty(`brand_id`) &&
                    props.attribute !== "series_group" &&
                    props.attribute !== "series" &&
                    props.attribute !== "Series"
                      ? "watch-attribute-affiliated"
                      : props.path
                  }
                />
              </Box>
            )}
          </Box>
        )}
      {attrData && attrData.brand && <BrandDetails {...attrData} />}
      {attrData && props.attribute === "series" && (
        <SeriesSeo
          {...attrData}
          attribute={props.attribute}
          rawAttribute={props.rawAttribute}
          setGroupDetailsData={props.setGroupDetailsData}
          myTitle={myTitle}
          myBrand={myBrand}
          brandListTitle={brandListTitle}
          setMyBrand={setMyBrand}
          setMyBrandId={props.setMyBrandId}
          setEditBrand={props.setEditBrand}
          brandTitle={props.brandTitle}
          id={props.id}
          setIsDisabled={props.setIsDisabled}
          isDisabled={props.isDisabled}
          myBrandId={props.myBrandId}
        />
      )}
      {attrData && props.attribute.endsWith("_group") && (
        <SeriesSeo
          {...attrData}
          attribute={props.attribute}
          rawAttribute={props.rawAttribute}
          setGroupDetailsData={props.setGroupDetailsData}
          myTitle={myTitle}
          myBrand={myBrand}
          brandListTitle={brandListTitle}
          setMyBrand={setMyBrand}
          setMyBrandId={props.setMyBrandId}
          setEditBrand={props.setEditBrand}
          brandTitle={props.brandTitle}
          id={props.id}
          setIsDisabled={props.setIsDisabled}
          isDisabled={props.isDisabled}
          myBrandId={props.myBrandId}
        />
      )}
      {attrData && props.attribute === "calibre" && compData && (
        <CalibreDetails
          {...attrData}
          certificationList={certData}
          complicationsList={compData}
          movementList={moveData}
          calibreList={calibreData}
          defaultCert={certData?.filter(
            (listItem) =>
              listItem.certification_id === attrData.certification_id,
          )}
          defaultMove={moveData?.filter(
            (listItem) => listItem.movement_id === attrData.movement,
          )}
          defaultCalibre={calibreData?.filter(
            (listItem) => listItem.calibre_id === attrData.base_calibre,
          )}
          attribute={props.attribute}
          rawAttribute={props.rawAttribute}
          myTitle={myTitle}
          myBrand={myBrand}
          brandListTitle={brandListTitle}
          setMyBrand={setMyBrand}
          setMyBrandId={props.setMyBrandId}
          setEditBrand={props.setEditBrand}
          brandTitle={props.brandTitle}
          id={props.id}
          setIsDisabled={props.setIsDisabled}
          isDisabled={props.isDisabled}
          myBrandId={props.myBrandId}
        />
      )}
    </div>
  );
};
