import { stringToHslPastel } from "../../lib";

export const AuthSelectors = {
  accountEmail(state) {
    return state.auth?.email ?? "";
  },
  avatarInfo(state) {
    const {
      auth: { user = {} },
    } = state;
    const firstName = user.first_name ?? "";
    const lastName = user.last_name ?? "";
    const wholeName = (firstName + " " + lastName).trim();
    const initials = firstName.substring(0, 1) + lastName.substring(0, 1);
    return {
      bgColor: stringToHslPastel(wholeName),
      text: initials,
      textColor: "#fff",
    };
  },
  userFirstName(state) {
    return state.auth?.user?.first_name ?? "";
  },
  userFullName(state) {
    const {
      auth: { user = {} },
    } = state;
    return `${user.first_name || ""} ${user.last_name || ""}`.trim();
  },
  userLastName(state) {
    return state.auth?.user?.last_name;
  },
};
